<template>
  <div v-if="cashFlowImpact[0].data.length">
    <div class="cash-flow-chart-title">Cumulative Cash Flow</div>
    <VueApexCharts
      type="bar"
      width="100%"
      height="400"
      :options="cashFlowImpactChartOptions"
      :series="cashFlowImpact"
    ></VueApexCharts>
  </div>
</template>

<script>
import colors from '../../assets/colors';
import helpers from '../../helpers/index';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    VueApexCharts
  },
  data() {
    return {
      cashFlowImpactUnchangedChartOptions: {
        chart: {
          stacked: true,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          type: 'numeric',
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            },
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return this.formatMoney(value);
            },
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'solid',
          opacity: 1
        },
        colors: [colors.chartOrange, colors.chartLightPurple, colors.chartGreen, colors.chartRed]
      }
    };
  },
  computed: {
    cashFlowImpactChartOptions() {
      return {
        ...this.cashFlowImpactUnchangedChartOptions,
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'bottom',
          horizontalAlign: 'left',
          fontSize: this.$store.state.chartsFonts.medium,
          width: 600,
          markers: {
            width: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            height: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            radius: 4
          },
          onItemClick: {
            toggleDataSeries: false
          },
          formatter: function (seriesName) {
            return ['', seriesName];
          }
        }
      };
    },
    cashFlowImpact() {
      const storeData =
        this.$store.state.scenarioData.currentScenarioData.chartsData.cashFlowImpact;
      const propertyTaxAndInsurancePayments = [],
        legacyMortgagePayments = [],
        directPaymentsFromReverseMortgageToHomeowner = [],
        voluntaryPaymentsFromHomeowner = [];
      for (let i = 0; i <= this.maxPeriod(this.youngestOwnerAge); i++) {
        propertyTaxAndInsurancePayments.push({
          x: storeData[i].age,
          y: storeData[i].propertyTaxAndInsurancePayments
        });
        legacyMortgagePayments.push({
          x: storeData[i].age,
          y: storeData[i].legacyMortgagePayments
        });
        directPaymentsFromReverseMortgageToHomeowner.push({
          x: storeData[i].age,
          y: storeData[i].directPaymentsFromReverseMortgageToHomeowner
        });
        voluntaryPaymentsFromHomeowner.push({
          x: storeData[i].age,
          y: storeData[i].voluntaryPaymentsFromHomeowner
        });
      }
      return [
        {
          name: 'Property Tax & Insurance Payments',
          data: propertyTaxAndInsurancePayments
        },
        { name: 'Legacy Mortgage Payments', data: legacyMortgagePayments },
        {
          name: 'Direct Payments from Reverse Mortgage to Homeowner',
          data: directPaymentsFromReverseMortgageToHomeowner
        },
        {
          name: 'Voluntary Payments from Homeowner',
          data: voluntaryPaymentsFromHomeowner
        }
      ];
    }
  },
  methods: { formatMoney: helpers.formatMoney, maxPeriod: helpers.getMaxPeriod }
};
</script>
<style scoped>
.cash-flow-chart-title {
  font-size: 1.25rem !important;
  font-weight: 500;
  color: #3e8df3;
}
</style>
