<template>
  <v-card class="d-flex flex-column align-center mb-8 mx-auto pt-9 pb-7 px-9" elevation="3">
    <v-sheet width="100%" max-width="800">
      <div class="clauseText--text medium-small-text-font mb-6 mb-sm-8">
        Here's a snapshot of your current Home Equity and Mortgage Payments:
      </div>
      <ValueBlock
        label="Estimated Home Value"
        :value="otherValues.estimatedHomeValue"
        :customText="false"
        :customBackground="false"
        :accent="false"
      />
      <ValueBlock
        label="Current Mortgages"
        :value="otherValues.currentMortgages"
        :customText="false"
        :customBackground="false"
        :accent="false"
      />
      <ValueBlock
        label="HOME EQUITY"
        :value="otherValues.homeEquity"
        :customText="false"
        :customBackground="false"
        :accent="false"
      />
      <ValueBlock
        label="Current Mortgage Payments"
        :value="otherValues.currentMortgagePayments"
        :customText="false"
        :customBackground="false"
        :accent="false"
      />
      <div class="clauseText--text medium-small-text-font mt-10 mb-6 mb-sm-8">
        Funds avalable from a new reverse mortgage, which is calculated based on your age and
        current interest rates, is less than the amount of your current mortgages:
      </div>
      <ValueBlock
        label="Current Mortgage(s)"
        :value="otherValues.currentMortgages"
        :customText="false"
        :customBackground="false"
        :accent="false"
      />
      <ValueBlock
        label="Reverse Mortgage (net approval)"
        :value="otherValues.reverseMortgage"
        :customText="false"
        :customBackground="false"
        :accent="false"
      />
      <ValueBlock
        label="Shortfall"
        :value="otherValues.shortfall"
        :customText="false"
        :customBackground="false"
        :accent="true"
      />
      <div class="red--text darken-5 medium-small-text-font mt-16">
        Your current mortgage(s) must be paid in full when a new reverse mortgage is created.
      </div>
      <div class="green--text darken-5 medium-small-text-font my-8">
        A new reverse mortgage would contribute most of the funds needed to pay off your current
        mortgages, and you would have to pay the shortfall.
      </div>
      <div
        v-if="!!otherValues.conditionText.monthsOfPIPaymentsToCoverShortfall"
        class="black--text medium-small-text-font"
      >
        You could pay your regular mortgage principal and interest over the next
        {{ Math.abs(otherValues.conditionText.monthsOfPIPaymentsToCoverShortfall) }} months,
        (approximately
        {{ formatMoney(Math.abs(otherValues.conditionText.regularMortgagePrincipalAndInterest)) }}),
        or you could pay {{ formatMoney(Math.abs(otherValues.conditionText.shortfall)) }} now, and
        never have another mortgage payment, for as long as you have the reverse mortgage.
      </div>
      <div v-else class="black--text medium-small-text-font">
        You have financial assets totalling
        {{ formatMoney(otherValues.conditionText.totalLiquidAssets) }}. You could allocate
        approximately
        {{ formatPercentageFull(otherValues.conditionText.shortfallAsPercentOfLiquidAssets) }} of
        that ({{ roundToThousandsAndFormatMoney(otherValues.conditionText.shortfall) }}) to home
        equity to get a reverse mortgage and never have another mortgage payment, for as long as you
        have the reverse mortgage.
      </div>
    </v-sheet>
  </v-card>
</template>

<script>
import ValueBlock from '../ValueBlock.vue';
import helpers from '../../helpers';

export default {
  components: { ValueBlock },
  computed: {
    otherValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    roundToThousandsAndFormatMoney: helpers.roundToThousandsAndFormatMoney,
    formatPercentage: helpers.formatPercentage,
    formatPercentageFull: helpers.formatPercentageFull
  }
};
</script>

<style scoped></style>
