<template>
  <div class="my-4 full-width">
    <div class="medium-text-font my-8">Build your own reverse mortgage</div>
    <v-expansion-panels :value="isResultSaved ? 0 : null">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-sheet class="d-flex flex-column" width="100%">
            <div class="clauseText--text medium-small-text-font my-2">
              1. Select an Interest Rate Scenario
            </div>
          </v-sheet>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="small-text-font">
            This Reverse Mortgage has an Adjustable Interest Rate, so we built a simulation model
            for you.
          </div>
          <CustomInterestRatesTable />
          <div class="d-flex justify-space-between">
            <v-sheet class="d-flex flex-wrap justify-space-between align-center" width="100%">
              <v-sheet
                width="100%"
                min-width="260"
                max-width="38%"
                class="d-flex flex-column justify-space-between align-start mb-8 mb-lg-0"
              >
                <div class="small-text-font mb-10">
                  You can select several different future interest rate options to learn how future
                  interest rates will affect your loan balance and reverse mortgage line of credit.
                </div>
                <InterestRateScenarioSelector
                  label="Interest rate scenario"
                  :customBackground="false"
                />
              </v-sheet>
              <v-sheet style="flex: 1" min-width="280">
                <InterestRateScenarioChart />
              </v-sheet>
              <!-- <CustomPropertValueAppreciationRatesTable /> -->
            </v-sheet>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InterestRateScenarioChart from '../../Charts/InterestRateScenarioChart.vue';
import InterestRateScenarioSelector from '../../Selectors/InterestRateScenarioSelector.vue';
import CustomInterestRatesTable from '../../Tables/CustomInterestRatesTable.vue';
//import CustomPropertValueAppreciationRatesTable from '../../Tables/CustomPropertValueAppreciationRatesTable.vue';

export default {
  components: {
    CustomInterestRatesTable,
    //CustomPropertValueAppreciationRatesTable,
    InterestRateScenarioSelector,
    InterestRateScenarioChart
  },
  computed: {
    ...mapGetters(['isResultSaved'])
  }
};
</script>

<style scoped></style>
