export default {
  chartShadedYellow: '#F7C01C',
  chartYellow: '#F9DA78',
  chartBlue: '#3E8DF3',
  chartLightGreen: '#A8F72E',
  chartGreen: '#6FCE84',
  chartDarkGreen: '#27923E',
  chartLightPurple: '#C99AF7',
  chartPurple: '#633194',
  chartOrange: '#E79315',
  chartRed: '#F04848',
  chartDarkRed: '#6C0000'
};

// {
//   chartShadedYellow: '#F7C01C',
//   chartYellow: '#F9DA78',
//   chartBlue: '#3E8DF3',
//   chartLightGreen: '#A8F72E',
//   chartGreen: '#218721',
//   chartDarkGreen: '#01301F',
//   chartLightPurple: '#C99AF7',
//   chartPurple: '#633194',
//   chartOrange: '#DE8344',
//   chartRed: '#F04848',
//   chartDarkRed: '#6C0000'
// }
