<template>
  <div>
    <p class="mb-10">
      {{ loanOfficer.footerText }}
    </p>
    <p>
      The attached documents illustrate estimates only, based on information provided by you,
      currently available interest rates, fees, HUD and Lender guidelines & policies, all of which
      are subject to change at any time. Future projections of property values, loan balances, line
      of credit availability, monthly payment options, interest rates, etc. are estimates only and
      are not guaranteed.
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    loanOfficer() {
      return this.$store.state.loanOfficer.info;
    }
  }
};
</script>
