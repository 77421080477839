<template>
  <div class="text-h6 font-weight-regular width900 mx-auto text-justify my-8">
    <div class="my-8">
      <FHAMaterials />
    </div>

    <v-divider />

    <div class="my-8">
      <StandardHUD />
    </div>

    <v-divider />

    <div class="my-8">
      <CreatedScenario />
    </div>

    <v-divider />

    <div class="my-8">
      <EnhancedOptions />
    </div>
  </div>
</template>

<script>
import FHAMaterials from './FHAMaterials.vue';
import StandardHUD from './StandardHUD.vue';
import CreatedScenario from './CreatedScenario.vue';
import EnhancedOptions from './EnhancedOptions.vue';

export default { components: { FHAMaterials, StandardHUD, CreatedScenario, EnhancedOptions } };
</script>

<style scoped>
.width900 {
  max-width: 900px;
}
</style>
