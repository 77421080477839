<template>
  <v-sheet
    class="d-flex flex-column flex-sm-row justify-space-between align-center mb-3"
    v-bind:class="{
      generalBackground: isBackgroundCustom,
      'align-sm-start': showMax
    }"
    width="100%"
  >
    <div
      style="max-width: 250px"
      class="small-text-font text-center text-sm-start mb-1 mb-sm-0"
      v-bind:class="{
        'mt-2': showMax
      }"
    >
      {{ label }}
    </div>
    <v-sheet
      class="d-flex flex-column justify-space-between"
      v-bind:class="{ generalBackground: isBackgroundCustom }"
      width="220"
    >
      <v-text-field
        v-bind:class="{ generalBackground: isBackgroundCustom }"
        prefix="$"
        outlined
        :hide-details="!immediateDrawError && !inputError"
        :value="formatMoneyForInput(immediateDraw)"
        :disabled="isUpdating"
        :readonly="isResultSaved"
        @input="(value) => handleScenarioOptionsChange(value, 'immediateDraw')"
        :error="Boolean(inputError)"
        :error-messages="inputError"
      ></v-text-field>
      <div
        v-if="showMax"
        class="text-h6 font-weight-regular align-self-center align-self-sm-start mt-2"
      >
        Maximum is: <span class="font-weight-bold">{{ formatMoney(maxImmediateCash) }}</span>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
import _ from 'lodash';
import helpers from '@/helpers';

export default {
  props: ['label', 'showMax', 'customBackground', 'maxImmediateDraw'],
  data: () => ({
    immediateDrawError: false,
    inputError: ''
  }),
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    immediateDraw() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
        .immediateDraw;
    },
    isBackgroundCustom() {
      return this.$props.customBackground || false;
    },
    maxImmediateCash() {
      return this.$props.maxImmediateDraw;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    formatMoneyForInput: helpers.formatMoneyForInput,
    unformatMoney: helpers.unformatMoney,
    saveScenarioOption: _.debounce(function (value, fieldName) {
      if (!this.immediateDrawError) {
        const data = { fieldName, value };
        this.$store.commit('changeOptionValues', data);
        this.$store.dispatch('getNewScenarioData');
      }
    }, 1000),
    handleScenarioOptionsChange(value, fieldName) {
      this.$data.inputError = '';

      const currentValue = value.length === 0 ? 0 : this.unformatMoney(value);

      if (currentValue === null) {
        this.$data.inputError = 'Value must be a number';
        return;
      }

      const validationResponse = this.immediateDrawRule(currentValue);

      if (typeof validationResponse === 'string') {
        this.$data.inputError = validationResponse;
      }

      this.saveScenarioOption(currentValue, fieldName);
    },
    immediateDrawRule(value) {
      if (value < 0) {
        this.immediateDrawError = true;
        return 'Should be a positive number!';
      } else if (value > this.maxImmediateCash) {
        this.immediateDrawError = true;
        return "Shouldn't exceed maximum value!";
      } else {
        this.immediateDrawError = false;
        return true;
      }
    }
  }
};
</script>
