<template>
  <div>
    <div class="page-break">
      <InterestRateScenario />
    </div>
    <div class="page-break">
      <PropertyValueAppreciationRate />
    </div>
    <div class="page-break">
      <DoYouWantImmediateCash />
    </div>
    <div class="page-break">
      <ConsiderVoluntaryPayments />
    </div>
    <div class="page-break">
      <SchedulePayments />
    </div>
  </div>
</template>

<script>
import InterestRateScenario from '../BuildYourOwnRM/InterestRateScenario.vue';
import PropertyValueAppreciationRate from '../BuildYourOwnRM/PropertyValueAppreciationRate.vue';
import DoYouWantImmediateCash from '../BuildYourOwnRM/DoYouWantImmediateCash.vue';
import ConsiderVoluntaryPayments from '../BuildYourOwnRM/ConsiderVoluntaryPayments.vue';
import SchedulePayments from '../BuildYourOwnRM/SchedulePayments/SchedulePayments.vue';

export default {
  components: {
    InterestRateScenario,
    PropertyValueAppreciationRate,
    DoYouWantImmediateCash,
    ConsiderVoluntaryPayments,
    SchedulePayments
  }
};
</script>

<style scoped>
.page-break {
  break-inside: avoid;
  page-break-inside: avoid;
}

@media print {
  .page-break {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>
