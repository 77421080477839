<template>
  <v-card
    class="mx-auto mb-2 py-2 d-flex flex-column flex-md-row justify-space-between align-center align-md-start flex-md-wrap flex-ls-nowrap page-break"
  >
    <v-sheet
      class="d-flex flex-column my-4 mx-2"
      :width="$vuetify.breakpoint.name === 'md' ? '100%' : '48%'"
    >
      <div class="small-text-font ml-4 mb-4">
        Allocate everything to a Reverse Mortgage Line of Credit
      </div>
      <VueApexCharts
        v-if="allocateToReverseMortgageLineOfCredit[0].data.length"
        type="area"
        width="100%"
        height="550"
        :options="allocateToReverseMortgageLineOfCreditChartOptions"
        :series="allocateToReverseMortgageLineOfCredit"
      ></VueApexCharts>
    </v-sheet>
    <v-sheet
      class="d-flex flex-column my-4 mx-2"
      :width="$vuetify.breakpoint.name === 'md' ? '100%' : '48%'"
    >
      <div class="small-text-font ml-4 mb-4">
        Or <span class="font-weight-bold">receive monthly payments</span> from the Reverse Mortgage
      </div>
      <VueApexCharts
        v-if="monthlyPaymentsFromTheReverseMortgage[0].data.length"
        type="bar"
        width="100%"
        height="625"
        :options="monthlyPaymentsFromTheReverseMortgageChartOptions"
        :series="monthlyPaymentsFromTheReverseMortgage"
      ></VueApexCharts>
    </v-sheet>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import helpers from '../../helpers';
import colors from '../../assets/colors';

export default {
  components: { VueApexCharts },
  data() {
    return {
      allocateToReverseMortgageLineOfCreditUnchangedChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          type: 'numeric',
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            },
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        yaxis: {
          type: 'numeric',
          labels: {
            formatter: (value) => {
              return this.formatMoney(value);
            },
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        fill: {
          type: 'solid',
          opacity: 0.8
        },
        colors: [colors.chartYellow]
      },
      monthlyPaymentsFromTheReverseMortgageUnchangedChartOptions: {
        chart: {
          stacked: true,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          labels: {
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return this.formatMoney(value);
            },
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'solid',
          opacity: 0.8
        },
        colors: [colors.chartBlue, colors.chartGreen, colors.chartPurple]
      }
    };
  },
  computed: {
    allocateToReverseMortgageLineOfCreditChartOptions() {
      return {
        ...this.$data.allocateToReverseMortgageLineOfCreditUnchangedChartOptions,
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'bottom',
          horizontalAlign: 'left',
          fontSize: this.$store.state.chartsFonts.large,
          height: 50,
          offsetX: this.$vuetify.breakpoint.width < 380 ? 0 : 40,
          markers: {
            width: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            height: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            radius: 4,
            offsetY: this.$vuetify.breakpoint.width < 500 ? 0 : 7
          },
          onItemClick: {
            toggleDataSeries: false
          },
          formatter: (seriesName, opts) => {
            const initialLine = this.roundToThousandsAndFormatMoney(
              opts.w.globals.series[opts.seriesIndex][0]
            );
            return ['-', seriesName, '-', initialLine];
          }
        }
      };
    },
    monthlyPaymentsFromTheReverseMortgageChartOptions() {
      return {
        ...this.$data.monthlyPaymentsFromTheReverseMortgageUnchangedChartOptions,
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'left',
          fontSize: this.$store.state.chartsFonts.small,
          width: this.$vuetify.breakpoint.width < 1000 ? 400 : 700,
          offsetX: this.$vuetify.breakpoint.width < 380 ? 0 : 40,
          markers: {
            width: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            height: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            radius: 4,
            offsetY: this.$vuetify.breakpoint.width < 500 ? 0 : 7
          },
          itemMargin: {
            horizontal: 0,
            vertical: 10
          },
          onItemClick: {
            toggleDataSeries: false
          },
          formatter: (seriesName, opts) => {
            const valueToShow = opts.w.globals.series[opts.seriesIndex].filter(
              (value) => value !== 0
            );
            const stringValue = this.roundToThousandsAndFormatMoney(valueToShow[0]);

            if (seriesName === 'Initial') {
              return `- Compare the initial amount of ${stringValue} to monthly payment examples below`;
            } else if (seriesName === '10 Years') {
              return `- Monthly Payments - 10 Years - ${stringValue} - ${this.formatMoney(
                this.monthly.firstValue
              )} per month`;
            } else if (seriesName === '30 Years') {
              return `- Monthly Payments - 30 Years - ${stringValue} - ${this.formatMoney(
                this.monthly.secondValue
              )} per month`;
            }
          }
        }
      };
    },
    allocateToReverseMortgageLineOfCredit() {
      const storeData =
        this.$store.state.scenarioData.currentScenarioData.chartsData
          .allocateToReverseMortgageLineOfCredit;
      const data = [];
      for (let object of storeData) {
        data.push({ x: object.age, y: object.mortgageLineOfCredit });
      }
      return [{ name: 'Initial Line of Credit', data }];
    },
    monthlyPaymentsFromTheReverseMortgage() {
      const storeData =
        this.$store.state.scenarioData.currentScenarioData.chartsData
          .montlyPaymentsFromTheReverseMortgage;
      const fields = Object.keys(storeData);
      const series = [];
      for (let currentField of fields) {
        const data = [];
        for (let field of fields) {
          const dataObject = {
            x: storeData[field]?.text,
            y: currentField === field ? storeData[currentField].price : 0
          };
          data.push(dataObject);
        }
        series.push({
          name: storeData[currentField].text,
          data
        });
      }

      return series;
    },
    monthly() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues.monthly;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    roundToThousandsAndFormatMoney: helpers.roundToThousandsAndFormatMoney
  }
};
</script>

<style scoped>
.page-break {
  break-inside: avoid;
  page-break-inside: avoid;
}

@media print {
  .page-break {
    break-before: always;
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>
