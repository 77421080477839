<template>
  <v-sheet v-bind:class="{ generalBackground: isBackgroundCustom }" width="100%"
    ><v-sheet
      class="d-flex flex-column flex-sm-row justify-space-between align-center mb-3"
      v-bind:class="{ generalBackground: isBackgroundCustom }"
      width="100%"
    >
      <div
        style="max-width: 250px"
        class="small-text-font text-center text-sm-start mb-1 mb-sm-0 mb-1 mb-sm-0"
      >
        {{ startingLabel }}
      </div>
      <v-sheet width="200">
        <v-select
          v-bind:class="{ generalBackground: isBackgroundCustom }"
          v-model="starting"
          hide-details
          :items="startingItems"
          dense
          outlined
          @change="(value) => handleScenarioOptionsChange(value, 'scheduleStarting')"
          :disabled="isUpdating"
          :readonly="isResultSaved"
        />
      </v-sheet>
    </v-sheet>
    <v-sheet
      class="d-flex flex-column flex-sm-row justify-space-between align-center mb-3"
      v-bind:class="{ generalBackground: isBackgroundCustom }"
      width="100%"
    >
      <v-sheet
        class="d-flex flex-row justify-center justify-sm-start align-end mb-1 mb-sm-0"
        v-bind:class="{ generalBackground: isBackgroundCustom }"
        max-width="250"
      >
        <div class="small-text-font text-center text-sm-start mr-2">{{ yearsLabel }}</div>
        <v-tooltip v-if="showTooltip" top max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Lifetime payments never stop in this application. Payments for a specific period of
            time are limited to 20 years in this application, but they can be set up for a longer
            period when you sign a loan application.</span
          ></v-tooltip
        >
      </v-sheet>
      <v-sheet width="200">
        <v-select
          v-bind:class="{ generalBackground: isBackgroundCustom }"
          v-model="numberOfYears"
          hide-details
          :items="numberOfYearsItems"
          dense
          outlined
          @change="(value) => handleScenarioOptionsChange(value, 'scheduleNumberOfYears')"
          :disabled="isUpdating"
          :readonly="isResultSaved"
        />
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  props: ['startingLabel', 'yearsLabel', 'showTooltip', 'customBackground'],
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    starting: {
      get() {
        return this.selectsOptions.selected.scheduleStarting;
      },
      set(value) {
        this.$store.commit('changeOptionValues', { fieldName: 'scheduleStarting', value });
      }
    },
    numberOfYears: {
      get() {
        return this.selectsOptions.selected.scheduleNumberOfYears;
      },
      set(value) {
        this.$store.commit('changeOptionValues', { fieldName: 'scheduleNumberOfYears', value });
      }
    },
    isBackgroundCustom() {
      return this.$props.customBackground || false;
    },
    selectsOptions() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions;
    },
    startingItems() {
      const items = [...this.selectsOptions.beginPayments];
      if (this.numberOfYears !== 999) {
        const numOfItemsToHide = this.numberOfYears - 1;
        items.splice(items.length - numOfItemsToHide, numOfItemsToHide);
      }
      return items;
    },
    numberOfYearsItems() {
      const items = [...this.selectsOptions.makeOrReceivePayments];
      // items.shift();
      if (!(this.starting === 'Immediately' || this.starting === 'Never')) {
        const numOfItemsToHide = Number(this.starting.slice(3, 5)); // extract a number out of "in <num> years"
        items.splice(items.length - numOfItemsToHide - 1, numOfItemsToHide);
        items.splice(items.length - 1, 1, 'Lifetime');
      } else {
        items[items.length - 1] = { value: items[items.length - 1], text: 'Lifetime' };
      }

      return items;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    }
  },
  methods: {
    handleScenarioOptionsChange(value, fieldName) {
      let data;
      if (fieldName === 'scheduleNumberOfYears' && value === 'Lifetime') {
        data = { fieldName, value: 999 };
      } else {
        data = { fieldName, value };
      }
      this.$store.commit('changeOptionValues', data);
      this.$store.dispatch('getNewScenarioData');
    }
  }
};
</script>
