<template>
  <div class="my-4 full-width">
    <v-expansion-panels :value="isResultSaved ? 0 : null">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-sheet class="d-flex flex-column" width="100%">
            <div class="clauseText--text medium-small-text-font my-2">
              2. Select a Property Value Appreciation Rate
            </div>
          </v-sheet>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
          <div class="small-text-font mb-10">
            The average annual appreciation rate for your actuarial expected life is
            <span class="font-weight-bold">{{
              formatPercentage(averageAnnualAppreciationRate)
            }}</span
            >.
          </div>
          <v-sheet class="d-flex">
            <v-sheet class="d-flex align-center">
              <PropertyValueAppreciationRateSelector
                label="Property Value Appreciation Rate"
                :customBackground="true"
              />
            </v-sheet>
            <v-sheet width="100%" min-width="300">
              <CaseSchillerPropertyValueIndexChart :customBackground="true" />
            </v-sheet>
          </v-sheet>
          <CustomPropertValueAppreciationRatesTable />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CaseSchillerPropertyValueIndexChart from '../../Charts/CaseSchillerPropertyValueIndexChart.vue';
import PropertyValueAppreciationRateSelector from '../../Selectors/PropertyValueAppreciationRateSelector.vue';
import CustomPropertValueAppreciationRatesTable from '../../Tables/CustomPropertValueAppreciationRatesTable.vue';

import helpers from '../../../helpers';

export default {
  components: {
    CaseSchillerPropertyValueIndexChart,
    PropertyValueAppreciationRateSelector,
    CustomPropertValueAppreciationRatesTable
  },
  computed: {
    ...mapGetters(['isResultSaved']),
    averageAnnualAppreciationRate() {
      return this.$store.state.scenarioData.currentScenarioData.chartsData
        .caseSchillerPropertyValueIndex.minOfAverageAppreciation;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    }
  },
  methods: {
    formatPercentage: helpers.formatPercentage
  }
};
</script>

<style scoped></style>
