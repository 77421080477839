<template>
  <v-sheet class="table mt-10" width="100%" v-if="propertyValueApreciationRate === 'Custom'">
    <v-data-table
      class="generalBackground"
      hide-default-header
      hide-default-footer
      disable-pagination
      :items="selectsOptions.selected.customPropertyValueRates"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr>
            <td class="nameCell"><div class="text-center">Year</div></td>
            <td class="cell" v-for="({ year }, index) in items" :key="index">
              <div class="text-center">{{ year }}</div>
            </td>
            <td class="endCell text-center">>40</td>
          </tr>
          <tr>
            <td class="nameCell"><div class="text-center">Rate</div></td>
            <td class="cell px-2" v-for="({ percentage }, index) in items" :key="index">
              <div v-if="index === 0" class="text-center">
                {{ formatPercentage(percentage) }}
              </div>
              <v-text-field
                v-else
                class="rate-input ma-0 pa-0"
                :min="0"
                :max="100"
                type="number"
                suffix="%"
                hide-details
                :value="percentagesMutating(percentage, 'toPercent')"
                :disabled="isUpdating"
                :readonly="isResultSaved"
                @change="(value) => handleAppreciationRatesPerYearChange(Number(value), index)"
              ></v-text-field>
            </td>
            <td class="endCell text-center">Average yrs 1-40</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import numeral from 'numeral';
import helpers from '../../helpers';

export default {
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    selectsOptions() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions;
    },
    propertyValueApreciationRate() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
        .propertyValueApreciationRate;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    }
  },
  methods: {
    formatPercentage: helpers.formatPercentage,
    percentagesMutating(value, action) {
      const valueToGetPercentageValue = 100;
      if (action === 'toPercent') {
        return numeral(value).multiply(valueToGetPercentageValue).value();
      }
      if (action === 'toValue') {
        return numeral(value).divide(valueToGetPercentageValue).value();
      }
      return null;
    },
    handleAppreciationRatesPerYearChange(value, index) {
      const mutatedValue = this.percentagesMutating(value, 'toValue');
      this.$store.commit('changePropertyRatesByYears', { index, value: mutatedValue });
      this.$store.dispatch('getNewScenarioData');
    }
  }
};
</script>

<style scoped>
.table {
  border: 2px solid #e2e2e2;
  border-radius: 10px;
  overflow: hidden;
}
.nameCell {
  min-width: 61px;
}
.cell {
  border-left: 1px solid #e2e2e2;
  min-width: 70px;
}
.endCell {
  border-left: 1px solid #e2e2e2;
  min-width: 84px;
}
.rate-input >>> input {
  text-align: center;
  font-size: 14px;
}

::v-deep .v-text-field input {
  min-width: 41px;
}
</style>
