<template>
  <v-sheet width="100%">
    <div
      v-if="!isCurrentAppreciationRateInList"
      class="red--text darken-5 align-self-center small-text-font text-center mx-1 mb-1"
    >
      Property value appreciation rate you chose earlier isn't available in this configuration.
      Please choose another one.
    </div>
    <v-sheet class="table" width="100%">
      <v-data-table
        class="generalBackground table-body"
        hide-default-header
        hide-default-footer
        disable-pagination
        mobile-breakpoint="0"
      >
        <template v-slot:body="">
          <tbody>
            <tr>
              <td class="headerCell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  Property Value Apprecia{{ isWrap ? '- ' : '' }}tion Rate:
                </div>
              </td>
              <td
                class="headerCell rightCell"
                colspan="2"
                v-bind:class="{ 'red lighten-5': !isCurrentAppreciationRateInList }"
              >
                <v-select
                  style="max-width: 150px"
                  class="mx-auto"
                  hide-details
                  :items="allAppreciationRates"
                  item-value="value"
                  item-text="text"
                  flat
                  dense
                  :value="currentAppreciationRate"
                  @change="
                    (value) => handleScenarioOptionsChange(value, 'propertyValueApreciationRate')
                  "
                  :disabled="isUpdating"
                  :readonly="isResultSaved"
                />
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">Years</div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">Now</div>
              </td>
              <td class="cell rightCell cellWidth">
                <v-select
                  style="max-width: 150px"
                  class="mx-auto"
                  hide-details
                  :items="selectsOptions.effectiveYearsOptions"
                  dense
                  :value="currentEffectiveYear"
                  @change="(value) => handleScenarioOptionsChange(value, 'effectiveAge')"
                  :disabled="isUpdating"
                  :readonly="isResultSaved"
                />
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  Property Value
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByNow.propertyValue) }}
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByYears.propertyValue) }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  Mortgage Balance
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByNow.mortgageBalance) }}
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByYears.mortgageBalance) }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  Home Equity
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByNow.homeEquity) }}
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByYears.homeEquity) }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  Equity Change
                </div>
              </td>
              <td class="cell rightCell" colspan="2">
                <div class="small-text-font text-center blue--text darken-5 font-weight-medium">
                  {{ formatMoney(tableValues.finalHomeEquity) }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  {{ preFinalRowLabel }}
                </div>
              </td>
              <td class="cell rightCell" colspan="2">
                <div class="small-text-font text-center font-weight-medium">
                  {{ formatMoney(preFinalRowValue) }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div
                  class="small-text-font text-left green--text darken-5 font-weight-medium text-uppercase"
                >
                  {{ finalRowLabel }}
                </div>
              </td>
              <td class="cell rightCell" colspan="2">
                <div class="small-text-font text-center green--text darken-5 font-weight-medium">
                  {{ formatMoney(tableValues.equityChangeAndCashSavings) }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table></v-sheet
    ></v-sheet
  >
</template>

<script>
import helpers from '../../helpers';

export default {
  props: ['finalRowLabel', 'preFinalRowLabel', 'preFinalRowValue'],
  data() {
    return { currentAppreciationRateCheck: 0 };
  },
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    selectsOptions() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions;
    },
    allAppreciationRates() {
      const allRates = this.selectsOptions.allAppreciationRates;
      const formattedRates = allRates.map((x) => ({
        value: x,
        text: this.formatPercentage(x)
      }));
      return [
        ...formattedRates,
        { value: 'Custom', text: 'Custom' },
        { value: 'Soft Landing', text: 'Soft Landing' }
      ];
    },
    currentAppreciationRate: {
      get() {
        return (
          this.$data.currentAppreciationRateCheck ||
          this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
            .propertyValueApreciationRate
        );
      },
      set(value) {
        this.$data.currentAppreciationRateCheck = value;
      }
    },
    currentEffectiveYear() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
        .effectiveAge;
    },
    tableValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.tableValues;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    },
    isCurrentAppreciationRateInList() {
      if (!this.allAppreciationRates.find((rate) => rate.value === this.currentAppreciationRate)) {
        return false;
      }
      return true;
    },
    isWrap() {
      return this.$vuetify.breakpoint.width < 585;
    }
  },
  mounted() {
    const defaultAppreciationRate =
      this.$store.state.scenarioData.currentScenarioData.selectsOptions.defaultAppreciationRate;
    if (defaultAppreciationRate) {
      this.handleScenarioOptionsChange(defaultAppreciationRate, 'propertyValueApreciationRate');
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    formatPercentage: helpers.formatPercentage,
    handleScenarioOptionsChange(value, fieldName) {
      const data = { fieldName, value };
      this.$store.commit('changeOptionValues', data);
      this.$store.dispatch('getNewScenarioData');
    }
  }
};
</script>

<style scoped>
.table {
  border: 2px solid #e2e2e2;
  border-radius: 10px;
  overflow: hidden;
}
.table-body >>> .v-data-table__wrapper {
  overflow-x: hidden;
}
.headerCell {
  width: 90px;
  min-width: 90px;
  background-color: #f3f3f3;
}
.cell {
  width: 90px;
  min-width: 90px;
}
.cellWidth {
  width: 33%;
}
.rightCell {
  border-left: 1px solid #e2e2e2;
}
</style>
