<template>
  <v-card
    class="mx-auto mb-8 py-6 px-2 px-sm-10 d-flex flex-column justify-space-between align-center"
    elevation="3"
  >
    <!-- <CaseSchiller /> -->
    <InitialEstimatedRate />
  </v-card>
</template>

<script>
// import CaseSchiller from './CaseSchiller.vue';
import InitialEstimatedRate from './InitialEstimatedRate.vue';

export default { components: { InitialEstimatedRate } };
</script>

<style scoped></style>
