<template>
  <div>
    <CustomScenarioChart :period="customChartPeriod" category="age" />

    <ConsiderVoluntaryPayments />

    <ScheduleRMPayments />

    <InterestRateScenarioAndPVARate />

    <ImportantData />
  </div>
</template>

<script>
import CustomScenarioChart from '@/components/Charts/CustomScenarioChart.vue';
import ConsiderVoluntaryPayments from '@/components/ScenarioB/ConsiderVoluntaryPayments.vue';
import ScheduleRMPayments from '@/components/ScenarioB/ScheduleRMPayments.vue';
import InterestRateScenarioAndPVARate from '@/components/ScenarioB/InterestRateScenarioAndPVARate.vue';
import ImportantData from '@/components/ScenarioB/ImportantData/ImportantData.vue';

import helpers from '@/helpers';

export default {
  components: {
    CustomScenarioChart,
    ConsiderVoluntaryPayments,
    ScheduleRMPayments,
    InterestRateScenarioAndPVARate,
    ImportantData
  },
  computed: {
    customChartPeriod() {
      return this.maxPeriod(this.youngestOwnerAge);
    }
  },
  methods: {
    maxPeriod: helpers.getMaxPeriod
  }
};
</script>

<style scoped>
.page-break {
  break-inside: avoid;
  page-break-inside: avoid;
}

@media print {
  .page-break {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>
