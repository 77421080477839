<template>
  <div>
    <div v-if="referral" class="font-weight-bold">
      Created by referral:
      <span style="color: #4caf50">{{ referral.name }} / {{ referral.email }}</span>
    </div>
    <div v-if="currentScenarioTitle" class="pt-8">
      <div v-if="currentScenarioTitle === allScenariosTitles.scenarioATitle">
        <ScenarioASavedResult />
      </div>
      <div v-if="currentScenarioTitle === allScenariosTitles.scenarioBTitle">
        <ScenarioBSavedResult />
      </div>
      <div v-if="currentScenarioTitle === allScenariosTitles.scenarioCTitle">
        <ScenarioC />
      </div>
      <div v-if="currentScenarioTitle === allScenariosTitles.scenarioDTitle">
        <ScenarioD />
      </div>
      <div v-if="currentScenarioTitle === allScenariosTitles.scenarioETitle">
        <ScenarioE />
      </div>
    </div>

    <LeadCaptureDescription />
    <Footer />
  </div>
</template>

<script>
import ScenarioASavedResult from '@/components/ScenarioA/SavedResult/index.vue';
import ScenarioBSavedResult from '@/components/ScenarioB/SavedResult/index.vue';
import ScenarioC from '@/components/ScenarioC/index.vue';
import ScenarioD from '@/components/ScenarioD/index.vue';
import ScenarioE from '@/components/ScenarioE/index.vue';
import Footer from '@/components/LeadCapture/Footer.vue';
import LeadCaptureDescription from '@/components/LeadCapture/Description/index.vue';

export default {
  components: {
    ScenarioASavedResult,
    ScenarioBSavedResult,
    ScenarioC,
    ScenarioD,
    ScenarioE,
    Footer,
    LeadCaptureDescription
  },
  computed: {
    currentScenarioTitle() {
      return this.$store.state.scenarioData.scenario;
    },
    allScenariosTitles() {
      return this.$store.state.scenarioData.allScenarioTitles;
    },
    referral() {
      return this.$store.state.scenarioData.referral;
    }
  },
  created() {
    console.log('saved result');
    this.$store.commit('setExpanded', 0);
  }
};
</script>
