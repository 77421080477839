<template>
  <div class="my-4 full-width">
    <v-expansion-panels :value="isResultSaved ? 0 : null">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-sheet class="d-flex flex-column generalBackground" width="100%">
            <div class="clauseText--text medium-small-text-font my-2">
              4. Consider making voluntary payments on a reverse mortgage
            </div>
          </v-sheet>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
          <div class="small-text-font">
            Each payment is applied to the outstanding loan balance and increases the Line of Credit
            amount available to you in the future.
          </div>
          <v-sheet
            class="d-flex flex-column justify-space-between generalBackground"
            width="100%"
            max-width="460"
          >
            <MonthlyVoluntaryPaymentsTextField
              label="Monthly Voluntary Payments"
              :customBackground="true" />
            <VoluntaryPaymentsPeriodSelectors
              yearsLabel="Number of years"
              startingLabel="Starting"
              :showTooltip="true"
              :customBackground="true"
          /></v-sheet>
          <v-sheet style="flex: 1" min-width="280" class="generalBackground" width="100%"
            ><CustomScenarioChart :period="customChartPeriod" category="age" />
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MonthlyVoluntaryPaymentsTextField from '../../Selectors/MonthlyVoluntaryPaymentsTextField.vue';
import VoluntaryPaymentsPeriodSelectors from '../../Selectors/VoluntaryPaymentsPeriodSelectors.vue';
import CustomScenarioChart from '../../Charts/CustomScenarioChart.vue';
import helpers from '../../../helpers';

export default {
  components: {
    MonthlyVoluntaryPaymentsTextField,
    VoluntaryPaymentsPeriodSelectors,
    CustomScenarioChart
  },
  computed: {
    ...mapGetters(['isResultSaved']),
    youngestOwnerAge() {
      return this.$store.state.scenarioData.landingPageFormData.basic.youngestOwnerAge;
    },
    customChartPeriod() {
      return this.maxPeriod(this.youngestOwnerAge);
    }
  },
  methods: { maxPeriod: helpers.getMaxPeriod }
};
</script>

<style scoped></style>
