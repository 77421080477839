import Vue from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const options = {
  maxToasts: 1,
  position: 'bottom-left',
  timeout: 5000
};

export default Vue.use(Toast, options);
