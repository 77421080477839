import numeral from 'numeral';
import moment from 'moment';

const formatMoney = (value) => {
  if (typeof value === 'string') return value;
  return numeral(value).format('$0,0');
};
const formatMoneyWithDecimals = (value) => {
  if (typeof value === 'string') return value;
  return numeral(value).format('$0,0.00');
};

const formatMoneyForInput = (value) => {
  if (!value && value !== 0) {
    return '';
  }

  return numeral(value).format('0,0');
};

const unformatMoney = (value) => {
  const splitedValue = value.split(',');
  const isNaN =
    value.length > 1
      ? splitedValue.some((num) => Number.isNaN(Number(num)))
      : Number.isNaN(Number(splitedValue[0]));

  if (isNaN) {
    return null;
  }

  const parsedValue = numeral(value).value();

  return parsedValue;
};

const roundToThousandsAndFormatMoney = (value) => {
  const helperValue = 1000;
  const divided = numeral(value).divide(helperValue).value();
  const rounded = divided.toFixed(0);
  const multiplied = numeral(rounded).multiply(helperValue).value();
  return formatMoney(multiplied);
};

const formatPercentage = (value) => {
  if (typeof value === 'string') return value;

  const valueToMultiply = 10000;

  const multiplied = numeral(value).multiply(valueToMultiply).value();
  const rounded = Math.round(multiplied);
  const divided = numeral(rounded).divide(valueToMultiply).value();
  return numeral(divided).format('0.00%');
};

const formatPercentageFull = (value) => {
  if (typeof value === 'string') return value;

  const valueToMultiply = 10000;

  const multiplied = numeral(value).multiply(valueToMultiply).value();
  const rounded = Math.round(multiplied);
  const divided = numeral(rounded).divide(valueToMultiply).value();
  return numeral(divided).format('0%');
};

const getPMT = (ratePerPeriod, numberOfPayments, presentValue, futureValue, type) => {
  if (ratePerPeriod !== 0) {
    // Interest rate exists
    const pow = Math.pow(numeral(1).add(ratePerPeriod).value(), numberOfPayments);
    return numeral(
      -numeral(ratePerPeriod)
        .multiply(numeral(pow).multiply(presentValue).add(futureValue).value())
        .value()
    )
      .divide(
        numeral(numeral(-1).add(pow).value())
          .multiply(numeral(ratePerPeriod).multiply(type).add(1).value())
          .value()
      )
      .value();
  } else if (numberOfPayments !== 0) {
    // No interest rate, but number of payments exists
    return numeral(-numeral(futureValue).add(presentValue).value())
      .divide(numberOfPayments)
      .value();
  }
  return 0;
};

const getMaxPeriod = (age) => {
  const minAge = 65,
    midAge = 75,
    maxAge = 80;
  if (age <= minAge) {
    return 25;
  } else if (age <= midAge) {
    return 20;
  } else if (age <= maxAge) {
    return 15;
  } else {
    return 15;
  }
};

const dateMutating = (value, action) => {
  const dbFormat = 'MM/DD/YYYY';
  const pickerFormat = 'YYYY-MM-DD';
  if (action === 'toDB') {
    return moment(value, pickerFormat).format(dbFormat);
  }
  if (action === 'toPicker') {
    return moment(value, dbFormat).format(pickerFormat);
  }
  return null;
};

const formatDecimal = (value) => {
  if (typeof value === 'string') return value;
  return numeral(value).format('0.000');
};

export default {
  formatMoney,
  formatMoneyWithDecimals,
  formatMoneyForInput,
  unformatMoney,
  roundToThousandsAndFormatMoney,
  formatPercentage,
  formatPercentageFull,
  getPMT,
  getMaxPeriod,
  dateMutating,
  formatDecimal
};
