<template>
  <div class="my-4 full-width">
    <v-expansion-panels :value="0">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="clauseText--text medium-small-text-font align-self-start">
            4. Change the number in the blue cell below to see important data at a particular age of
            the youngest borrower.
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-sheet class="d-flex flex-wrap align-center justify-space-between mb-10" width="100%">
            <v-sheet
              min-width="400"
              width="100%"
              :max-width="$vuetify.breakpoint.md ? '100%' : '50%'"
              class="d-flex flex-column align-center mb-8"
            >
              <v-sheet class="mb-8" width="100%" min-width="300" max-width="1000">
                <NowVsFutureTableAB />
              </v-sheet>
            </v-sheet>
            <v-sheet
              min-width="280"
              width="100%"
              :max-width="$vuetify.breakpoint.md ? '100%' : '40%'"
            >
              <CustomScenarioChart :period="customChartPeriod" category="age" />
            </v-sheet>
          </v-sheet>
          <v-sheet width="100%" :max-width="$vuetify.breakpoint.md ? 600 : '50%'">
            <CashFlowImpactChart />
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import CustomScenarioChart from '../../Charts/CustomScenarioChart.vue';
import NowVsFutureTableAB from '../../Tables/NowVsFutureTableAB.vue';
import CashFlowImpactChart from '../../Charts/CashFlowImpactChart.vue';
import helpers from '../../../helpers';

export default {
  components: {
    CustomScenarioChart,
    NowVsFutureTableAB,
    CashFlowImpactChart
  },
  computed: {
    youngestOwnerAge() {
      return this.$store.state.scenarioData.landingPageFormData.basic.youngestOwnerAge;
    },
    customChartPeriod() {
      return this.maxPeriod(this.youngestOwnerAge);
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    maxPeriod: helpers.getMaxPeriod
  }
};
</script>
