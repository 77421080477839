import guestAxios from '@/axios';
import _ from 'lodash';
import { initUserAxiosInstance } from '@/axios';

const getDefaultState = () => ({
  uid: null,
  tenant: null,
  info: null,
  referral: null,
  landingPageContent: {}
});

export default {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    isLoanOfficerLoaded(state) {
      return !!state.uid;
    }
  },
  mutations: {
    SET_LOAN_OFFICER(state, data) {
      const { uid, tenant, info, referral, landingPageContent } = data;
      state.uid = uid;
      state.tenant = tenant;
      state.referral = referral;
      state.info = info;
      if (!_.isEmpty(landingPageContent)) {
        const content = {};
        _.each(landingPageContent, (item) => {
          content[item.type] = item.content;
        });
        state.landingPageContent = content;
      }
    }
  },
  actions: {
    async getLoanOfficer({ commit }, { uid, referral }) {
      const params = { uid, referral };
      const { data } = await guestAxios.get('/public/loan-officer', { params });
      if (!data) return;

      commit('SET_LOAN_OFFICER', data);
      document.title = `${data.info.firstName} ${data.info.lastName} | GRIPH`;
      initUserAxiosInstance({ loanOfficerUID: data.uid });
    }
  }
};
