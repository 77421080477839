export const minOptionsValues = {
  minYearBuilt: 1976,
  minSqFt: 600,
  dollarToSqFtMonthCharge: 0.14,
  propTaxes: 0.0075,
  hazardInsuranceAnnunal: { SFRetc: 1050, condominium: 500 },
  taxesAndInsurance: 1550,
  withholdingTaxOnIRAsetc: 15,
  residualIncomeWiggleRoom: 25,
  ageMinMarriedBorrower: 18,
  ageMinSingleBorrower: 62
};

export const propertyTypes = [
  { value: 'Single Family' },
  { value: 'Condominium' },
  { value: 'Manufactured Home', year: true },
  { value: 'Manufactured Condo', year: true },
  { value: '2 to 4 Units' },
  { value: 'Coop', unable: true },
  { value: 'Townhouse' },
  { value: 'Site Condminium' }
];
export const historyItems = [
  { value: 'Excellent' },
  { value: 'Average' },
  { value: 'Below Average' }
];
export const yesNo = [
  { title: 'Yes', value: true },
  { title: 'No', value: false }
];

export const statesAndZipCodes = [
  {
    state: 'Alabama',
    abbreviation: 'AL',
    zipCode: 36702
  },
  {
    state: 'Alaska',
    abbreviation: 'AK',
    zipCode: 99716
  },
  {
    state: 'Arizona',
    abbreviation: 'AZ',
    zipCode: 85211
  },
  {
    state: 'Arkansas',
    abbreviation: 'AR',
    zipCode: 71802
  },
  {
    state: 'California',
    abbreviation: 'CA',
    zipCode: 94115
  },
  {
    state: 'Colorado',
    abbreviation: 'CO',
    zipCode: 80302
  },
  {
    state: 'Connecticut',
    abbreviation: 'CT',
    zipCode: 6388
  },
  {
    state: 'Delaware',
    abbreviation: 'DE',
    zipCode: 19810
  },
  {
    state: 'District of Columbia',
    abbreviation: 'DC',
    zipCode: 20011
  },
  {
    state: 'Florida',
    abbreviation: 'FL',
    zipCode: 32411
  },
  {
    state: 'Georgia',
    abbreviation: 'GA',
    zipCode: 31210
  },
  {
    state: 'Hawaii',
    abbreviation: 'HI',
    zipCode: 96782
  },
  {
    state: 'Idaho',
    abbreviation: 'ID',
    zipCode: 83340
  },
  {
    state: 'Illinois',
    abbreviation: 'IL',
    zipCode: 61802
  },
  {
    state: 'Indiana',
    abbreviation: 'IN',
    zipCode: 46403
  },
  {
    state: 'Iowa',
    abbreviation: 'IA',
    zipCode: 51577
  },
  {
    state: 'Kansas',
    abbreviation: 'KS',
    zipCode: 66502
  },
  {
    state: 'Kentucky',
    abbreviation: 'KY',
    zipCode: 41014
  },
  {
    state: 'Louisiana',
    abbreviation: 'LA',
    zipCode: 70810
  },
  {
    state: 'Maine',
    abbreviation: 'ME',
    zipCode: 4631
  },
  {
    state: 'Maryland',
    abbreviation: 'MD',
    zipCode: 21078
  },
  {
    state: 'Massachusetts',
    abbreviation: 'MA',
    zipCode: 1970
  },
  {
    state: 'Michigan',
    abbreviation: 'MI',
    zipCode: 48104
  },
  {
    state: 'Minnesota',
    abbreviation: 'MN',
    zipCode: 55447
  },
  {
    state: 'Mississippi',
    abbreviation: 'MS',
    zipCode: 39201
  },
  {
    state: 'Missouri',
    abbreviation: 'MO',
    zipCode: 64102
  },
  {
    state: 'Montana',
    abbreviation: 'MT',
    zipCode: 59602
  },
  {
    state: 'Nebraska',
    abbreviation: 'NE',
    zipCode: 68502
  },
  {
    state: 'Nevada',
    abbreviation: 'NV',
    zipCode: 89044
  },
  {
    state: 'New Hampshire',
    abbreviation: 'NH',
    zipCode: 3431
  },
  {
    state: 'New Jersey',
    abbreviation: 'NJ',
    zipCode: 7762
  },
  {
    state: 'New Mexico',
    abbreviation: 'NM',
    zipCode: 87104
  },
  {
    state: 'New York',
    abbreviation: 'NY',
    zipCode: 10549
  },
  {
    state: 'North Carolina',
    abbreviation: 'NC',
    zipCode: 27909
  },
  {
    state: 'North Dakota',
    abbreviation: 'ND',
    zipCode: 58005
  },
  {
    state: 'Ohio',
    abbreviation: 'OH',
    zipCode: 43002
  },
  {
    state: 'Oklahoma',
    abbreviation: 'OK',
    zipCode: 73703
  },
  {
    state: 'Oregon',
    abbreviation: 'OR',
    zipCode: 97201
  },
  {
    state: 'Pennsylvania',
    abbreviation: 'PA',
    zipCode: 19607
  },
  {
    state: 'Rhode Island',
    abbreviation: 'PR',
    zipCode: 610
  },
  {
    state: 'Puerto Rico',
    abbreviation: 'RI',
    zipCode: 2882
  },
  {
    state: 'South Carolina',
    abbreviation: 'SC',
    zipCode: 29061
  },
  {
    state: 'South Dakota',
    abbreviation: 'SD',
    zipCode: 57501
  },
  {
    state: 'Tennessee',
    abbreviation: 'TN',
    zipCode: 37072
  },
  {
    state: 'Texas',
    abbreviation: 'TX',
    zipCode: 75935
  },
  {
    state: 'Utah',
    abbreviation: 'UT',
    zipCode: 84102
  },
  {
    state: 'Vermont',
    abbreviation: 'VT',
    zipCode: 5672
  },
  {
    state: 'Virginia',
    abbreviation: 'VA',
    zipCode: 20104
  },
  {
    state: 'Virgin Islands',
    abbreviation: 'VI',
    zipCode: 805
  },
  {
    state: 'Washington',
    abbreviation: 'WA',
    zipCode: 98002
  },
  {
    state: 'West Virginia',
    abbreviation: 'WV',
    zipCode: 24714
  },
  {
    state: 'Wisconsin',
    abbreviation: 'WI',
    zipCode: 53215
  },
  {
    state: 'Wyoming',
    abbreviation: 'WY',
    zipCode: 82003
  }
];

export const scenarioData = [
  {
    title: 'Scenario A',
    value: {
      basic: {
        youngestOwnerAge: 77,
        isBirthDaySoon: false,
        propertyValue: 625000
      },
      creditAndProperty: {
        creditHistory: 'Average',
        monthlyInstallmentPayment: 250,
        minMonthlyCreditCardPayments: 125,
        propertyType: '2 to 4 Units',
        yearBuilt: 1977,
        numberPeopleInHome: 3,
        isPayingHOADues: false,
        monthlyAmount: 175,
        estimatedSquareFeet: 2850,
        isAnyOtherProperties: true,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2700,
        checkingSavingsAccounts: 5000,
        taxableInvestmentAccount: 35000,
        additionalAssets: 100000
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 3000,
        yearsLeftOnMortgage: 7,
        areTaxesEscrowed: false,
        annualTaxes: 7500,
        annualInsurance: 1800,
        homeEquityLine: 35000,
        monthlyHelocPayment: 225,
        yearsOnHomeEquityLine: 9
      }
    }
  },
  {
    title: 'Scenario A2',
    value: {
      basic: {
        youngestOwnerAge: 77,
        isBirthDaySoon: false,
        propertyValue: 625000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 250,
        minMonthlyCreditCardPayments: 125,
        propertyType: '2 to 4 Units',
        yearBuilt: 1977,
        numberPeopleInHome: 3,
        isPayingHOADues: false,
        monthlyAmount: 175,
        estimatedSquareFeet: 2850,
        isAnyOtherProperties: true,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2700,
        checkingSavingsAccounts: 5000,
        taxableInvestmentAccount: 35000,
        additionalAssets: 100000
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 3000,
        yearsLeftOnMortgage: 7,
        areTaxesEscrowed: false,
        annualTaxes: 7500,
        annualInsurance: 1800,
        homeEquityLine: 35000,
        monthlyHelocPayment: 225,
        yearsOnHomeEquityLine: 9
      }
    }
  },
  {
    title: 'Scenario A3',
    value: {
      basic: {
        youngestOwnerAge: 68,
        isBirthDaySoon: true,
        propertyValue: 500000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 250,
        minMonthlyCreditCardPayments: 125,
        propertyType: 'Condominium',
        yearBuilt: 1977,
        numberPeopleInHome: 1,
        isPayingHOADues: true,
        monthlyAmount: 175,
        estimatedSquareFeet: 1400,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 1560,
        checkingSavingsAccounts: 5000,
        taxableInvestmentAccount: 35000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 3000,
        yearsLeftOnMortgage: 7,
        areTaxesEscrowed: false,
        annualTaxes: 3500,
        annualInsurance: 1150,
        homeEquityLine: 18000,
        monthlyHelocPayment: 55,
        yearsOnHomeEquityLine: 21
      }
    }
  },
  {
    title: 'Scenario A4',
    value: {
      basic: {
        youngestOwnerAge: 70,
        isBirthDaySoon: false,
        propertyValue: 700000
      },
      creditAndProperty: {
        creditHistory: 'Average',
        monthlyInstallmentPayment: 200,
        minMonthlyCreditCardPayments: 125,
        propertyType: 'Condominium',
        yearBuilt: 1977,
        numberPeopleInHome: 1,
        isPayingHOADues: true,
        monthlyAmount: 350,
        estimatedSquareFeet: 1700,
        isAnyOtherProperties: false,
        state: 'Utah'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2800,
        checkingSavingsAccounts: 12000,
        taxableInvestmentAccount: 100000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 0,
        yearsLeftOnMortgage: 0,
        areTaxesEscrowed: false,
        annualTaxes: 6000,
        annualInsurance: 700,
        homeEquityLine: 0,
        monthlyHelocPayment: 0,
        yearsOnHomeEquityLine: 0
      }
    }
  },
  {
    title: 'Scenario A5',
    value: {
      basic: {
        youngestOwnerAge: 70,
        isBirthDaySoon: false,
        propertyValue: 700000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 125,
        minMonthlyCreditCardPayments: 100,
        propertyType: 'Single Family',
        yearBuilt: 1977,
        numberPeopleInHome: 2,
        isPayingHOADues: true,
        monthlyAmount: 175,
        estimatedSquareFeet: 2000,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 4500,
        checkingSavingsAccounts: 14500,
        taxableInvestmentAccount: 25000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 0,
        yearsLeftOnMortgage: 0,
        areTaxesEscrowed: false,
        annualTaxes: 5000,
        annualInsurance: 1000,
        homeEquityLine: 15000,
        monthlyHelocPayment: 125,
        yearsOnHomeEquityLine: 12
      }
    }
  },
  {
    title: 'Scenario A6',
    value: {
      basic: {
        youngestOwnerAge: 70,
        isBirthDaySoon: false,
        propertyValue: 700000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 125,
        minMonthlyCreditCardPayments: 100,
        propertyType: 'Manufactured Home',
        yearBuilt: 2013,
        numberPeopleInHome: 2,
        isPayingHOADues: true,
        monthlyAmount: 175,
        estimatedSquareFeet: 1440,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 3500,
        checkingSavingsAccounts: 15000,
        taxableInvestmentAccount: 200000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 0,
        yearsLeftOnMortgage: 0,
        areTaxesEscrowed: false,
        annualTaxes: 5000,
        annualInsurance: 1000,
        homeEquityLine: 15000,
        monthlyHelocPayment: 125,
        yearsOnHomeEquityLine: 12
      }
    }
  },
  {
    title: 'Scenario A7',
    value: {
      basic: {
        youngestOwnerAge: 65,
        isBirthDaySoon: false,
        propertyValue: 700000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 124,
        minMonthlyCreditCardPayments: 65,
        propertyType: 'Manufactured Home',
        yearBuilt: 1990,
        numberPeopleInHome: 1,
        isPayingHOADues: false,
        monthlyAmount: 175,
        estimatedSquareFeet: 1800,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 6000,
        checkingSavingsAccounts: 5000,
        taxableInvestmentAccount: 55000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 0,
        yearsLeftOnMortgage: 0,
        areTaxesEscrowed: false,
        annualTaxes: 5000,
        annualInsurance: 1235,
        homeEquityLine: 0,
        monthlyHelocPayment: 125,
        yearsOnHomeEquityLine: 12
      }
    }
  },
  {
    title: 'Scenario A8',
    value: {
      basic: {
        youngestOwnerAge: 62,
        isBirthDaySoon: false,
        propertyValue: 600000
      },
      creditAndProperty: {
        creditHistory: 'Excellent',
        monthlyInstallmentPayment: 90,
        minMonthlyCreditCardPayments: 60,
        propertyType: 'Single Family',
        yearBuilt: 1990,
        numberPeopleInHome: 2,
        isPayingHOADues: false,
        monthlyAmount: 175,
        estimatedSquareFeet: 1800,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 6000,
        checkingSavingsAccounts: 56000,
        taxableInvestmentAccount: 55000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 0,
        yearsLeftOnMortgage: 0,
        areTaxesEscrowed: false,
        annualTaxes: 6000,
        annualInsurance: 1200,
        homeEquityLine: 0,
        monthlyHelocPayment: 125,
        yearsOnHomeEquityLine: 12
      }
    }
  },
  {
    title: 'Scenario A8.5',
    value: {
      basic: {
        youngestOwnerAge: 62,
        isBirthDaySoon: false,
        propertyValue: 600000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 90,
        minMonthlyCreditCardPayments: 60,
        propertyType: 'Single Family',
        yearBuilt: 1990,
        numberPeopleInHome: 2,
        isPayingHOADues: false,
        monthlyAmount: 175,
        estimatedSquareFeet: 1800,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 6000,
        checkingSavingsAccounts: 56000,
        taxableInvestmentAccount: 55000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 0,
        yearsLeftOnMortgage: 0,
        areTaxesEscrowed: false,
        annualTaxes: 6000,
        annualInsurance: 1200,
        homeEquityLine: 0,
        monthlyHelocPayment: 125,
        yearsOnHomeEquityLine: 12
      }
    }
  },
  {
    title: 'Scenario B',
    value: {
      basic: {
        youngestOwnerAge: 64,
        isBirthDaySoon: true,
        propertyValue: 800000
      },
      creditAndProperty: {
        creditHistory: 'Excellent',
        monthlyInstallmentPayment: 400,
        minMonthlyCreditCardPayments: 125,
        propertyType: 'Manufactured Home',
        yearBuilt: 1999,
        numberPeopleInHome: 2,
        isPayingHOADues: true,
        monthlyAmount: 115,
        estimatedSquareFeet: 1440,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2700,
        checkingSavingsAccounts: 17000,
        taxableInvestmentAccount: 35000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 190000,
        firstMortgageMonthlyPayment: 2400,
        yearsLeftOnMortgage: 15,
        areTaxesEscrowed: true,
        annualTaxes: 7500,
        annualInsurance: 1800,
        homeEquityLine: 35000,
        monthlyHelocPayment: 225,
        yearsOnHomeEquityLine: 9
      }
    }
  },
  {
    title: 'Scenario B2',
    value: {
      basic: {
        youngestOwnerAge: 64,
        isBirthDaySoon: true,
        propertyValue: 800000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 400,
        minMonthlyCreditCardPayments: 125,
        propertyType: 'Manufactured Home',
        yearBuilt: 1999,
        numberPeopleInHome: 2,
        isPayingHOADues: true,
        monthlyAmount: 115,
        estimatedSquareFeet: 1440,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2700,
        checkingSavingsAccounts: 17000,
        taxableInvestmentAccount: 35000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 190000,
        firstMortgageMonthlyPayment: 2400,
        yearsLeftOnMortgage: 15,
        areTaxesEscrowed: false,
        annualTaxes: 7500,
        annualInsurance: 1800,
        homeEquityLine: 0,
        monthlyHelocPayment: 0,
        yearsOnHomeEquityLine: 0
      }
    }
  },
  {
    title: 'Scenario B3',
    value: {
      basic: {
        youngestOwnerAge: 68,
        isBirthDaySoon: false,
        propertyValue: 500000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 250,
        minMonthlyCreditCardPayments: 125,
        propertyType: 'Condominium',
        yearBuilt: 1999,
        numberPeopleInHome: 1,
        isPayingHOADues: true,
        monthlyAmount: 175,
        estimatedSquareFeet: 1400,
        isAnyOtherProperties: true,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 1560,
        checkingSavingsAccounts: 5000,
        taxableInvestmentAccount: 350000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 0,
        yearsLeftOnMortgage: 0,
        areTaxesEscrowed: false,
        annualTaxes: 3500,
        annualInsurance: 1150,
        homeEquityLine: 68000,
        monthlyHelocPayment: 55,
        yearsOnHomeEquityLine: 21
      }
    }
  },
  {
    title: 'Scenario B4',
    value: {
      basic: {
        youngestOwnerAge: 73,
        isBirthDaySoon: false,
        propertyValue: 550000
      },
      creditAndProperty: {
        creditHistory: 'Average',
        monthlyInstallmentPayment: 250,
        minMonthlyCreditCardPayments: 125,
        propertyType: 'Single Family',
        yearBuilt: 1977,
        numberPeopleInHome: 1,
        isPayingHOADues: true,
        monthlyAmount: 175,
        estimatedSquareFeet: 1400,
        isAnyOtherProperties: true,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 1560,
        checkingSavingsAccounts: 5000,
        taxableInvestmentAccount: 350000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 0,
        yearsLeftOnMortgage: 0,
        areTaxesEscrowed: false,
        annualTaxes: 3500,
        annualInsurance: 1150,
        homeEquityLine: 110000,
        monthlyHelocPayment: 275,
        yearsOnHomeEquityLine: 21
      }
    }
  },
  {
    title: 'Scenario B5',
    value: {
      basic: {
        youngestOwnerAge: 65,
        isBirthDaySoon: false,
        propertyValue: 625000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 128,
        minMonthlyCreditCardPayments: 200,
        propertyType: 'Single Family',
        yearBuilt: 2002,
        numberPeopleInHome: 1,
        isPayingHOADues: true,
        monthlyAmount: 125,
        estimatedSquareFeet: 1440,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2500,
        checkingSavingsAccounts: 45000,
        taxableInvestmentAccount: 300000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 29500,
        firstMortgageMonthlyPayment: 1240,
        yearsLeftOnMortgage: 4,
        areTaxesEscrowed: false,
        annualTaxes: 3750,
        annualInsurance: 1700,
        homeEquityLine: 0,
        monthlyHelocPayment: 0,
        yearsOnHomeEquityLine: 0
      }
    }
  },
  {
    title: 'Scenario B6',
    value: {
      basic: {
        youngestOwnerAge: 75,
        isBirthDaySoon: true,
        propertyValue: 800000
      },
      creditAndProperty: {
        creditHistory: 'Excellent',
        monthlyInstallmentPayment: 0,
        minMonthlyCreditCardPayments: 225,
        propertyType: '2 to 4 Units',
        yearBuilt: 1977,
        numberPeopleInHome: 3,
        isPayingHOADues: false,
        monthlyAmount: 0,
        estimatedSquareFeet: 2200,
        isAnyOtherProperties: false,
        state: 'Colorado'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 4650,
        checkingSavingsAccounts: 15000,
        taxableInvestmentAccount: 50000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 200000,
        firstMortgageMonthlyPayment: 1750,
        yearsLeftOnMortgage: 12,
        areTaxesEscrowed: true,
        annualTaxes: 12400,
        annualInsurance: 2400,
        homeEquityLine: 0,
        monthlyHelocPayment: 0,
        yearsOnHomeEquityLine: 0
      }
    }
  },
  {
    title: 'Scenario B7',
    value: {
      basic: {
        youngestOwnerAge: 65,
        isBirthDaySoon: false,
        propertyValue: 700000
      },
      creditAndProperty: {
        creditHistory: 'Excellent',
        monthlyInstallmentPayment: 300,
        minMonthlyCreditCardPayments: 100,
        propertyType: 'Manufactured Home',
        yearBuilt: 2010,
        numberPeopleInHome: 3,
        isPayingHOADues: false,
        monthlyAmount: 0,
        estimatedSquareFeet: 2500,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 3800,
        checkingSavingsAccounts: 10000,
        taxableInvestmentAccount: 175000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 125000,
        firstMortgageMonthlyPayment: 1200,
        yearsLeftOnMortgage: 13,
        areTaxesEscrowed: true,
        annualTaxes: 5000,
        annualInsurance: 1000,
        homeEquityLine: 0,
        monthlyHelocPayment: 0,
        yearsOnHomeEquityLine: 0
      }
    }
  },
  {
    title: 'Scenario B8',
    value: {
      basic: {
        youngestOwnerAge: 70,
        isBirthDaySoon: false,
        propertyValue: 600000
      },
      creditAndProperty: {
        creditHistory: 'Excellent',
        monthlyInstallmentPayment: 100,
        minMonthlyCreditCardPayments: 125,
        propertyType: 'Single Family',
        yearBuilt: 2010,
        numberPeopleInHome: 2,
        isPayingHOADues: false,
        monthlyAmount: 0,
        estimatedSquareFeet: 2000,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 3500,
        checkingSavingsAccounts: 70000,
        taxableInvestmentAccount: 0,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 80000,
        firstMortgageMonthlyPayment: 975,
        yearsLeftOnMortgage: 20,
        areTaxesEscrowed: false,
        annualTaxes: 5000,
        annualInsurance: 1300,
        homeEquityLine: 0,
        monthlyHelocPayment: 0,
        yearsOnHomeEquityLine: 0
      }
    }
  },
  {
    title: 'Scenario C',
    value: {
      basic: {
        youngestOwnerAge: 71,
        isBirthDaySoon: true,
        propertyValue: 450000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 350,
        minMonthlyCreditCardPayments: 75,
        propertyType: '2 to 4 Units',
        yearBuilt: 1977,
        numberPeopleInHome: 3,
        isPayingHOADues: false,
        monthlyAmount: 175,
        estimatedSquareFeet: 2250,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2680,
        checkingSavingsAccounts: 15000,
        taxableInvestmentAccount: 35000,
        additionalAssets: 50000
      },
      mortgages: {
        firstMortgageBalance: 133000,
        firstMortgageMonthlyPayment: 860,
        yearsLeftOnMortgage: 23,
        areTaxesEscrowed: true,
        annualTaxes: 2875,
        annualInsurance: 985,
        homeEquityLine: 0,
        monthlyHelocPayment: 65,
        yearsOnHomeEquityLine: 20
      }
    }
  },
  {
    title: 'Scenario C2',
    value: {
      basic: {
        youngestOwnerAge: 71,
        isBirthDaySoon: true,
        propertyValue: 450000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 350,
        minMonthlyCreditCardPayments: 75,
        propertyType: '2 to 4 Units',
        yearBuilt: 1977,
        numberPeopleInHome: 3,
        isPayingHOADues: false,
        monthlyAmount: 175,
        estimatedSquareFeet: 2250,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2680,
        checkingSavingsAccounts: 15000,
        taxableInvestmentAccount: 35000,
        additionalAssets: 50000
      },
      mortgages: {
        firstMortgageBalance: 155000,
        firstMortgageMonthlyPayment: 860,
        yearsLeftOnMortgage: 23,
        areTaxesEscrowed: false,
        annualTaxes: 2875,
        annualInsurance: 985,
        homeEquityLine: 25000,
        monthlyHelocPayment: 65,
        yearsOnHomeEquityLine: 20
      }
    }
  },
  {
    title: 'Scenario C3',
    value: {
      basic: {
        youngestOwnerAge: 65,
        isBirthDaySoon: false,
        propertyValue: 575000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 0,
        minMonthlyCreditCardPayments: 150,
        propertyType: 'Single Family',
        yearBuilt: 1977,
        numberPeopleInHome: 2,
        isPayingHOADues: false,
        monthlyAmount: 0,
        estimatedSquareFeet: 1850,
        isAnyOtherProperties: false,
        state: 'Oregon'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 1200,
        checkingSavingsAccounts: 75000,
        taxableInvestmentAccount: 0,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 0,
        yearsLeftOnMortgage: 0,
        areTaxesEscrowed: false,
        annualTaxes: 6000,
        annualInsurance: 700,
        homeEquityLine: 175000,
        monthlyHelocPayment: 810,
        yearsOnHomeEquityLine: 20
      }
    }
  },
  {
    title: 'Scenario C4',
    value: {
      basic: {
        youngestOwnerAge: 70,
        isBirthDaySoon: false,
        propertyValue: 950000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 0,
        minMonthlyCreditCardPayments: 75,
        propertyType: 'Single Family',
        yearBuilt: 1977,
        numberPeopleInHome: 1,
        isPayingHOADues: true,
        monthlyAmount: 250,
        estimatedSquareFeet: 1800,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2200,
        checkingSavingsAccounts: 255000,
        taxableInvestmentAccount: 175000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 0,
        firstMortgageMonthlyPayment: 0,
        yearsLeftOnMortgage: 0,
        areTaxesEscrowed: false,
        annualTaxes: 5500,
        annualInsurance: 1350,
        homeEquityLine: 250000,
        monthlyHelocPayment: 1200,
        yearsOnHomeEquityLine: 17
      }
    }
  },
  {
    title: 'Scenario D',
    value: {
      basic: {
        youngestOwnerAge: 78,
        isBirthDaySoon: false,
        propertyValue: 575000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 0,
        minMonthlyCreditCardPayments: 75,
        propertyType: '2 to 4 Units',
        yearBuilt: 1977,
        numberPeopleInHome: 2,
        isPayingHOADues: false,
        monthlyAmount: 175,
        estimatedSquareFeet: 3150,
        isAnyOtherProperties: true,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2700,
        checkingSavingsAccounts: 17000,
        taxableInvestmentAccount: 75000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 165000,
        firstMortgageMonthlyPayment: 1800,
        yearsLeftOnMortgage: 15,
        areTaxesEscrowed: false,
        annualTaxes: 7500,
        annualInsurance: 1800,
        homeEquityLine: 45000,
        monthlyHelocPayment: 135,
        yearsOnHomeEquityLine: 21
      }
    }
  },
  {
    title: 'Scenario D2',
    value: {
      basic: {
        youngestOwnerAge: 73,
        isBirthDaySoon: false,
        propertyValue: 575000
      },
      creditAndProperty: {
        creditHistory: 'Excellent',
        monthlyInstallmentPayment: 0,
        minMonthlyCreditCardPayments: 75,
        propertyType: '2 to 4 Units',
        yearBuilt: 1977,
        numberPeopleInHome: 2,
        isPayingHOADues: false,
        monthlyAmount: 175,
        estimatedSquareFeet: 3150,
        isAnyOtherProperties: true,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2700,
        checkingSavingsAccounts: 17000,
        taxableInvestmentAccount: 350000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 275000,
        firstMortgageMonthlyPayment: 1800,
        yearsLeftOnMortgage: 15,
        areTaxesEscrowed: false,
        annualTaxes: 7500,
        annualInsurance: 1800,
        homeEquityLine: 30000,
        monthlyHelocPayment: 270,
        yearsOnHomeEquityLine: 21
      }
    }
  },
  {
    title: 'Scenario D3',
    value: {
      basic: {
        youngestOwnerAge: 18,
        isBirthDaySoon: false,
        propertyValue: 500000
      },
      creditAndProperty: {
        creditHistory: 'Average',
        monthlyInstallmentPayment: 250,
        minMonthlyCreditCardPayments: 125,
        propertyType: 'Manufactured Home',
        yearBuilt: 1977,
        numberPeopleInHome: 1,
        isPayingHOADues: true,
        monthlyAmount: 175,
        estimatedSquareFeet: 1400,
        isAnyOtherProperties: true,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 1560,
        checkingSavingsAccounts: 5000,
        taxableInvestmentAccount: 350000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 217000,
        firstMortgageMonthlyPayment: 3000,
        yearsLeftOnMortgage: 7,
        areTaxesEscrowed: false,
        annualTaxes: 3500,
        annualInsurance: 1150,
        homeEquityLine: 18000,
        monthlyHelocPayment: 55,
        yearsOnHomeEquityLine: 21
      }
    }
  },
  {
    title: 'Scenario D4',
    value: {
      basic: {
        youngestOwnerAge: 72,
        isBirthDaySoon: false,
        propertyValue: 400000
      },
      creditAndProperty: {
        creditHistory: 'Below Average',
        monthlyInstallmentPayment: 400,
        minMonthlyCreditCardPayments: 175,
        propertyType: 'Manufactured Home',
        yearBuilt: 2005,
        numberPeopleInHome: 1,
        isPayingHOADues: true,
        monthlyAmount: 225,
        estimatedSquareFeet: 1440,
        isAnyOtherProperties: true,
        state: 'Arizona'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 2650,
        checkingSavingsAccounts: 7000,
        taxableInvestmentAccount: 0,
        additionalAssets: 200000
      },
      mortgages: {
        firstMortgageBalance: 167500,
        firstMortgageMonthlyPayment: 1300,
        yearsLeftOnMortgage: 15,
        areTaxesEscrowed: false,
        annualTaxes: 3500,
        annualInsurance: 1150,
        homeEquityLine: 0,
        monthlyHelocPayment: 0,
        yearsOnHomeEquityLine: 0
      }
    }
  },
  {
    title: 'Scenario E',
    value: {
      basic: {
        youngestOwnerAge: 68,
        isBirthDaySoon: false,
        propertyValue: 500000
      },
      creditAndProperty: {
        creditHistory: 'Average',
        monthlyInstallmentPayment: 250,
        minMonthlyCreditCardPayments: 125,
        propertyType: 'Condominium',
        yearBuilt: 1977,
        numberPeopleInHome: 1,
        isPayingHOADues: true,
        monthlyAmount: 175,
        estimatedSquareFeet: 1400,
        isAnyOtherProperties: true,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 1560,
        checkingSavingsAccounts: 5000,
        taxableInvestmentAccount: 350000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 187000,
        firstMortgageMonthlyPayment: 3000,
        yearsLeftOnMortgage: 7,
        areTaxesEscrowed: false,
        annualTaxes: 3500,
        annualInsurance: 1150,
        homeEquityLine: 0,
        monthlyHelocPayment: 55,
        yearsOnHomeEquityLine: 21
      }
    }
  },
  {
    title: 'Scenario E2',
    value: {
      basic: {
        youngestOwnerAge: 73,
        isBirthDaySoon: true,
        propertyValue: 2400000
      },
      creditAndProperty: {
        creditHistory: 'Excellent',
        monthlyInstallmentPayment: 1500,
        minMonthlyCreditCardPayments: 2000,
        propertyType: 'Single Family',
        yearBuilt: 2000,
        numberPeopleInHome: 2,
        isPayingHOADues: false,
        monthlyAmount: 175,
        estimatedSquareFeet: 3506,
        isAnyOtherProperties: true,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 8335,
        checkingSavingsAccounts: 14000,
        taxableInvestmentAccount: 331000,
        additionalAssets: 1306000
      },
      mortgages: {
        firstMortgageBalance: 175000,
        firstMortgageMonthlyPayment: 1500,
        yearsLeftOnMortgage: 12,
        areTaxesEscrowed: false,
        annualTaxes: 8784,
        annualInsurance: 2712,
        homeEquityLine: 300000,
        monthlyHelocPayment: 2200,
        yearsOnHomeEquityLine: 9
      }
    }
  },
  {
    title: 'Scenario E3',
    value: {
      basic: {
        youngestOwnerAge: 68,
        isBirthDaySoon: false,
        propertyValue: 970000
      },
      creditAndProperty: {
        creditHistory: 'Excellent',
        monthlyInstallmentPayment: 250,
        minMonthlyCreditCardPayments: 125,
        propertyType: 'Condominium',
        yearBuilt: 1995,
        numberPeopleInHome: 1,
        isPayingHOADues: true,
        monthlyAmount: 175,
        estimatedSquareFeet: 1400,
        isAnyOtherProperties: true,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 1560,
        checkingSavingsAccounts: 5000,
        taxableInvestmentAccount: 350000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 383000,
        firstMortgageMonthlyPayment: 3000,
        yearsLeftOnMortgage: 7,
        areTaxesEscrowed: false,
        annualTaxes: 3500,
        annualInsurance: 1150,
        homeEquityLine: 0,
        monthlyHelocPayment: 0,
        yearsOnHomeEquityLine: 0
      }
    }
  },
  {
    title: 'Scenario E4',
    value: {
      basic: {
        youngestOwnerAge: 77,
        isBirthDaySoon: false,
        propertyValue: 625000
      },
      creditAndProperty: {
        creditHistory: 'Excellent',
        monthlyInstallmentPayment: 250,
        minMonthlyCreditCardPayments: 125,
        propertyType: 'Condominium',
        yearBuilt: 1995,
        numberPeopleInHome: 1,
        isPayingHOADues: true,
        monthlyAmount: 175,
        estimatedSquareFeet: 1400,
        isAnyOtherProperties: true,
        state: 'Utah'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 1560,
        checkingSavingsAccounts: 5000,
        taxableInvestmentAccount: 350000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 250000,
        firstMortgageMonthlyPayment: 2375,
        yearsLeftOnMortgage: 18,
        areTaxesEscrowed: false,
        annualTaxes: 2500,
        annualInsurance: 1150,
        homeEquityLine: 1,
        monthlyHelocPayment: 1,
        yearsOnHomeEquityLine: 1
      }
    }
  },
  {
    title: 'Cash Flow Issue',
    value: {
      basic: {
        youngestOwnerAge: 62,
        isBirthDaySoon: false,
        propertyValue: 900000
      },
      creditAndProperty: {
        creditHistory: 'Excellent',
        monthlyInstallmentPayment: 100,
        minMonthlyCreditCardPayments: 100,
        propertyType: 'Single Family',
        yearBuilt: 1995,
        numberPeopleInHome: 2,
        isPayingHOADues: false,
        monthlyAmount: 0,
        estimatedSquareFeet: 2000,
        isAnyOtherProperties: false,
        state: 'California'
      },
      incomeAndAssets: {
        householdMonthlyIncome: 5000,
        checkingSavingsAccounts: 1500,
        taxableInvestmentAccount: 450000,
        additionalAssets: 0
      },
      mortgages: {
        firstMortgageBalance: 100000,
        firstMortgageMonthlyPayment: 800,
        yearsLeftOnMortgage: 15,
        areTaxesEscrowed: false,
        annualTaxes: 4000,
        annualInsurance: 1800,
        homeEquityLine: 0,
        monthlyHelocPayment: 1,
        yearsOnHomeEquityLine: 1
      }
    }
  }
];
