import axios from 'axios';

import config from '../config';
import store from './store';

import { showErrorToast } from './helpers/showErrorToast';

function handleErrorResponse(error) {
  store.commit('setError', {
    reason: error.response.data.error,
    status: error.response.status,
    statusText: error.response.statusText
  });

  const errorMessage = error.response?.data?.message || error.message;

  showErrorToast('Request to API failed');

  return Promise.reject(new Error(errorMessage));
}

const guestAxios = axios.create({
  baseURL: config.server_URL
});

export default guestAxios;

guestAxios.interceptors.response.use((response) => {
  return response;
}, handleErrorResponse);

export let userAxios;

export function initUserAxiosInstance({ loanOfficerUID }) {
  userAxios = axios.create({
    baseURL: config.server_URL,
    headers: {
      accept: 'application/json',
      'loan-officer-uid': loanOfficerUID
    }
  });

  userAxios.interceptors.response.use((response) => {
    return response;
  }, handleErrorResponse);
}
