<template>
  <VueApexCharts
    v-if="initialEstimatedRate[0].data.length"
    type="area"
    style="width: 100%"
    height="400"
    :options="initialEstimatedRateChartOptions"
    :series="initialEstimatedRate"
  ></VueApexCharts>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import colors from '../../assets/colors';

export default {
  components: { VueApexCharts },
  data() {
    return {
      initialEstimatedRateUnchangedChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              fontSize: this.$store.state.chartsFonts.small
            },
            formatter: function(val) {
              const date = new Date(val);
              const year = date.getFullYear();
              return year;
            },
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value + '%';
            },
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: [0, 3, 3],
          dashArray: [0, 0, 15]
        },
        fill: {
          type: ['solid', 'solid', 'solid'],
          opacity: [0.5, 0, 0]
        },
        colors: [colors.chartShadedYellow, colors.chartBlue, colors.chartRed]
      }
    };
  },
  computed: {
    initialEstimatedRateChartOptions() {
      return {
        ...this.initialEstimatedRateUnchangedChartOptions,
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
          fontSize: this.$store.state.chartsFonts.medium,
          markers: {
            width: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            height: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            radius: 4
          },
          onItemClick: {
            toggleDataSeries: false
          },
          formatter: function (seriesName) {
            return ['', seriesName];
          }
        }
      };
    },
    initialEstimatedRate() {
      const storeData =
        this.$store.state.scenarioData.currentScenarioData.chartsData.initialEstimatedRate;
      const historicData = [],
        interestRateData = [],
        lifetimeCapData = [];
      for (let object of storeData) {
        interestRateData.push({ x: object.date, y: object.interestAndFHAInsurance });
        historicData.push({ x: object.date, y: object.graphMarker });
        lifetimeCapData.push({ x: object.date, y: object.max });
      }
      return [
        { name: 'Historic Data', type: 'area', data: historicData },
        { name: 'Interest Rate', type: 'area', data: interestRateData },
        { name: 'Lifetime Cap', type: 'area', data: lifetimeCapData }
      ];
    }
  }
};
</script>
