<template>
  <v-card class="d-flex flex-column align-center mb-8 mx-auto pt-9 pb-7 px-9" elevation="3">
    <v-sheet width="100%" max-width="800">
      <div class="clauseText--text medium-small-text-font mb-6 mb-sm-8">
        Estimated cash flow savings over your actuarial expected life of
        {{ otherValues.lifeExpectancy }} years:
      </div>
      <ValueBlock
        label="Your current mortgage (principal & interest) payment"
        :value="otherValues.currentMortgagePayment"
        :customText="false"
        :customBackground="false"
        :accent="false" />
      <ValueBlock
        label="Your current HELOC payment"
        :value="otherValues.currentHelocPayments"
        :customText="false"
        :customBackground="false"
        :accent="false" />
      <ValueBlock
        label="Future estimated property tax and insurance 
payments"
        :value="otherValues.futureTaxAndInsurancePayments"
        :customText="false"
        :customBackground="false"
        :accent="false" />
      <ValueBlock
        label="Your Estimated Monthly Savings with a 
Reverse Mortgage"
        :value="otherValues.estimatedMonthlySavings"
        :customText="false"
        :customBackground="false"
        :accent="false" />
      <v-divider class="my-8"></v-divider>
      <div class="extra-small-text-font text-center text-sm-start mt-2 mt-sm-4 mb-10">
        Multiply by {{ otherValues.lifeExpectancy }} years (your actuarial expected life), adjust
        for future principal HELOC payments (if any) and estimated upfront costs, and include the
        estimated {{ formatMoney(otherValues.estimatedValueWhenReverseMortgageCreated) }} you will
        receive when the reverse mortgage is created, results in
      </div>
      <ValueBlock
        label="TOTAL CASH FLOW IMPACT OF APPROXIMATELY:"
        :value="otherValues.totalCashFlowImpactValue"
        :customText="true"
        :customBackground="true"
        :accent="false"
    /></v-sheet>
  </v-card>
</template>

<script>
import ValueBlock from '../ValueBlock.vue';
import helpers from '../../helpers';

export default {
  components: { ValueBlock },
  computed: {
    otherValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney
  }
};
</script>

<style scoped></style>
