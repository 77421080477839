<template>
  <v-card
    class="mx-auto mb-8 py-6 px-2 px-sm-10 d-flex flex-column justify-space-between align-center"
    elevation="3"
  >
    <v-sheet width="80%" min-width="300" max-width="1000"
      ><CustomScenarioChart :period="otherValues.lifeExpectancy" category="age"
    /></v-sheet>

    <v-sheet v-if="!shouldWeReviseNumbers" width="80%" min-width="300" max-width="1000">
      <div class="small-text-font mt-14">
        Your equity right now is approximately
        <span class="font-weight-bold">{{
          formatMoney(tableValues.finalValuesByNow.homeEquity)
        }}</span
        >. If your property value increases by approximately
        <span class="font-weight-bold">{{
          formatPercentage(selectedValues.propertyValueApreciationRate)
        }}</span>
        each year for the next
        <span class="font-weight-bold">{{ otherValues.lifeExpectancy }}</span> years, your equity in
        <span class="font-weight-bold">{{ otherValues.lifeExpectancy }}</span> years will be about
        the same as it is now:
        <span class="font-weight-bold">{{
          formatMoney(tableValues.finalValuesByYears.homeEquity)
        }}</span>
        .......without making any mortgage payments.
      </div>
    </v-sheet>

    <!-- <CaseSchiller /> -->
    <CustomPropertValueAppreciationRatesTable />
    <v-sheet class="my-10" width="80%" min-width="300" max-width="1000"
      ><NowVsFutureTableCDE
        finalRowLabel="Equity Change + Cash Savings"
        :preFinalRowLabel="`Estimated Total Cash Flow Impact, including immediate cash of ${formatMoney(
          tableValues.estimatedCashFlowImpact
        )}`"
        :preFinalRowValue="tableValues.estimatedTotalCashFlowImpact"
    /></v-sheet>

    <v-sheet width="80%" min-width="300" max-width="1000">
      <div class="small-text-font mt-8">
        Is there a particular amount of equity you would like to have in
        <span class="font-weight-bold">{{ currentEffectiveYear }}</span>
        years?
      </div>
    </v-sheet>

    <v-sheet class="mt-4" width="80%" min-width="300" max-width="1000">
      <ChooseEquityTable />
    </v-sheet>
    <v-sheet class="mt-4" width="80%" min-width="300" max-width="1000">
      <CashFlowForCDEscenarios />
    </v-sheet>

    <!-- <AuditButton /> -->
    <!-- <QuantumReverseButton /> -->
    <!-- Set Cash Disbursement to 40 000$?
    <v-select
      class="py-4"
      hide-details
      :items="cashDisbursementOptions"
      label="Please select"
      dense
      solo
      @change="(value) => handleChange(value)"
      :disabled="isUpdating"
      :readonly="isResultSaved"
    />
    <CalculateQuantumReverseButton /> -->
  </v-card>
</template>

<script>
// import CaseSchiller from './CaseSchiller.vue';
import ChooseEquityTable from '../../Tables/ChooseEquityTable.vue';
import NowVsFutureTableCDE from '../../Tables/NowVsFutureTableCDE.vue';
import CustomScenarioChart from '../../Charts/CustomScenarioChart.vue';
// import AuditButton from '../../AuditButton.vue';
//import QuantumReverseButton from '../../QuantumReverseButton.vue';
// import CalculateQuantumReverseButton from '../../CalculateQuantumReverseButton.vue';
import CashFlowForCDEscenarios from '../../Charts/CashFlowForCDEscenarios.vue';
import CustomPropertValueAppreciationRatesTable from '../../Tables/CustomPropertValueAppreciationRatesTable.vue';
import helpers from '../../../helpers';

export default {
  components: {
    // CaseSchiller,
    ChooseEquityTable,
    NowVsFutureTableCDE,
    CustomScenarioChart,
    // AuditButton,
    //QuantumReverseButton,
    CashFlowForCDEscenarios,

    CustomPropertValueAppreciationRatesTable
  },
  data: () => ({
    cashDisbursementOptions: [
      { value: true, text: 'Yes' },
      { value: false, text: 'No' }
    ]
  }),
  computed: {
    otherValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues;
    },
    selectedValues() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected;
    },
    tableValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.tableValues;
    },
    currentEffectiveYear() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
        .effectiveAge;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    },
    shouldWeReviseNumbers() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
        .shouldWeReviseNumbers;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    formatPercentage: helpers.formatPercentage,
    handleChange(value) {
      this.$store.commit('setCashDisbursementState', value);
    }
  }
};
</script>

<style scoped></style>
