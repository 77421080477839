<template>
  <v-sheet
    class="d-flex flex-wrap justify-space-between align-center"
    v-bind:class="{ generalBackground: isBackgroundCustom }"
    width="100%"
    max-width="460"
  >
    <div class="small-text-font mr-2">{{ label }}</div>
    <v-sheet width="200">
      <v-select
        v-bind:class="{ generalBackground: isBackgroundCustom }"
        hide-details
        :items="allAppreciationRates"
        item-value="value"
        item-text="text"
        dense
        outlined
        :value="currentAppreciationRate"
        @change="(value) => handleScenarioOptionsChange(value, 'propertyValueApreciationRate')"
        :disabled="isUpdating"
        :readonly="isResultSaved"
      />
    </v-sheet>
  </v-sheet>
</template>

<script>
import helpers from '../../helpers';

export default {
  props: ['label', 'customBackground', 'isAudit'],
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    isBackgroundCustom() {
      return this.$props.customBackground || false;
    },
    allAppreciationRates() {
      const allRates =
        this.$store.state.scenarioData.currentScenarioData.selectsOptions.allAppreciationRates;
      const formattedRates = allRates.map((x) => ({
        value: x,
        text: this.formatPercentage(x)
      }));
      return [
        ...formattedRates,
        { value: 'Custom', text: 'Custom' },
        { value: 'Soft Landing', text: 'Soft Landing' }
      ];
    },
    currentAppreciationRate() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
        .propertyValueApreciationRate;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    }
  },
  methods: {
    formatPercentage: helpers.formatPercentage,
    handleScenarioOptionsChange(value, fieldName) {
      const data = { fieldName, value };
      this.$store.commit('changeOptionValues', data);
      this.$store.dispatch('getNewScenarioData', this.$props.isAudit);
    }
  }
};
</script>
