<template>
  <GChart
    id="map-chart"
    :type="type"
    :data="data"
    :options="options"
    :settings="settings"
    :resizeDebounce="1000"
  />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy';

const options = {
  type: 'GeoChart',
  options: {
    legend: 'none',
    region: 'US',
    resolution: 'provinces',
    backgroundColor: {
      fill: '#f5f5f5'
    },
    datalessRegionColor: '#e4e4e4',
    colorAxis: { colors: ['#837ab0', '#837ab0'] },
    enableRegionInteractivity: false
  },
  settings: {
    packages: ['geochart']
  }
};

export default {
  components: {
    GChart
  },
  props: ['states'],
  data() {
    return {
      ...options,
      data: [['State', 'Select'], ...this.$props.states.map((i) => [i, 0])]
    };
  }
};
</script>
