var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"d-flex flex-column flex-sm-row justify-space-between align-center mb-2 mb-sm-4",attrs:{"width":"100%"}},[_c('div',{staticClass:"small-text-font mr-0 mr-sm-4 mb-1 mb-sm-0 text-center text-sm-left",class:{
      'totalCashFlowImpactText--text font-weight-bold': _vm.isTextCustom,
      'blue--text darken-5': _vm.isAccented
    }},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('v-sheet',{staticClass:"value-container d-flex flex-row justify-center align-center",class:{
      totalCashFlowImpactBackground: _vm.isBackgroundCustom,
      'blue--text darken-5': _vm.isAccented
    },attrs:{"max-width":"250","min-width":"250","height":"40"}},[_c('div',{staticClass:"medium-small-text-font font-weight-regular",class:{ 'font-weight-bold': _vm.isTextCustom }},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.value))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }