import Vue from 'vue';
import Vuex from 'vuex';

import scenarioDataModule from './scenarioDataModule';
import amortCompAndTALCModule from './amortCompAndTALCModule';
import emailToLOModule from './emailToLOModule';
import chartsFontsModule from './chartsFontsModule';
import leadCaptureModule from './leadCaptureModule';
import emailConfirmModule from './emailConfirmModule';
import loanOfficerModule from './modules/loanOfficer';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loanOfficer: loanOfficerModule,
    scenarioData: scenarioDataModule,
    amortCompAndTALC: amortCompAndTALCModule,
    emailToLO: emailToLOModule,
    chartsFonts: chartsFontsModule,
    leadCapture: leadCaptureModule,
    emailConfirm: emailConfirmModule
  },
  state: {
    isLoading: false,
    isUpdating: false,
    error: {}
  },

  mutations: {
    setLoading(state) {
      state.isLoading = !state.isLoading;
    },
    setUpdating(state) {
      state.isUpdating = !state.isUpdating;
    },
    setError(state, data) {
      state.error = data;
    }
  },

  actions: {}
});
