<template>
  <div class="my-8">
    <ImportantData />
    <HomeEquityAndRMPaymentsAndFunds />

    <v-card
      class="mx-auto mb-8 py-6 px-2 px-sm-10 d-flex flex-column justify-space-between align-center"
      elevation="3"
    >
      <v-sheet class="d-flex flex-wrap justify-space-between align-start" width="100%">
        <v-sheet
          style="flex: 1"
          class="d-flex flex-column justify-space-between align-start mb-8 mb-lg-0"
          min-width="280"
        >
          <div class="clauseText--text text-h6 mb-4">Initial Estimated Rate</div>
          <InterestRateScenarioSelector label="Interest rate scenario" :customBackground="false" />
        </v-sheet>
        <v-sheet style="flex: 1" min-width="280" width="100%">
          <InterestRateScenarioChart />
        </v-sheet>
      </v-sheet>
      <CustomInterestRatesTable />
      <v-sheet width="80%" min-width="300" max-width="1000"> <CashFlowForCDEscenarios /></v-sheet>

      <!-- <AuditButton v-if="!isResultSaved" /> -->
    </v-card>

    <!-- <PreFundRMSense /> -->
  </div>
</template>

<script>
// import PreFundRMSense from './PreFundRMSense.vue';
import HomeEquityAndRMPaymentsAndFunds from './HomeEquityAndRMPaymentsAndFunds.vue';
import ImportantData from './ImportantData/index.vue';
import InterestRateScenarioChart from '../Charts/InterestRateScenarioChart.vue';
import InterestRateScenarioSelector from '../Selectors/InterestRateScenarioSelector.vue';
import CustomInterestRatesTable from '../Tables/CustomInterestRatesTable.vue';
// import AuditButton from '../AuditButton.vue';
import CashFlowForCDEscenarios from '../Charts/CashFlowForCDEscenarios.vue';

export default {
  components: {
    // PreFundRMSense,
    HomeEquityAndRMPaymentsAndFunds,
    ImportantData,
    CustomInterestRatesTable,
    InterestRateScenarioSelector,
    InterestRateScenarioChart,
    // AuditButton,
    CashFlowForCDEscenarios
  }
};
</script>

<style scoped></style>
