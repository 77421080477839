import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        headerButtonText: '#5B5B5B',
        headerTabs: '#635EDD',
        clauseText: '#3E8DF3',
        generalBackground: '#FAFBFE',
        questionIcon: '#DE8344',
        greyText: '#626262',
        totalCashFlowImpactBackground: '#e2e2e2',
        totalCashFlowImpactText: '#27923E'
      }
    }
  }
});
