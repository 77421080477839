<template>
  <div class="my-4 full-width">
    <v-expansion-panels :value="isResultSaved ? 0 : null">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-sheet class="d-flex flex-column" width="100%">
            <div class="clauseText--text medium-small-text-font my-2">
              3. Indicate if you want immediate cash
            </div>
          </v-sheet>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
          <v-sheet class="d-flex align-center justify-space-between flex-wrap">
            <v-sheet max-width="460" width="100%">
              <ImmediateDrawTextField
                label="Immediate Draw"
                :showMax="true"
                :customBackground="false"
                :maxImmediateDraw="maxImmediateCash"
            /></v-sheet>
            <v-sheet min-width="280" width="50%"
              ><VueApexCharts
                v-if="allocationOfRMFunds.length"
                type="pie"
                height="400"
                width="100%"
                :options="allocationOfRMFundsChartOptions"
                :series="allocationOfRMFunds"
              ></VueApexCharts
            ></v-sheet>
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueApexCharts from 'vue-apexcharts';
import ImmediateDrawTextField from '../../Selectors/ImmediateDrawTextField.vue';
import colors from '../../../assets/colors';

export default {
  components: {
    ImmediateDrawTextField,
    VueApexCharts
  },
  data() {
    return {
      allocationOfRMFundsUnchangedChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        labels: [
          'Immediate Draw',
          'Monthly Payment to Borrower',
          'Line of Credit',
          'Property Tax and Insurance Reserve'
        ],
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'solid',
          opacity: 1
        },
        colors: [
          colors.chartDarkGreen,
          colors.chartLightGreen,
          colors.chartYellow,
          colors.chartOrange
        ]
      }
    };
  },
  computed: {
    ...mapGetters(['isResultSaved']),
    allocationOfRMFundsChartOptions() {
      return {
        ...this.allocationOfRMFundsUnchangedChartOptions,
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'bottom',
          horizontalAlign: 'left',
          width: 400,
          offsetX: this.$vuetify.breakpoint.width < 1264 ? 0 : 40,
          fontSize: this.$store.state.chartsFonts.medium,
          markers: {
            width: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            height: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            radius: 4,
            offsetY: this.$vuetify.breakpoint.width < 500 ? 0 : 6
          },
          onItemClick: {
            toggleDataSeries: false
          }
        }
      };
    },
    allocationOfRMFunds() {
      const storeData =
        this.$store.state.scenarioData.currentScenarioData.chartsData
          .allocationOfReverseMortgageFunds.custom;
      const series = [
        storeData.immediateDraw,
        storeData.monthlyPayments,
        storeData.lineOfCredit,
        storeData.taxAndInsuranceReserve
      ];
      return series;
    },
    maxImmediateCash() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues
        .maxImmediateCash;
    }
  }
};
</script>

<style scoped></style>
