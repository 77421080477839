<template>
  <VueApexCharts
    :class="customBackground ? 'generalBackground' : ''"
    v-if="caseSchillerPropertyValueIndexData[0].data.length"
    type="area"
    style="width: 100%"
    height="400"
    :options="caseSchillerPropertyValueIndexChartOptions"
    :series="caseSchillerPropertyValueIndexData"
  ></VueApexCharts>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import colors from '../../assets/colors';

export default {
  components: { VueApexCharts },
  props: ['customBackground'],
  data() {
    return {};
  },
  computed: {
    caseSchillerPropertyValueIndexUnchangedChartOptions() {
      return {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              fontSize: this.$store.state.chartsFonts.small
            },
            formatter: function (val, timestamp) {
              return new Date(timestamp).getFullYear();
            }
          },
          tickPlacement: 'on'
        },
        yaxis: {
          type: 'numeric',
          labels: {
            formatter: function () {
              return '';
            },
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        fill: {
          type: 'solid',
          opacity: 0.8
        },
        colors: [colors.chartBlue]
      };
    },
    caseSchillerPropertyValueIndexChartOptions() {
      return {
        ...this.caseSchillerPropertyValueIndexUnchangedChartOptions,
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'bottom',
          horizontalAlign: this.$vuetify.breakpoint.width < 500 ? 'left' : 'center',
          fontSize: this.$store.state.chartsFonts.medium,
          markers: {
            width: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            height: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            radius: 4,
            offsetY: 0
          },
          onItemClick: {
            toggleDataSeries: false
          },
          formatter: function (seriesName) {
            return ['', seriesName];
          }
        }
      };
    },
    caseSchillerPropertyValueIndexData() {
      const storeData =
        this.$store.state.scenarioData.currentScenarioData.chartsData.caseSchillerPropertyValueIndex
          .allData;
      const data = [];
      for (let object of storeData) {
        data.push({ x: object.earlyAppreciation.date, y: object.earlyAppreciation.presentValue });
      }
      return [{ name: 'Case Schiller Property Value Index - 20 Major Cities', data }];
    }
  }
};
</script>
