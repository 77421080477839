<template>
  <VueApexCharts
    v-if="customScenario[0].data.length"
    type="area"
    width="100%"
    height="400"
    :options="customScenarioChartOptions"
    :series="customScenario"
  ></VueApexCharts>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import helpers from '../../helpers';
import colors from '../../assets/colors';

export default {
  components: { VueApexCharts },
  props: ['period', 'category'],
  data() {
    return {
      customScenarioUnchangedChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          type: 'numeric',
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            },
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return this.formatMoney(value);
            },
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        }
      }
    };
  },
  computed: {
    customScenarioChartOptions() {
      return {
        ...this.customScenarioUnchangedChartOptions,
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'bottom',
          horizontalAlign: 'left',
          fontSize: this.$store.state.chartsFonts.medium,
          width: 300,
          markers: {
            width: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            height: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            radius: 4
          },
          onItemClick: {
            toggleDataSeries: false
          },
          formatter: function (seriesName) {
            return ['', seriesName];
          }
        },
        stroke: {
          width:
            this.currentScenarioTitle === this.allScenariosTitles.scenarioATitle
              ? [0, 4, 0, 4]
              : [8, 0, 0, 4],
          curve: 'smooth'
        },
        fill: {
          type: ['solid', 'solid', 'solid', 'solid'],
          opacity:
            this.currentScenarioTitle === this.allScenariosTitles.scenarioATitle
              ? [1, 0, 1, 0]
              : [0, 1, 1, 0]
        },
        colors:
          this.currentScenarioTitle === this.allScenariosTitles.scenarioATitle
            ? [colors.chartBlue, colors.chartDarkRed, colors.chartGreen, colors.chartYellow]
            : [colors.chartDarkRed, colors.chartBlue, colors.chartGreen, colors.chartYellow]
      };
    },
    customScenario() {
      const storeData =
        this.$store.state.scenarioData.currentScenarioData.chartsData.customScenario;
      const propertyValue = [],
        reverseMortgageOrLoanBalance = [],
        lineOfCredit = [];
      let changingSeriesName, changingFieldName;
      if (this.currentScenarioTitle === this.allScenariosTitles.scenarioATitle) {
        changingSeriesName = 'Reverse Mortgage';
        changingFieldName = 'reverseMortgage';
      } else {
        changingSeriesName = 'Loan Balance';
        changingFieldName = 'loanBalance';
      }
      for (let i = 0; i <= this.$props.period; i++) {
        propertyValue.push({
          x: storeData[i][this.$props.category],
          y: storeData[i].propertyValue
        });
        reverseMortgageOrLoanBalance.push({
          x: storeData[i][this.$props.category],
          y: storeData[i][changingFieldName]
        });
        lineOfCredit.push({ x: storeData[i][this.$props.category], y: storeData[i].lineOfCredit });
      }

      if (this.currentScenarioTitle === this.allScenariosTitles.scenarioATitle) {
        return [
          { name: 'Home Equity', type: 'area', data: propertyValue },
          { name: 'Property Value', type: 'area', data: propertyValue },
          { name: changingSeriesName, type: 'area', data: reverseMortgageOrLoanBalance },
          { name: 'Line of Credit', type: 'area', data: lineOfCredit }
        ];
      } else if (this.currentScenarioTitle === this.allScenariosTitles.scenarioBTitle) {
        return [
          { name: 'Property Value', type: 'area', data: propertyValue },
          { name: 'Home Equity', type: 'area', data: propertyValue },
          { name: changingSeriesName, type: 'area', data: reverseMortgageOrLoanBalance },
          { name: 'Line of Credit', type: 'area', data: lineOfCredit }
        ];
      } else {
        return [
          { name: 'Property Value', type: 'area', data: propertyValue },
          { name: 'Home Equity', type: 'area', data: propertyValue },
          { name: changingSeriesName, type: 'area', data: reverseMortgageOrLoanBalance }
        ];
      }
    },
    currentScenarioTitle() {
      return this.$store.state.scenarioData.scenario;
    },
    allScenariosTitles() {
      return this.$store.state.scenarioData.allScenarioTitles;
    }
  },
  methods: { formatMoney: helpers.formatMoney }
};
</script>
