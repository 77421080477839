<template>
  <div>
    <v-card class="mx-auto mb-8 pa-6 page-break" elevation="3">
      <v-sheet width="80%" min-width="300" max-width="1000"
        ><CustomScenarioChart :period="otherValues.lifeExpectancy" category="age"
      /></v-sheet>
    </v-card>

    <v-card class="mx-auto mb-8 pa-6 page-break" elevation="3">
      <v-sheet width="80%" min-width="300" max-width="1000">
        <div class="small-text-font mt-6">
          Your equity right now is approximately
          <span class="font-weight-bold">{{
            formatMoney(tableValues.finalValuesByNow.homeEquity)
          }}</span
          >. If your property value increases by approximately
          <span class="font-weight-bold">{{
            formatPercentage(selectedValues.propertyValueApreciationRate)
          }}</span>
          each year for the next
          <span class="font-weight-bold">{{ otherValues.lifeExpectancy }}</span> years, your equity
          in <span class="font-weight-bold">{{ otherValues.lifeExpectancy }}</span> years will be
          about the same as it is now:
          <span class="font-weight-bold">{{
            formatMoney(tableValues.finalValuesByYears.homeEquity)
          }}</span>
          .......without making any mortgage payments.
        </div>

        <CustomPropertValueAppreciationRatesTable />
        <v-sheet class="my-10" width="80%" min-width="300" max-width="1000"
          ><NowVsFutureTableCDE
            finalRowLabel="Equity Change + Cash Savings"
            :preFinalRowLabel="`Estimated Total Cash Flow Impact, including immediate cash of ${formatMoney(
              tableValues.estimatedCashFlowImpact
            )}`"
            :preFinalRowValue="tableValues.estimatedTotalCashFlowImpact"
        /></v-sheet>
      </v-sheet>
    </v-card>

    <v-card class="mx-auto mb-8 pa-6 page-break" elevation="3">
      <v-sheet width="80%" min-width="300" max-width="1000">
        <div class="small-text-font">
          Is there a particular amount of equity you would like to have in
          <span class="font-weight-bold">{{ currentEffectiveYear }}</span>
          years?
        </div>
      </v-sheet>

      <v-sheet class="mt-4" width="80%" min-width="300" max-width="1000">
        <ChooseEquityTable />
      </v-sheet>
    </v-card>

    <v-card class="mx-auto mb-8 pa-6 page-break" elevation="3">
      <v-sheet class="mt-4" width="80%" min-width="300" max-width="1000">
        <CashFlowForCDEscenarios />
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import ChooseEquityTable from '@/components/Tables/ChooseEquityTable.vue';
import NowVsFutureTableCDE from '@/components/Tables/NowVsFutureTableCDE.vue';
import CustomScenarioChart from '@/components/Charts/CustomScenarioChart.vue';
import CashFlowForCDEscenarios from '@/components/Charts/CashFlowForCDEscenarios.vue';
import CustomPropertValueAppreciationRatesTable from '@/components/Tables/CustomPropertValueAppreciationRatesTable.vue';
import helpers from '@/helpers';

export default {
  components: {
    ChooseEquityTable,
    NowVsFutureTableCDE,
    CustomScenarioChart,
    CashFlowForCDEscenarios,
    CustomPropertValueAppreciationRatesTable
  },
  data: () => ({
    cashDisbursementOptions: [
      { value: true, text: 'Yes' },
      { value: false, text: 'No' }
    ]
  }),
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    otherValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues;
    },
    selectedValues() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected;
    },
    tableValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.tableValues;
    },
    currentEffectiveYear() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
        .effectiveAge;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    formatPercentage: helpers.formatPercentage,
    handleChange(value) {
      this.$store.commit('setCashDisbursementState', value);
    }
  }
};
</script>

<style scoped>
.page-break {
  break-inside: avoid;
  page-break-inside: avoid;
}

@media print {
  .page-break {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
</style>
