<template>
  <v-card
    class="mx-auto mb-2 py-2 d-flex flex-column justify-space-between align-center page-break"
    elevation="3"
  >
    <v-sheet
      class="d-flex align-center flex-column my-4 mx-2"
      width="80%"
      max-width="800"
      min-width="300"
    >
      <div class="medium-text-font ml-2 mb-8 align-self-start text-capitalize">
        Initial setup example
      </div>
      <VueApexCharts
        style="width: 100%"
        height="400"
        v-if="initialSetUp.length"
        type="pie"
        :options="initialSetUpChartOptions"
        :series="initialSetUp"
      ></VueApexCharts>
    </v-sheet>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import helpers from '../../helpers';
import colors from '../../assets/colors';

export default {
  components: { VueApexCharts },
  data() {
    return {
      initialSetUpUnchangedChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        labels: [
          'Immediate Draw',
          'Monthly Tenure Payment to Borrower',
          'Line of Credit',
          'Property Tax and Insurance Reserve'
        ],
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'solid',
          opacity: 1
        },
        colors: [
          colors.chartDarkGreen,
          colors.chartLightGreen,
          colors.chartYellow,
          colors.chartOrange
        ]
      }
    };
  },
  computed: {
    initialSetUpChartOptions() {
      return {
        ...this.$data.initialSetUpUnchangedChartOptions,
        legend: {
          show: true,
          showForSingleSeries: true,
          position: this.$vuetify.breakpoint.width < 960 ? 'bottom' : 'left',
          horizontalAlign: this.$vuetify.breakpoint.width < 960 ? 'left' : 'center',
          height: this.$vuetify.breakpoint.width < 400 ? 175 : undefined,
          width: this.legendContainerWidth,
          fontSize: this.$store.state.chartsFonts.medium,
          markers: {
            width: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            height: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            radius: 4,
            offsetY: this.$vuetify.breakpoint.width < 500 ? 0 : 6
          },
          onItemClick: {
            toggleDataSeries: false
          },
          formatter: (seriesName, opts) => {
            const value =
              seriesName === 'Monthly Payment to Borrower'
                ? this.monthlyPaymentToBorrower
                : opts.w.globals.series[opts.seriesIndex];
            return ['', seriesName, '-', this.formatMoney(value)];
          }
        }
      };
    },
    legendContainerWidth() {
      if (this.$vuetify.breakpoint.width < 400) {
        return 250;
      } else if (this.$vuetify.breakpoint.width < 960) {
        return 510;
      } else {
        return undefined;
      }
    },
    initialSetUp() {
      const storeData =
        this.$store.state.scenarioData.currentScenarioData.chartsData
          .allocationOfReverseMortgageFunds.staticExamples;
      const series = [
        storeData.immediateDraw,
        storeData.monthlyPayments,
        storeData.lineOfCredit,
        storeData.taxAndInsuranceReserve
      ];
      return series;
    },
    monthlyPaymentToBorrower() {
      return this.$store.state.scenarioData.currentScenarioData.chartsData.initialSetUp
        .monthlyPaymentToBorrower;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney
  }
};
</script>

<style scoped>
.page-break {
  break-inside: avoid;
  page-break-inside: avoid;
}

@media print {
  .page-break {
    break-before: always;
    break-inside: avoid;
  }
}
</style>
