<template>
  <div class="fill-height">
    <div v-if="!isLoading" class="d-flex align-center fill-height">
      <div class="center" v-if="isEmailConfirmed">
        <h1 class="text-capitalize text-center">Your email was successfully confirmed</h1>
        <p class="text-subtitle-1 text-center">Loan Officer will contact with you soon</p>
      </div>

      <div class="center" v-if="!isEmailConfirmed">
        <h1 class="text-capitalize text-center">Your confirmation link is invalid</h1>
        <p class="text-subtitle-1 text-center">Contact with your Loan Officer</p>
      </div>
    </div>

    <v-progress-linear
      :active="isLoading"
      :indeterminate="isLoading"
      fixed
      bottom
      height="7"
      color="headerTabs"
    ></v-progress-linear>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  computed: {
    resultUID() {
      return this.$route.params.resultUID;
    },
    confirmHash() {
      return this.$route.params.confirmHash;
    },
    email() {
      return this.$route.query.email;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    isEmailConfirmed() {
      return this.$store.state.emailConfirm.isEmailConfirmed;
    }
  },
  methods: {
    ...mapActions({ checkEmailConfirm: 'checkConfirm' })
  },
  created() {
    this.checkEmailConfirm({
      resultUID: this.resultUID,
      confirmHash: this.confirmHash,
      email: this.email
    });
  }
};
</script>

<style scoped>
.center {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}
</style>
