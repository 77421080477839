<template>
  <v-card class="header elevation-4">
    <div class="d-flex justify-space-between flex-wrap align-center flex1">
      <div class="d-flex align-center mx-4">
        <router-link
          v-if="loanOfficer.corporateImage"
          to="/"
          style="text-decoration: none; color: inherit"
        >
          <v-img
            v-if="loanOfficer.corporateImage"
            width="auto"
            max-height="40px"
            max-width="220px"
            contain
            :src="loanOfficer.corporateImage"
            alt="Logo"
          />
        </router-link>
      </div>

      <div class="mx-4">
        <div class="mx-auto">
          <v-btn
            v-if="loanOfficer.phone"
            elevation="0"
            class="white mx-2"
            :href="`tel:${loanOfficer.phone}`"
          >
            <v-icon color="primary" left> mdi-phone </v-icon> {{ loanOfficer.phone }}
          </v-btn>
          <v-btn
            v-if="loanOfficer.email"
            elevation="0"
            class="white mx-2 text-none"
            :href="`mailto:${loanOfficer.email}`"
          >
            <v-icon color="primary" left> mdi-email </v-icon> {{ loanOfficer.email }}
          </v-btn>
          <v-btn elevation="0" class="white mx-2 text-none" to="/about">
            <v-icon color="primary" left> mdi-information </v-icon> About
          </v-btn>
        </div>
      </div>
    </div>

    <div v-if="referral" class="referral-container">
      <v-chip color="green" label text-color="white">
        <v-icon left> mdi-account-key-outline </v-icon>
        {{ referral.name }} / {{ referral.email }}
      </v-chip>
    </div>
  </v-card>
</template>

<script>
export default {
  computed: {
    loanOfficer() {
      const loanOfficer = this.$store.state.loanOfficer.info;
      const { phone, email, corporateImage } = loanOfficer;

      return {
        phone,
        email,
        corporateImage
      };
    },
    referral() {
      const referral = this.$store.state.loanOfficer.referral;
      if (!referral) return null;
      const { email, name } = referral;

      return {
        email,
        name
      };
    }
  }
};
</script>

<style scoped>
.header {
  position: relative;
  padding: 8px 16px !important;
}
.referral-container {
  position: absolute;
  right: 10px;
  bottom: -37px;
}
</style>
