export default {
  state: {
    form: null
  },
  getters: {
    isOldestUsed(state) {
      let isEmpty = true;
      Object.values(state.form.oldestBorrower).forEach((val) => {
        if (val) isEmpty = false;
      });
      return !isEmpty;
    },
    leadCaptureData(state, getters) {
      if (!state.form) {
        return {
          mainObjective: '',
          borrowers: []
        };
      }
      const isOldestFormUsed = getters.isOldestUsed;
      return {
        mainObjective: state.form.mainObjective,
        borrowers: isOldestFormUsed
          ? [state.form.youngestBorrower, state.form.oldestBorrower]
          : [state.form.youngestBorrower]
      };
    }
  },
  mutations: {
    SET_FORM(state, data) {
      state.form = data;
    }
  },
  actions: {
    setLeadCaptureForm({ commit }, value) {
      commit('SET_FORM', value);
    }
  }
};
