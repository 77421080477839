<template>
  <div>
    <v-row>
      <v-col sm="12" md="8" lg="7" class="pa-8">
        <div v-if="landingPageContent.main">
          <div v-html="landingPageContent.main" class="tiptap-vuetify-editor__content" />
        </div>
        <div v-else>
          <h2 class="text-h3 my-6 font-weight-medium">Try my new reverse mortgage calculator</h2>
          <p class="text-h4 my-6 font-weight-medium">
            It answers all your "what if", "how much" and other important questions:
          </p>
          <ul class="text-body-2 my-6">
            <li>What if rates go up?</li>
            <li>What if I make voluntary payments?</li>
            <li>What if property values go down?</li>
            <li>How much equity will I have in 15 years?</li>
            <li>How much can I get in monthly payments in 5 years?</li>
            <li>Will the growth in my line of credit keep up with inflation?</li>
          </ul>
          <p></p>
        </div>
      </v-col>
      <v-col sm="12" md="4" lg="5" class="d-flex align-center justify-center pa-8">
        <v-img
          v-if="!loanOfficer.profileImage"
          class="image-container"
          src="../../assets/businessman.png"
        />
        <v-img v-else class="image-container" :src="loanOfficer.profileImage" />
      </v-col>
    </v-row>

    <v-row class="py-8">
      <v-col
        cols="12"
        class="d-flex flex-column flex1 justify-center align-center align-self-center"
      >
        <v-card
          elevation="4"
          outlined
          shaped
          class="pa-4 d-flex flex-row align-center justify-center flex-wrap"
        >
          <div class="text-h5 font-weight-medium mx-4 my-2">Get your answers here</div>
          <!-- TODO loanOfficerUID must be changed before deploy -->
          <v-btn
            x-large
            color="success"
            class="text-none text-h6 font-weight-medium mx-4 my-2"
            style="width: 300px"
            :to="{ name: 'Form' }"
          >
            Get Started
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pa-4 d-flex justify-center">
      <!-- <div v-if="landingPageContent.bottom" style="flex: 1">
        <div v-html="landingPageContent.bottom" class="tiptap-vuetify-editor__content" />
      </div> -->

      <v-col sm="12" md="4" lg="5" class="d-flex align-center justify-center">
        <MapStates :states="states" />
      </v-col>
      <v-col
        sm="12"
        md="8"
        lg="7"
        class="d-flex flex-column justify-center"
        style="max-width: 600px; z-index: 1"
      >
        <div class="my-6">
          <p class="mb-0">Licensed to conduct business only in {{ statesString }}.</p>
          <p class="mb-0">
            {{ loanOfficer.company.companyName }} {{ loanOfficer.company.address }},
            {{ loanOfficer.company.city }}, {{ loanOfficer.company.state }},
            {{ loanOfficer.company.zip }}.
          </p>
          <p class="mb-0">NMLS #{{ loanOfficer.company.nmls }}</p>
        </div>
        <p class="my-4 text-justify">
          These materials have not been approved or endorsed by FHA or any other government agency.
          {{ loanOfficer.company.companyName }} is not affiliated with your current lender. Equal
          Housing Opportunity. Rates, Program, Fees, and Guidelines are subject to change without
          notice. Restrictions apply. Not all will qualify. Not a commitment to lend.
        </p>

        <div class="d-flex justify-center logos my-4">
          <v-img v-if="loanOfficer.logos.CRMP" max-width="120px" src="../../assets/CRMP-logo.jpg" />
          <v-img
            v-if="loanOfficer.logos.NRMLA"
            max-width="120px"
            src="../../assets/NMLS-logo.png"
          />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="6" class="d-flex flex-row justify-end align-center">
        <div class="d-flex flex-column justify-center align-center body-2 mx-4 text-uppercase">
          <p class="mb-0">
            {{ loanOfficer.firstName }} {{ loanOfficer.lastName }}, NMLS {{ loanOfficer.nmls }}
          </p>
          <p class="mb-0">{{ loanOfficer.title }}</p>
          <p class="mb-0">{{ loanOfficer.company.companyName }}</p>
        </div>
      </v-col>
      <v-col sm="6" class="d-flex align-center">
        <v-img :src="loanOfficer.corporateImage" max-width="200px" class="mx-4" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MapStates from '@/components/HomePage/MapStates.vue';

export default {
  components: {
    MapStates
  },
  computed: {
    loanOfficer() {
      return this.$store.state.loanOfficer.info;
    },
    landingPageContent() {
      return this.$store.state.loanOfficer.landingPageContent;
    },
    states() {
      return this.loanOfficer.states.map((i) => i.abbreviation);
    },
    statesString() {
      return this.loanOfficer.states.map((i) => i.state).join(', ');
    }
  }
};
</script>

<style scoped>
ul > li {
  font-size: 1.2rem;
  line-height: 2rem;
}
.image2 {
  width: 100%;
  max-width: 500px;
  height: auto;
  position: relative;
  overflow: visible;
}
.image-container {
  width: 100%;
  max-width: 500px;
  height: auto;
  position: relative;
  overflow: visible;
}
.image-container::before {
  content: '';
  z-index: -1;
  display: block;
  height: 200%;
  width: 200%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #0066d7;
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.1;
}

.logos {
  gap: 24px;
}
</style>
