<template>
  <v-sheet class="d-flex flex-column align-center mb-10 px-1 px-sm-4" width="100%">
    <v-sheet width="80%" min-width="300" max-width="1000"
      ><CustomScenarioChart :period="otherValues.lifeExpectancy" category="age"
    /></v-sheet>

    <v-sheet width="80%" min-width="300" max-width="1000">
      <div class="small-text-font mt-14">
        Your equity right now is approximately
        <span class="font-weight-bold">{{
          formatMoney(tableValues.finalValuesByNow.homeEquity)
        }}</span
        >. If your property value increases by approximately
        <span class="font-weight-bold">{{
          formatPercentage(selectedValues.propertyValueApreciationRate)
        }}</span>
        each year for the next
        <span class="font-weight-bold">{{ otherValues.lifeExpectancy }}</span> years, your equity in
        <span class="font-weight-bold">{{ otherValues.lifeExpectancy }}</span> years will be about
        the same as it is now:
        <span class="font-weight-bold">{{
          formatMoney(tableValues.finalValuesByYears.homeEquity)
        }}</span>
        .......without making any mortgage payments.
      </div>
    </v-sheet>

    <CustomPropertValueAppreciationRatesTable />
    <v-sheet class="my-10" width="80%" min-width="300" max-width="1000"
      ><NowVsFutureTableCDE
        finalRowLabel="Equity Change + Cash Savings"
        preFinalRowLabel="Estimated Cash Flow Savings"
        :preFinalRowValue="estimatedTotalCashFlowSavings"
    /></v-sheet>
  </v-sheet>
</template>

<script>
// import InterestRateScenarioChart from '../../Charts/InterestRateScenarioChart.vue';
// import InterestRateScenarioSelector from '../../Selectors/InterestRateScenarioSelector.vue';
// import CustomInterestRatesTable from '../../Tables/CustomInterestRatesTable.vue';
import NowVsFutureTableCDE from '../../Tables/NowVsFutureTableCDE.vue';
import CustomScenarioChart from '../../Charts/CustomScenarioChart.vue';
import CustomPropertValueAppreciationRatesTable from '../../Tables/CustomPropertValueAppreciationRatesTable.vue';

import helpers from '../../../helpers';

export default {
  components: {
    // CustomInterestRatesTable,
    // InterestRateScenarioSelector,
    // InterestRateScenarioChart,
    NowVsFutureTableCDE,
    CustomScenarioChart,
    CustomPropertValueAppreciationRatesTable
  },
  computed: {
    tableValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.tableValues;
    },
    selectedValues() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected;
    },
    otherValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues;
    },
    estimatedTotalCashFlowSavings() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.tableValues
        .estimatedTotalCashFlowSavings;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    formatPercentage: helpers.formatPercentage
  }
};
</script>

<style scoped></style>
