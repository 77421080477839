<template>
  <div class="my-4 full-width">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="clauseText--text medium-small-text-font">
            2. Schedule Payments you wish to receive from the reverse mortgage
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
          <v-sheet
            class="d-flex flex-column justify-space-between align-start generalBackground mb-8"
            width="100%"
            max-width="500"
          >
            <ImmediateDrawTextField
              :label="`Initial Draw Amount - maximum is ${formatMoney(maxImmediateCash)}`"
              :showMax="false"
              :customBackground="true"
              :maxImmediateDraw="maxImmediateCash"
            />
            <RMPaymentsPeriodSelectors
              startingLabel="When do you want to start receiving monthly
payments?"
              yearsLabel="How long (in years) do you wish to receive payments?"
              :showTooltip="false"
              :customBackground="true"
            />
            <MonthlyAmountSelectors
              :maxPerMonthLabel="`Do you want the max/month of ${formatMoney(
                otherValues.scheduleMaxPerMonth
              )}?`"
              yourAmountLabel="Enter the amount you want"
              :customBackground="true"
            />
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ImmediateDrawTextField from '../Selectors/ImmediateDrawTextField.vue';
import RMPaymentsPeriodSelectors from '../Selectors/RMPaymentsPeriodSelectors.vue';
import MonthlyAmountSelectors from '../Selectors/MonthlyAmountSelectors.vue';
import helpers from '../../helpers';

export default {
  components: { ImmediateDrawTextField, RMPaymentsPeriodSelectors, MonthlyAmountSelectors },
  computed: {
    averageAnnualAppreciationRate() {
      return this.$store.state.scenarioData.currentScenarioData.chartsData
        .caseSchillerPropertyValueIndex.minOfAverageAppreciation;
    },
    otherValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues;
    },
    maxImmediateCash() {
      return this.otherValues.initialDrawAmountMaximum;
    },
    expandedPanel() {
      return this.$store.state.scenarioData.expandedPanel;
    }
  },

  methods: {
    formatPercentage: helpers.formatPercentage,
    formatMoney: helpers.formatMoney
  }
};
</script>

<style scoped></style>
