import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

import guestAxios, { userAxios } from '@/axios';
import { statesAndZipCodes } from '@/views/Form/constants';

const getDefaultState = () => ({
  resultUID: null,
  resultId: null,
  referral: null,
  landingPageFormData: {},
  scenario: '',
  allScenarioTitles: {},
  currentScenarioData: {
    finalValues: {},
    selectsOptions: {},
    chartsData: {}
  },
  qrData: { request: {}, response: {} },
  qrCalculatedData: { request: {}, response: {} },
  doesNotQualifyReasons: [],
  cashDisbursementFixed: false,
  expandedPanel: null
});

export default {
  state: getDefaultState(),
  mutations: {
    setResultId(state, { id, uid }) {
      state.resultId = id;
      state.resultUID = uid;
    },
    setReferral(state, data) {
      state.referral = data;
    },
    setLandingPageFormData(state, data) {
      state.landingPageFormData = data;
    },
    setScenario(state, data) {
      state.scenario = data;
    },
    setAllScenarioTitles(state, data) {
      state.allScenarioTitles = data;
    },
    setQRData(state, data) {
      state.qrData = data;
    },
    setQRCalculatedData(state, data) {
      state.qrCalculatedData = data;
    },
    setCashDisbursementState(state, value) {
      state.cashDisbursementFixed = value;
    },
    setScenarioData(state, data) {
      state.currentScenarioData = {
        finalValues: { ...state.currentScenarioData.finalValues, ...data.finalValues },
        selectsOptions: { ...state.currentScenarioData.selectsOptions, ...data.selectsOptions },
        chartsData: { ...state.currentScenarioData.chartsData, ...data.chartsData }
      };
    },
    setDoesNotQualifyReasons(state, value) {
      state.doesNotQualifyReasons = _.reverse(value);
    },
    changeOptionValues(state, data) {
      state.currentScenarioData.selectsOptions.selected[data.fieldName] = data.value;
    },
    changePercentagesByYears(state, data) {
      state.currentScenarioData.selectsOptions.selected.percentagesByYears[data.index].percentage =
        data.value;
    },
    changePropertyRatesByYears(state, data) {
      state.currentScenarioData.selectsOptions.selected.customPropertyValueRates[
        data.index
      ].percentage = data.value;
    },
    setExpanded(state, value) {
      state.expandedPanel = value;
    },
    reset(state) {
      Object.assign(state, getDefaultState());
    }
  },
  getters: {
    isResultSaved(state) {
      return !!state.resultId && !!state.resultUID;
    }
  },
  actions: {
    resetCalculationResults({ commit }) {
      commit('reset');
    },
    async getAllData({ commit, rootState }, { submitData }) {
      commit('setLandingPageFormData', submitData);
      commit('setLoading');
      const loanOfficerUID = rootState.loanOfficer.uid;
      const referralUID = rootState.loanOfficer.referral?.uid;
      console.log({ loanOfficerUID, referralUID });
      const parameters = { loanOfficerUID, referralUID };

      try {
        const { data } = await userAxios.post('landing-form', {
          landingFormData: submitData,
          parameters
        });
        console.log('got', data);

        if (data.allScenarioData.matchedScenario) {
          commit('setScenario', data.allScenarioData.matchedScenario.title);
          commit('setAllScenarioTitles', data.allScenarioData.scenariosTitles);
          commit('setScenarioData', data.allScenarioData.scenarioData);
          commit('setAmortCompAndTALCData', data.allScenarioData.amortCompAndTALC);
          commit('setEmailToLOData', data.allScenarioData.emailToLO);
        } else {
          commit('setDoesNotQualifyReasons', data.allScenarioData.doesNotQualifyReasons);
        }
      } catch (e) {
        console.log(e);
      }
      commit('setLoading');
    },
    async getAllSavedData({ commit }, { resultUID }) {
      try {
        commit('setLoading');
        const { data } = await guestAxios.get(`/public/calculation-results/${resultUID}`);
        const id = data.id;

        if (data.allScenarioData.matchedScenario) {
          commit('setResultId', { id, uid: resultUID });
          commit('setScenario', data.allScenarioData.matchedScenario.title);
          commit('setAllScenarioTitles', data.allScenarioData.scenariosTitles);
          commit('setScenarioData', data.allScenarioData.scenarioData);
          commit('setLandingPageFormData', data.landingFormData);
          commit('setAmortCompAndTALCData', data.allScenarioData.amortCompAndTALC);
          commit('setReferral', data.referral);

          //dispatch('checkInvite', { loanOfficerUID: data.loanOfficerUID });
        }
      } catch (error) {
        console.error(error);
      } finally {
        commit('setLoading');
      }
    },
    async getNewScenarioData({ state, commit, rootState }, isAudit) {
      commit('setUpdating');
      const options = state.currentScenarioData.selectsOptions.selected;
      const loanOfficerUID = rootState.loanOfficer.uid;
      const parameters = { loanOfficerUID };
      let endpoint = 'scenario/';
      switch (state.scenario) {
        case state.allScenarioTitles.scenarioATitle:
          endpoint += 'A';
          break;
        case state.allScenarioTitles.scenarioBTitle:
          endpoint += 'B';
          break;
        case state.allScenarioTitles.scenarioCTitle:
          endpoint += 'C';
          break;
        case state.allScenarioTitles.scenarioDTitle:
          endpoint += 'D';
          break;
        case state.allScenarioTitles.scenarioETitle:
          endpoint += 'E';
          break;
      }
      try {
        const { data } = await userAxios.post(endpoint, {
          landingFormData: state.landingPageFormData,
          options,
          isAudit,
          parameters
        });

        console.log('new scenario data', data);
        commit('setScenarioData', data.scenarioData);
        commit('setAmortCompAndTALCData', data.amortCompAndTALC);
        commit('setEmailToLOData', data.emailToLO);
      } catch (e) {
        console.log(e);
      }
      commit('setUpdating');
    },
    async postResultsToLO({ state, commit, getters, rootState }) {
      try {
        commit('setUpdating');
        const loanOfficerUID = rootState.loanOfficer.uid;
        const referralUID = rootState.loanOfficer.referral?.uid;

        const lenderLoanNumber = state.qrData.response?.Identification?.LenderLoanNumber;
        const leadCaptureData = getters.leadCaptureData;

        const dataToSend = {
          loanOfficerUID,
          referralUID,
          landingFormData: state.landingPageFormData,
          scenarioTitle: state.scenario,
          scenarioData: {
            selectsOptions: state.currentScenarioData.selectsOptions,
            finalValues: state.currentScenarioData.finalValues
          },
          leadCaptureData,
          lenderLoanNumber,
          doesNotQualifyReasons: state.doesNotQualifyReasons,
          type: 'WEB'
        };

        const result = await userAxios.post('/public/calculation-results', dataToSend);
        const response = result.data;
        commit('setResultId', { id: response.id, uid: response.uid });
        console.log('data posted to Loan officer', response);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        commit('setUpdating');
      }
    },

    sendToQRData({ commit, state, rootState }) {
      const scenarioData = rootState.scenarioData;
      const isABDScenario = (() => {
        return (
          scenarioData.scenario === scenarioData.allScenarioTitles.scenarioATitle ||
          scenarioData.scenario === scenarioData.allScenarioTitles.scenarioBTitle ||
          scenarioData.scenario === scenarioData.allScenarioTitles.scenarioDTitle
        );
      })();
      const hecm = isABDScenario ? 'hecmMonthlyARM' : 'hecmFixedLR';
      const amortCompAndTALCData = rootState.amortCompAndTALC.data;

      const availableAssets = numeral(
        scenarioData.landingPageFormData.incomeAndAssets.householdMonthlyIncome
      )
        .add(scenarioData.landingPageFormData.incomeAndAssets.checkingSavingsAccounts)
        .add(scenarioData.landingPageFormData.incomeAndAssets.taxableInvestmentAccount)
        .value();
      const dateNow = moment().format('YYYY-MM-DD');
      const homeOwnerDOB = (() => {
        let dob = moment(dateNow)
          .subtract(scenarioData.landingPageFormData.basic.youngestOwnerAge, 'years')
          .format();
        let monthsToAdd;
        if (scenarioData.landingPageFormData.isBirthDaySoon) {
          monthsToAdd = 1;
        } else {
          monthsToAdd = 8;
        }
        dob = moment(dob).add(monthsToAdd, 'months').format('YYYY-MM-DD');

        return dob;
      })();

      let dataToSend = {
        ImportLoanId: '506',
        Loan: {
          Borrowers: {
            Items: [
              {
                Dob: homeOwnerDOB, //'1954-01-01T10:44:37.314Z',
                FirstName: 'Kavin',
                LastName: 'Bacon',
                Type: {
                  Id: 0,
                  Name: 'Borrower'
                },
                MonthlyIncome:
                  scenarioData.landingPageFormData.incomeAndAssets.householdMonthlyIncome,
                AvaiableAssets: availableAssets
              }
            ] //change later to lead capture data
          },
          LoanUsers: {
            LoanOfficerFirstName: 'David',
            LoanOfficerLastName: 'Darling',
            LoanOfficerNMLSNumber: '239431',
            LoanOfficerEmail: 'dbdarling57@hotmail.com'
          } //change to exact loan officet(Calcs J5,J6...)
        }
      };
      const stateObject = statesAndZipCodes.find(
        (element) => element.state === scenarioData.landingPageFormData.creditAndProperty.state
      );
      const stateAbreviation = stateObject.abbreviation;
      const ZipCode = stateObject.zipCode;

      dataToSend.Loan.Property = {
        PropertyValueEstimate: scenarioData.landingPageFormData.basic.propertyValue,
        PropertyType: {
          Id: 5,
          Name: scenarioData.landingPageFormData.creditAndProperty.propertyType
        },
        LivingAreaAboveGrade:
          scenarioData.landingPageFormData.creditAndProperty.estimatedSquareFeet,
        //"Insurance": {
        //   "IsFloodInsuranceRequired": true,
        //   "FloodZoneText": "string"
        // },
        Address: {
          Zip: ZipCode,
          StateFk: stateAbreviation
        } //need changes to leadCaptureData
      };
      const yearBuilt = scenarioData.landingPageFormData.creditAndProperty.yearBuilt;
      if (yearBuilt) {
        dataToSend.Loan.Property.YearBuilt = yearBuilt;
        dataToSend.Loan.ManufacturedHome = { Year: yearBuilt };
      }
      //missed fields: Lesa
      const conditionalFields = isABDScenario
        ? { MarginOrRate: amortCompAndTALCData.reverseMortgageComparison.margin[hecm] }
        : {};
      const ExpectedRate =
        amortCompAndTALCData.reverseMortgageComparison.expectedInterestRate[hecm] * 100;
      const InitialRate =
        amortCompAndTALCData.reverseMortgageComparison.initialInterestRate[hecm] * 100;
      const MonthlyServicingFee = 0; // need changing, G130 Calcs
      // const ClosingCosts = amortCompAndTALCData.reverseMortgageComparison.otherCosts[hecm];
      const ClosingCostsNetCredits = 0; // zero is okay
      const CreditIn802 = amortCompAndTALCData.reverseMortgageComparison.credits[hecm];
      // const CashDisbursement = amortCompAndTALCData.reverseMortgageComparison.cashRequest[hecm];
      const CashDisbursement = scenarioData.cashDisbursementFixed
        ? 40000
        : amortCompAndTALCData.reverseMortgageComparison.cashRequest[hecm];
      const OwnersTitlePolicyLimit = scenarioData.landingPageFormData.basic.propertyValue;
      const TermMonthlyDisbursement =
        amortCompAndTALCData.reverseMortgageComparison.monthlyPaymentRequest[hecm];
      const Loc = amortCompAndTALCData.reverseMortgageComparison.totalLineOfCredit[hecm];

      dataToSend.Loan.Calculation = {
        ...conditionalFields,
        ExpectedRate,
        InitialRate,
        MonthlyServicingFee,
        // ClosingCosts,
        ClosingCostsNetCredits,
        CreditIn802,
        CashDisbursement,
        OwnersTitlePolicyLimit,
        Loc,
        TermMonthlyDisbursement
      };

      dataToSend.Loan.Payoffs = {
        Payoffs: [
          {
            PayoffGoodThrough: dateNow,
            ValidUntilDate: dateNow,
            // "Since": "2022-01-05T08:54:35.452Z", later
            //           "PayoffType": {
            //             "Name": "string",
            //             "Id": 0
            //           },
            IsMandatoryObligation: true,
            Balance: scenarioData.landingPageFormData.mortgages.firstMortgageBalance
          },
          {
            PayoffGoodThrough: dateNow,
            ValidUntilDate: dateNow,
            IsMandatoryObligation: true,
            Balance: scenarioData.landingPageFormData.mortgages.homeEquityLine
          }
        ]
      };
      dataToSend.Context = {
        ClosingDate: moment(
          amortCompAndTALCData.reverseMortgageComparison.additionalData.closingDate
        ).format('YYYY-MM-DD')
      };

      //FirstYearLesaDisbursement Calcs CM24

      //todo Zip,Dob,FirstName,LastName, IsHouseholdMember, YearsAtCurrentAddress, MonthlyIncome(temp added), AvaiableAssets(temp added),IntentionToBuyFinancialProducts
      dataToSend.Loan.FinancialAssessment = {
        LiabilityAndExpense: {
          LiabilityExpenseItems: [
            {
              // OwnerIdList: ['00000000-0000-0000-0000-000000000000'],
              MonthlyCharge: scenarioData.landingPageFormData.mortgages.annualTaxes / 12,
              Balance: 0,
              HighBalance: 0,
              ExcludeFromFACalculation:
                scenarioData.landingPageFormData.creditAndProperty.creditHistory !== 'Excellent',
              // PayoffId: '00000000-0000-0000-0000-000000000000',
              // isDeleted: true,
              // CreditorName: 'string',
              // AccountNameOrNumber: 'string',
              // Since: '2022-08-03T19:06:17.127Z',
              // LastReported: '2022-08-03T19:06:17.127Z',
              // Employer: 'string',
              // Id: '00000000-0000-0000-0000-000000000000',
              // RealEstateReferred: {
              //   Name: 'string',
              //   Id: 0
              // },
              LiabilityExpenseTypeEnum: {
                Name: 'Property Taxes',
                Id: 1210
              }
            }
          ]
        }
      };

      commit('setQRData', { ...state.qrData, request: dataToSend });
    },

    async createLoanInQR({ commit, dispatch, state }) {
      commit('setUpdating');
      await dispatch('sendToQRData');
      const sendToQRData = { ...state.qrData.request };
      console.log(sendToQRData);
      let response;
      try {
        const result = await userAxios.post('/createLoanQR', sendToQRData);
        response = result.data;
        console.log('loan created', response);
      } catch (e) {
        console.log(e);
        response = e;
      }

      commit('setQRData', { request: sendToQRData, response });

      commit('setUpdating');
    },

    async calculateLoanEstimateQR({ commit }, sendToQRData) {
      commit('setUpdating');
      console.log('sent to QR data', sendToQRData);
      let response;
      try {
        const { data } = await userAxios.post('/calculate-loan-estimate', sendToQRData);
        response = data.Result;
        console.log('calculated loan estimate calculated', data);
      } catch (e) {
        console.log(e);
        response = e;
      }

      commit('setQRCalculatedData', { request: sendToQRData, response });

      commit('setUpdating');
    }
  }
};
