<template>
  <v-sheet v-bind:class="{ generalBackground: isBackgroundCustom }" width="100%"
    ><v-sheet
      class="d-flex flex-column flex-sm-row justify-space-between align-center mb-3"
      v-bind:class="{ generalBackground: isBackgroundCustom }"
      width="100%"
    >
      <div class="small-text-font text-center text-sm-start mb-1 mb-sm-0" style="max-width: 250px">
        {{ maxPerMonthLabel }}
      </div>
      <v-sheet width="200">
        <v-select
          v-bind:class="{ generalBackground: isBackgroundCustom }"
          hide-details
          item-value="value"
          item-text="title"
          :items="yesNo"
          dense
          outlined
          :value="doYouWantMaxPerMonth"
          @change="(value) => handleScenarioOptionsChange(value, 'doYouWantMaxPerMonth')"
          :disabled="isUpdating"
          :readonly="isResultSaved"
        />
      </v-sheet>
    </v-sheet>
    <v-sheet
      v-if="!selected.doYouWantMaxPerMonth"
      class="d-flex flex-column flex-sm-row justify-space-between align-center align-sm-start"
      v-bind:class="{ generalBackground: isBackgroundCustom }"
      width="100%"
    >
      <div
        class="small-text-font text-center text-sm-start mb-1 mb-sm-0 mt-0 mt-sm-2"
        style="max-width: 250px"
      >
        {{ yourAmountLabel }}
      </div>
      <v-sheet width="200">
        <v-text-field
          v-bind:class="{ generalBackground: isBackgroundCustom }"
          prefix="$"
          outlined
          :hide-details="!amountYouWantError && !inputError"
          :disabled="isUpdating"
          :readonly="isResultSaved"
          :value="formatMoneyForInput(amountYouWant)"
          @input="(value) => handleScenarioOptionsChange(value, 'amountYouWant')"
          :error="Boolean(inputError)"
          :error-messages="inputError"
        ></v-text-field></v-sheet></v-sheet
  ></v-sheet>
</template>

<script>
import _ from 'lodash';
import { yesNo } from '@/views/Form/constants';
import helpers from '@/helpers';

export default {
  props: ['maxPerMonthLabel', 'yourAmountLabel', 'customBackground'],
  data: () => ({
    yesNo,
    amountYouWantCheck: 0,
    amountYouWantError: false,
    inputError: ''
  }),
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    amountYouWant: {
      get() {
        return this.$data.amountYouWantCheck || this.selected.amountYouWant;
      },
      set(value) {
        this.$data.amountYouWantCheck = value;
      }
    },
    doYouWantMaxPerMonth() {
      return this.selected.doYouWantMaxPerMonth;
    },
    selected() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected;
    },
    maxPerMonth() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues
        .scheduleMaxPerMonth;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    },
    isBackgroundCustom() {
      return this.$props.customBackground || false;
    }
  },
  watch: {
    maxPerMonth: function (newValue) {
      if (this.amountYouWant > newValue) {
        this.amountYouWant = newValue;
        this.$store.commit('changeOptionValues', { fieldName: 'amountYouWant', value: newValue });
      }
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    formatMoneyForInput: helpers.formatMoneyForInput,
    unformatMoney: helpers.unformatMoney,
    saveScenarioOption: _.debounce(function (value, fieldName) {
      if (!this.amountYouWantError) {
        let data;
        if (fieldName === 'scheduleNumberOfYears' && value === 'Lifetime') {
          data = { fieldName, value: 999 };
        } else {
          data = { fieldName, value };
        }
        this.$store.commit('changeOptionValues', data);
        this.$store.dispatch('getNewScenarioData');
      }
    }, 1000),
    handleAmountChange(value) {
      this.$data.inputError = '';

      const currentValue = value.length === 0 ? 0 : this.unformatMoney(value);

      if (currentValue === null) {
        this.$data.inputError = 'Value must be a number';
        return null;
      }

      const validationResponse = this.amountYouWantRule(currentValue);

      if (typeof validationResponse === 'string') {
        this.$data.inputError = validationResponse;
      }

      return currentValue;
    },
    handleScenarioOptionsChange(value, fieldName) {
      const currentValue = fieldName !== 'amountYouWant' ? value : this.handleAmountChange(value);

      if (currentValue !== null) {
        this.saveScenarioOption(currentValue, fieldName);
      }
    },
    amountYouWantRule(value) {
      if (value < 0) {
        this.amountYouWantError = true;
        return 'Should be a positive number!';
      } else if (value > this.maxPerMonth) {
        this.amountYouWantError = true;
        return "Shouldn't exceed maximum value!";
      } else {
        this.amountYouWantError = false;
        return true;
      }
    }
  }
};
</script>
