<template>
  <v-sheet
    class="
      d-flex
      flex-column
      justify-space-between
      align-start align-self-center align-self-sm-start
      mb-4
    "
    width="100%"
    max-width="460"
  >
    <RMPaymentsPeriodSelectors
      startingLabel="Starting"
      yearsLabel="Number of years"
      :showTooltip="true"
      :customBackground="false"
    />
    <MonthlyAmountSelectors
      :maxPerMonthLabel="`Want the max/month ${formatMoney(otherValues.scheduleMaxPerMonth)}?`"
      yourAmountLabel="Enter the amount you want"
      :customBackground="false"
    />
  </v-sheet>
</template>

<script>
import RMPaymentsPeriodSelectors from '../../../Selectors/RMPaymentsPeriodSelectors.vue';
import MonthlyAmountSelectors from '../../../Selectors/MonthlyAmountSelectors.vue';
import helpers from '../../../../helpers';

export default {
  components: {
    RMPaymentsPeriodSelectors,
    MonthlyAmountSelectors
  },
  computed: {
    otherValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney
  }
};
</script>

<style scoped></style>
