<template>
  <div class="my-8">
    <ImportantData v-if="!isResultSaved" />
    <SavedImportantData v-if="isResultSaved" />
    <!-- <CurrentHomeEquitySnapshot /> -->
  </div>
</template>

<script>
// import CurrentHomeEquitySnapshot from './CurrentHomeEquitySnapshot.vue';
import ImportantData from './ImportantData/index.vue';
import SavedImportantData from './SavedResult/SavedImportantData.vue';

export default {
  components: {
    // CurrentHomeEquitySnapshot,
    ImportantData,
    SavedImportantData
  },
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    }
  }
};
</script>

<style scoped></style>
