<template>
  <v-sheet class="table" width="100%">
    <v-data-table
      class="generalBackground"
      hide-default-header
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
    >
      <template v-slot:body="">
        <tbody>
          <tr>
            <td class="cell">
              <div class="extra-small-text-font text-left font-weight-medium text-uppercase">
                Enter the amount here
              </div>
            </td>
            <td class="cell rightCell blue lighten-5">
              <v-text-field
                class="amount-input extra-small-text-font ma-0 pa-0"
                prefix="$"
                :value="formatMoneyForInput(currentAmount)"
                :hide-details="!amountError && !inputError"
                :disabled="isUpdating"
                :readonly="isResultSaved"
                @input="(value) => handleScenarioOptionsChange(value, 'amount')"
                :error="Boolean(inputError)"
                :error-messages="inputError"
              ></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="cell">
              <div class="extra-small-text-font text-left font-weight-medium text-uppercase">
                This is the monthly payment you would have to make, beginning immediately, to
                achieve the desired equity, at the assumed appreciation rate.
              </div>
            </td>
            <td class="cell rightCell">
              <div class="small-text-font text-center" style="font-weight: 500">
                {{ formatMoney(otherValues.monthlyPayment) }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="cell">
              <div class="extra-small-text-font text-left font-weight-medium text-uppercase">
                Should we revise the numbers to reflect the volunatry monthly payment?
              </div>
            </td>
            <td class="cell rightCell blue lighten-5">
              <v-select
                style="max-width: 150px"
                class="mx-auto extra-small-text-font"
                hide-details
                :items="yesNo"
                item-value="value"
                item-text="title"
                flat
                dense
                :value="shouldWeReviseNumbers"
                @change="(value) => handleScenarioOptionsChange(value, 'shouldWeReviseNumbers')"
                :disabled="isUpdating"
                :readonly="isResultSaved"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import _ from 'lodash';
import { yesNo } from '@/views/Form/constants';
import helpers from '@/helpers';

export default {
  props: ['isAudit'],
  data() {
    return { yesNo, amountError: false, inputError: '' };
  },
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    currentAmount() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected.amount;
    },
    otherValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues;
    },
    shouldWeReviseNumbers() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
        .shouldWeReviseNumbers;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    formatMoneyForInput: helpers.formatMoneyForInput,
    unformatMoney: helpers.unformatMoney,
    saveScenarioOption: _.debounce(function (value, fieldName) {
      if (!this.futureAgeError) {
        const data = { fieldName, value };
        this.$store.commit('changeOptionValues', data);
        this.$store.dispatch('getNewScenarioData', this.$props.isAudit);
      }
    }, 1000),
    handleAmountChange(value) {
      this.$data.inputError = '';

      const currentValue = value.length === 0 ? 0 : this.unformatMoney(value);

      if (currentValue === null) {
        this.$data.inputError = 'Value must be a number';
        return null;
      }

      const validationResponse = this.amountRule(currentValue);

      if (typeof validationResponse === 'string') {
        this.$data.inputError = validationResponse;
      }

      return currentValue;
    },
    handleScenarioOptionsChange(value, fieldName) {
      const currentValue = fieldName !== 'amount' ? value : this.handleAmountChange(value);

      if (currentValue !== null) {
        this.saveScenarioOption(currentValue, fieldName);
      }
    },
    amountRule(value) {
      if (value < 0) {
        this.amountError = true;
        return 'Should be a positive value!';
      } else {
        this.amountError = false;
        return true;
      }
    }
  }
};
</script>

<style scoped>
.table {
  border: 2px solid #e2e2e2;
  border-radius: 10px;
  overflow: hidden;
}
.headerCell {
  min-width: 100px;
  width: 50%;
  background-color: #f3f3f3;
}
.cell {
  min-width: 100px;
  width: 50%;
}
.rightCell {
  border-left: 1px solid #e2e2e2;
}
.amount-input >>> input {
  width: 50px;
}
</style>
