<template>
  <v-sheet
    class="d-flex flex-row justify-space-between align-center"
    v-bind:class="{ generalBackground: isBackgroundCustom }"
    width="100%"
  >
    <div class="small-text-font mr-2 mr-sm-0">{{ label }}</div>
    <div>
    <v-sheet>
      <v-select
        class="py-4"
        v-bind:class="{ generalBackground: isBackgroundCustom }"
        hide-details
        :items="allInterestRateScenario"
        dense
        outlined
        :value="currentInterestRateScenario"
        @change="(value) => handleScenarioOptionsChange(value, 'interestRateScenario')"
        :disabled="isUpdating"
        :readonly="isResultSaved"
      />
    </v-sheet>
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: ['label', 'customBackground'],
  computed: {
    isBackgroundCustom() {
      return this.$props.customBackground || false;
    },
    allInterestRateScenario() {
      const allOptionsObject =
        this.$store.state.scenarioData.currentScenarioData.selectsOptions
          .interestRateScenarioOptions;
      return Object.values(allOptionsObject);
    },
    currentInterestRateScenario() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
        .interestRateScenario;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    },
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    }
  },
  methods: {
    handleScenarioOptionsChange(value, fieldName) {
      const data = { fieldName, value };
      this.$store.commit('changeOptionValues', data);
      this.$store.dispatch('getNewScenarioData');
    }
  }
};
</script>
