<template>
  <section class="full-width">
    <div class="my-4 full-width">
      <v-expansion-panels :value="isResultSaved ? 0 : null">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-sheet style="flex: 1" class="d-flex flex-column align-center align-sm-start">
              <div class="clauseText--text medium-small-text-font my-2">
                5. Schedule Payments you wish to receive from the reverse mortgage
              </div>
            </v-sheet>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-4">
            <Selectors />
            <v-sheet style="flex: 1" min-width="280"><CashFlowImpactChart /></v-sheet>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="my-4 full-width">
      <v-expansion-panels :value="0">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-sheet style="flex: 1" class="d-flex flex-column align-center align-sm-start">
              <div class="clauseText--text medium-small-text-font my-2">
                6. Change the number in the blue cell below to see important data at a particular
                age of the youngest borrower.
              </div>
            </v-sheet>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-4">
            <NowVsFutureTableAB />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </section>
  <!-- <AuditButton v-if="!isResultSaved" /> -->
</template>

<script>
import { mapGetters } from 'vuex';

import Selectors from './Selectors.vue';
import NowVsFutureTableAB from '../../../Tables/NowVsFutureTableAB.vue';
// import AuditButton from '../../../AuditButton.vue';
import CashFlowImpactChart from '../../../Charts/CashFlowImpactChart.vue';

export default {
  components: {
    Selectors,
    NowVsFutureTableAB,
    // AuditButton,
    CashFlowImpactChart
  },
  computed: {
    ...mapGetters(['isResultSaved']),
    youngestOwnerAge() {
      return this.$store.state.scenarioData.landingPageFormData.basic.youngestOwnerAge;
    }
  }
};
</script>

<style scoped></style>
