<template>
  <div class="d-flex flex-column justify-center align-center pa-2 mt-12 footer">
    <div class="d-flex justify-center align-center gap4">
      <router-link to="/privacy-and-personal-information-notice">
        <div>Privacy and Personal Information Notice</div></router-link
      >
      <router-link to="/terms-and-conditions-of-use">
        <div>Terms and Conditions of Use</div></router-link
      >
    </div>
    <div class="mark">{{ year }} - GRIPH</div>
  </div>
</template>

<script>
export default {
  data: () => ({
    year: new Date().getFullYear()
  })
};
</script>

<style scoped>
.footer {
  font-size: 14px;
  background-color: #dce6f2 !important;
  border-top: 1px solid #cdcdcd !important;
}

.gap4 {
  flex-wrap: wrap;
  column-gap: 25px;
}

.mark {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 500;
}
</style>
