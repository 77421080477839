<template>
  <v-sheet
    class="d-flex flex-column flex-sm-row justify-space-between align-center mb-2 mb-sm-4"
    width="100%"
  >
    <div
      class="small-text-font mr-0 mr-sm-4 mb-1 mb-sm-0 text-center text-sm-left"
      v-bind:class="{
        'totalCashFlowImpactText--text font-weight-bold': isTextCustom,
        'blue--text darken-5': isAccented
      }"
    >
      {{ label }}
    </div>
    <v-sheet
      class="value-container d-flex flex-row justify-center align-center"
      v-bind:class="{
        totalCashFlowImpactBackground: isBackgroundCustom,
        'blue--text darken-5': isAccented
      }"
      max-width="250"
      min-width="250"
      height="40"
    >
      <div
        class="medium-small-text-font font-weight-regular"
        v-bind:class="{ 'font-weight-bold': isTextCustom }"
      >
        {{ formatMoney(value) }}
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
import helpers from '../helpers';

export default {
  props: ['label', 'value', 'customBackground', 'customText', 'accent'],
  computed: {
    isTextCustom() {
      return this.$props.customText || false;
    },
    isBackgroundCustom() {
      return this.$props.customBackground || false;
    },
    isAccented() {
      return this.$props.accent || false;
    }
  },
  methods: { formatMoney: helpers.formatMoney }
};
</script>

<style scoped>
.value-container {
  border: 2px solid #e2e2e2;
  border-radius: 8px;
  overflow: hidden;
}
</style>
