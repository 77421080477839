<template>
  <v-sheet v-bind:class="{ generalBackground: isBackgroundCustom }" width="100%">
    <v-sheet
      class="d-flex flex-column flex-sm-row justify-space-between align-center mb-3"
      v-bind:class="{ generalBackground: isBackgroundCustom }"
      width="100%"
    >
      <v-sheet
        class="d-flex flex-row justify-center justify-sm-start align-end mb-1 mb-sm-0"
        v-bind:class="{ generalBackground: isBackgroundCustom }"
        max-width="250"
      >
        <div class="small-text-font mr-2 text-center text-sm-start">{{ yearsLabel }}</div>
        <v-tooltip v-if="showTooltip" top max-width="300">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >This application allows you to model voluntary payments over a maximum of 20
            years.</span
          ></v-tooltip
        >
      </v-sheet>
      <v-sheet width="200">
        <v-select
          v-model="numberOfYears"
          v-bind:class="{ generalBackground: isBackgroundCustom }"
          hide-details
          :items="numberOfYearsItems"
          dense
          outlined
          @change="(value) => handleScenarioOptionsChange(value, 'voluntaryNumberOfYears')"
          :disabled="isUpdating"
          :readonly="isResultSaved"
        />
      </v-sheet>
    </v-sheet>
    <v-sheet
      class="d-flex flex-column flex-sm-row justify-space-between align-center"
      v-bind:class="{ generalBackground: isBackgroundCustom }"
      width="100%"
    >
      <div class="small-text-font text-center text-sm-start mb-1 mb-sm-0" style="max-width: 250px">
        {{ startingLabel }}
      </div>
      <v-sheet width="200">
        <v-select
          v-model="starting"
          v-bind:class="{ generalBackground: isBackgroundCustom }"
          hide-details
          :items="startingItems"
          dense
          outlined
          @change="(value) => handleScenarioOptionsChange(value, 'voluntaryStarting')"
          :disabled="isUpdating"
          :readonly="isResultSaved"
        />
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  props: ['yearsLabel', 'startingLabel', 'showTooltip', 'customBackground'],
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    starting: {
      get() {
        return this.selectsOptions.selected.voluntaryStarting;
      },
      set(value) {
        this.$store.commit('changeOptionValues', { fieldName: 'voluntaryStarting', value });
      }
    },
    numberOfYears: {
      get() {
        return this.selectsOptions.selected.voluntaryNumberOfYears;
      },
      set(value) {
        this.$store.commit('changeOptionValues', { fieldName: 'voluntaryNumberOfYears', value });
      }
    },
    isBackgroundCustom() {
      return this.$props.customBackground || false;
    },
    selectsOptions() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions;
    },
    startingItems() {
      const items = [...this.selectsOptions.beginPayments];
      if (this.numberOfYears !== 999) {
        const numOfItemsToHide = this.numberOfYears - 1;
        items.splice(items.length - numOfItemsToHide, numOfItemsToHide);
      }
      return items;
    },
    numberOfYearsItems() {
      const items = [...this.selectsOptions.makeOrReceivePayments];
      items.pop();
      // items.shift();
      if (!(this.starting === 'Immediately' || this.starting === 'Never')) {
        const numOfItemsToHide = Number(this.starting.slice(3, 5)); // extract a number out of "in <num> years"
        items.splice(items.length - numOfItemsToHide, numOfItemsToHide);
      }
      return items;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    }
  },
  methods: {
    handleScenarioOptionsChange(value, fieldName) {
      const data = { fieldName, value };
      this.$store.commit('changeOptionValues', data);
      this.$store.dispatch('getNewScenarioData');
    }
  }
};
</script>
