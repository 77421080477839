<template>
  <div class="my-4 full-width">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="clauseText--text medium-small-text-font">
            3. Select an Interest Rate Scenario and Property Value Appreciation Rate
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
          <v-sheet class="d-flex flex-wrap justify-space-between align-start" width="100%">
            <v-sheet
              style="flex: 1"
              class="d-flex flex-column justify-space-between align-start mb-8 mb-lg-0"
              min-width="280"
            >
              <div class="small-text-font mb-10">
                You can select several different future interest rate options to learn how future
                interest rates will affect your loan balance and reverse mortgage line of credit.
              </div>
              <InterestRateScenarioSelector
                label="Interest rate scenario"
                :customBackground="false"
              />
            </v-sheet>
            <v-sheet style="flex: 1" min-width="280">
              <InterestRateScenarioChart />
            </v-sheet>
          </v-sheet>
          <CustomInterestRatesTable />
          <v-sheet class="d-flex flex-wrap justify-space-between align-start mt-10" width="100%">
            <v-sheet
              class="d-flex flex-column justify-space-between align-start mb-8 mt-10 mb-lg-0"
              width="100%"
              min-width="260"
              max-width="53%"
            >
              <div class="small-text-font mb-10">
                The average annual appreciation rate for your actuarial expected life is
                <span class="font-weight-bold">{{
                  formatPercentage(averageAnnualAppreciationRate)
                }}</span
                >.
              </div>
              <PropertyValueAppreciationRateSelector
                label="Property Value Appreciation Rate"
                :customBackground="false"
              />
            </v-sheet>
            <v-sheet width="100%" min-width="300" max-width="40%">
              <CaseSchillerPropertyValueIndexChart />
            </v-sheet>
            <CustomPropertValueAppreciationRatesTable />
          </v-sheet>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import InterestRateScenarioChart from '../Charts/InterestRateScenarioChart.vue';
import InterestRateScenarioSelector from '../Selectors/InterestRateScenarioSelector.vue';
import CustomInterestRatesTable from '../Tables/CustomInterestRatesTable.vue';
import CaseSchillerPropertyValueIndexChart from '../Charts/CaseSchillerPropertyValueIndexChart.vue';
import PropertyValueAppreciationRateSelector from '../Selectors/PropertyValueAppreciationRateSelector.vue';
import CustomPropertValueAppreciationRatesTable from '../Tables/CustomPropertValueAppreciationRatesTable.vue';
import helpers from '../../helpers';

export default {
  components: {
    CustomInterestRatesTable,
    InterestRateScenarioSelector,
    InterestRateScenarioChart,
    CaseSchillerPropertyValueIndexChart,
    PropertyValueAppreciationRateSelector,
    CustomPropertValueAppreciationRatesTable
  },
  computed: {
    averageAnnualAppreciationRate() {
      return this.$store.state.scenarioData.currentScenarioData.chartsData
        .caseSchillerPropertyValueIndex.minOfAverageAppreciation;
    },
    expandedPanel() {
      return this.$store.state.scenarioData.expandedPanel;
    }
  },
  methods: {
    formatPercentage: helpers.formatPercentage
  }
};
</script>

<style scoped></style>
