import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import Home from '@/views/Home/Home.vue';
import Form from '@/views/Form/Form.vue';
import SavedResult from '@/views/Result/SavedResult.vue';
import EmailConfirm from '@/views/EmailConfirm.vue';
import Page404 from '@/components/404.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About/About.vue')
  },
  {
    path: '/privacy-and-personal-information-notice',
    name: 'Privacy and Personal Information Notice',
    component: () => import('@/views/Other/PrivacyNotice.vue')
  },
  {
    path: '/terms-and-conditions-of-use',
    name: 'Terms and conditions of use',
    component: () => import('@/views/Other/TermsAndConditionsOfUse.vue')
  },
  {
    path: '/rm',
    name: 'Form',
    component: Form
  },
  {
    path: '/rm/result',
    name: 'Result',
    component: () => import('@/views/Result/Result.vue')
  },
  {
    path: '/rm/calculation/:resultUID',
    name: 'Saved Result',
    component: SavedResult,
    meta: { requireResultHash: true }
  },
  {
    path: '/rm/no-result',
    name: 'No Saved Result',
    component: () => import('@/views/NoResultSaved.vue'),
    meta: { allowGuest: true }
  },
  {
    path: '/rm/calculation/:resultUID/confirm/:confirmHash',
    name: 'Email Confirm',
    component: EmailConfirm,
    meta: { allowGuest: true }
  },
  // {
  //   path: '/rm/audit',
  //   name: 'Audit',
  //   component: () => import('@/views/Audit/index.vue')
  // },
  // {
  //   path: '/rm/qr',
  //   name: 'Quantum Reverse',
  //   component: () => import('@/views/QuantumReverse/index.vue')
  // },
  // {
  //   path: '/rm/:loanOfficerUID/qr-calculated',
  //   name: 'Quantum Reverse calculated',
  //   component: () => import('@/views/QuantumReverse/calculated.vue')
  // },
  {
    path: '/rm/lead-capture',
    name: 'Lead Capture',
    component: () => import('@/views/LeadCapture/index.vue')
  },
  {
    path: '*',
    name: '404',
    component: Page404,
    meta: { allowGuest: true }
  },
  {
    path: '404',
    name: '404',
    component: Page404,
    meta: { allowGuest: true }
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history'
});

router.beforeEach(async (to, from, next) => {
  const subdomain = window.location.hostname.split('.').slice(0, -2).join('.');
  const [loanOfficerUID, referral] = subdomain.split('-');

  const isLoanOfficerLoaded = store.getters['loanOfficer/isLoanOfficerLoaded'];

  if (!isLoanOfficerLoaded && loanOfficerUID) {
    await store.dispatch('loanOfficer/getLoanOfficer', { uid: loanOfficerUID, referral });
  }

  if (to.matched.some((record) => record.meta.allowGuest)) {
    return next();
  }

  if (to.matched.some((record) => record.meta.requireResultHash)) {
    const resultUID = to.params?.resultUID;

    if (resultUID) {
      await store.dispatch('getAllSavedData', { resultUID });
    }

    return next();
  }

  const isValid = store.getters['loanOfficer/isLoanOfficerLoaded'];

  if (isValid) {
    return next();
  }

  return next({ name: '404' });
});

export default router;
