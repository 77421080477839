<template>
  <v-sheet class="d-flex flex-column align-center mx-auto" width="100%" max-width="900px"
    ><v-tooltip top max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-icon large v-bind="attrs" v-on="on" class="mb-4"> mdi-information-outline </v-icon>
      </template>
      <span
        >The maximum future age in this table is {{ tableValues.ageNow + maxAgeDiff }}.</span
      ></v-tooltip
    >
    <v-sheet class="table" width="100%"
      ><v-data-table
        class="generalBackground table-body"
        hide-default-header
        hide-default-footer
        disable-pagination
        mobile-breakpoint="0"
      >
        <template v-slot:body="">
          <tbody>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">Age</div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">{{ tableValues.ageNow }}</div>
              </td>
              <td class="cell rightCell cellWidth blue lighten-4">
                <v-text-field
                  type="number"
                  class="future-age-input ma-0 pa-0"
                  :value="currentFutureAge"
                  :min="tableValues.ageNow"
                  :hide-details="!futureAgeError"
                  :rules="[futureAgeRule]"
                  :disabled="isUpdating"
                  :readonly="isResultSaved"
                  @change="(value) => handleScenarioOptionsChange(Number(value), 'futureAge')"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  Property Value
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByNow.propertyValue) }}
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByYears.propertyValue) }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  Mortgage Balance
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByNow.mortgageBalance) }}
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByYears.mortgageBalance) }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  Equity
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByNow.homeEquity) }}
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByYears.homeEquity) }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  Equity Change
                </div>
              </td>
              <td class="cell rightCell" colspan="2">
                <div class="small-text-font text-center blue--text darken-5 font-weight-medium">
                  {{ formatMoney(tableValues.equityChange) }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  Net cash flow impact
                </div>
              </td>
              <td class="cell rightCell" colspan="2">
                <div class="small-text-font text-center font-weight-medium">
                  {{ formatMoney(tableValues.netCashFlowImpact) }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div
                  class="small-text-font text-left green--text darken-5 font-weight-medium text-uppercase"
                >
                  Equity change and Net cash flow impact
                </div>
              </td>
              <td class="cell rightCell" colspan="2">
                <div class="small-text-font text-center green--text darken-5 font-weight-medium">
                  {{ formatMoney(tableValues.equityChangeAndNetCashFlowImpact) }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="cell cellWidth">
                <div class="small-text-font text-left font-weight-medium text-uppercase">
                  Line of credit
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByNow.lineOfCredit) }}
                </div>
              </td>
              <td class="cell rightCell cellWidth">
                <div class="small-text-font text-center">
                  {{ formatMoney(tableValues.finalValuesByYears.lineOfCredit) }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table></v-sheet
    ></v-sheet
  >
</template>

<script>
import helpers from '../../helpers';

export default {
  data() {
    return { maxAgeDiff: 24, futureAgeError: false };
  },
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    tableValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.tableValues;
    },
    currentFutureAge() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected.futureAge;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    handleScenarioOptionsChange(value, fieldName) {
      if (!this.futureAgeError) {
        const data = { fieldName, value };
        this.$store.commit('changeOptionValues', data);
        this.$store.dispatch('getNewScenarioData');
      }
    },
    futureAgeRule(value) {
      const ageNow = this.tableValues.ageNow;
      const ageDiff = value - ageNow;
      if (value < ageNow) {
        this.futureAgeError = true;
        return 'Should be larger than current age!';
      } else if (ageDiff > this.maxAgeDiff) {
        this.futureAgeError = true;
        return "Shouldn't exceed maximum age!";
      } else {
        this.futureAgeError = false;
        return true;
      }
    }
  }
};
</script>

<style scoped>
.table {
  border: 2px solid #e2e2e2;
  border-radius: 10px;
  overflow: hidden;
}
.table-body >>> .v-data-table__wrapper {
  overflow-x: hidden;
}
.headerCell {
  width: 90px;
  min-width: 90px;
  background-color: #f3f3f3;
}
.cell {
  width: 90px;
  min-width: 90px;
}
.cellWidth {
  width: 33%;
}
.rightCell {
  border-left: 1px solid #e2e2e2;
}
.future-age-input >>> input {
  text-align: center;
  width: 50px;
}
</style>
