<template>
  <div v-if="cashFlowImpact[0].data.length">
    <div class="cash-flow-chart-title">Cumulative Cash Flow</div>
    <VueApexCharts
      type="bar"
      width="100%"
      height="400"
      :options="cashFlowImpactChartOptions"
      :series="cashFlowImpact"
    ></VueApexCharts>
  </div>
</template>

<script>
import colors from '../../assets/colors';
import helpers from '../../helpers/index';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    VueApexCharts
  },
  data() {
    return {
      cashFlowImpactUnchangedChartOptions: {
        chart: {
          stacked: true,
          toolbar: {
            show: false
          }
        },
        xaxis: {
          type: 'numeric',
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            },
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return this.formatMoney(value);
            },
            style: {
              fontSize: this.$store.state.chartsFonts.small
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'solid',
          opacity: 1
        }
      }
    };
  },
  computed: {
    cashFlowImpactChartOptions() {
      return {
        ...this.cashFlowImpactUnchangedChartOptions,
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'bottom',
          horizontalAlign: this.$vuetify.breakpoint.width < 500 ? 'left' : 'center',
          fontSize: this.$store.state.chartsFonts.medium,
          markers: {
            width: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            height: this.$vuetify.breakpoint.width < 500 ? 12 : 24,
            radius: 4
          },
          onItemClick: {
            toggleDataSeries: false
          },
          formatter: function (seriesName) {
            return ['', seriesName];
          }
        },
        colors: this.chartColors
      };
    },
    cashFlowImpact() {
      const storeData =
        this.$store.state.scenarioData.currentScenarioData.chartsData
          .cashFlowChartForCDEscenariosData;
      const shouldWeReviseNumbers =
        this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
          .shouldWeReviseNumbers;

      const taxAndInsurance = [],
        legacyMortgage = [],
        immediateCash = [],
        voluntaryPayments = [];
      for (let i = 0; i < storeData.length; i++) {
        taxAndInsurance.push({
          x: storeData[i].age,
          y: storeData[i].taxAndInsurance
        });
        legacyMortgage.push({
          x: storeData[i].age,
          y: storeData[i].legacyMortgage
        });
        immediateCash.push({
          x: storeData[i].age,
          y: storeData[i].immediateCash
        });
        voluntaryPayments.push({
          x: storeData[i].age,
          y: storeData[i].voluntaryPayments
        });
      }
      const dataArray = [
        {
          name: 'Property Tax & Insurance Payments',
          data: taxAndInsurance
        },
        { name: 'Legacy Mortgage Payments', data: legacyMortgage },
        {
          name: 'Immediate Cash',
          data: immediateCash
        }
      ];
      if (shouldWeReviseNumbers) {
        dataArray.push({
          name: 'Voluntary payments',
          data: voluntaryPayments
        });
      }
      return dataArray;
    },
    chartColors() {
      const { scenario, allScenarioTitles } = this.$store.state.scenarioData;
      let immediateCashColor = colors.chartGreen;
      if (scenario === allScenarioTitles.scenarioDTitle) immediateCashColor = colors.chartRed;

      return [colors.chartLightPurple, colors.chartOrange, immediateCashColor, colors.chartRed];
    }
  },
  methods: { formatMoney: helpers.formatMoney, maxPeriod: helpers.getMaxPeriod }
};
</script>

<style scoped>
.cash-flow-chart-title {
  font-size: 1.25rem !important;
  font-weight: 500;
  color: #3e8df3;
}
</style>
