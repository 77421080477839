<template>
  <div class="my-4 full-width">
    <div class="medium-text-font mb-8">Build your own reverse mortgage</div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-sheet class="d-flex flex-column" width="100%">
            <div class="clauseText--text medium-small-text-font">
              1. Consider making voluntary payments on a reverse mortgage
            </div>
          </v-sheet>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
          <div class="d-flex justify-space-between">
            <div class="d-flex flex-column justify-space-between align-start">
              <v-sheet
                class="d-flex flex-column justify-space-between align-self-center align-self-sm-start"
                width="100%"
                max-width="500"
              >
                <div class="small-text-font pt-4 pb-8">
                  Each payment is applied to the outstanding loan balance and increases the Line of
                  Credit amount available to you in the future.
                </div>
                <MonthlyVoluntaryPaymentsTextField
                  label="Monthly amount of Voluntary Payments you wish to make on your reverse mortgage"
                  :customBackground="false"
                />
                <VoluntaryPaymentsPeriodSelectors
                  yearsLabel="How many years of voluntary payments?"
                  startingLabel="When do you want to begin making payments?"
                  :showTooltip="false"
                  :customBackground="false"
                />
              </v-sheet>
            </div>
            <v-sheet
              class="d-flex flex-column justify-end align-start mt-10 mt-lg-0 ml-0 ml-lg-16"
              max-width="670"
              min-width="270"
            >
              <v-sheet
                v-if="voluntaryPayments > 0"
                class="d-flex flex-column justify-space-between align-start mb-8"
              >
                <div class="small-text-font mb-2">
                  If you would like to make an Interest Only<span class="font-weight-bold">*</span>
                  payment for the number of years you have entered, the monthly amount would be
                  approximately {{ formatMoney(iterationsMonthlyAmount) }}.
                </div>
                <div class="extra-small-text-font">
                  <span class="font-weight-bold">∗</span> This is not exactly an Interest Only
                  payment. It's a close estimate
                </div>
              </v-sheet>
              <div class="medium-small-text-font mb-2">
                CAN THE LINE OF CREDIT BE FROZEN LIKE A HELOC?
              </div>
              <div class="clauseText--text small-text-font font-weight-medium mb-2">
                No, it can ONLY be frozen if you do not meet your reverse mortgage obligations,
                <span class="font-italic">especially:</span>
              </div>
              <div class="clauseText--text small-text-font font-weight-medium">
                • Maintain Home as Primary Residence
              </div>
              <div class="clauseText--text small-text-font font-weight-medium">
                • Timely Payment of Property Taxes and Homeowner's Insurance
              </div>
            </v-sheet>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import MonthlyVoluntaryPaymentsTextField from '../Selectors/MonthlyVoluntaryPaymentsTextField.vue';
import VoluntaryPaymentsPeriodSelectors from '../Selectors/VoluntaryPaymentsPeriodSelectors.vue';
import helpers from '../../helpers';

export default {
  components: {
    MonthlyVoluntaryPaymentsTextField,
    VoluntaryPaymentsPeriodSelectors
  },
  computed: {
    iterationsMonthlyAmount() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues
        .iterationsMonthlyAmount;
    },
    voluntaryPayments() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
        .monthlyVoluntaryPayments;
    },
    expandedPanel() {
      return this.$store.state.scenarioData.expandedPanel;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney
  }
};
</script>
