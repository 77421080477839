import { minOptionsValues, propertyTypes } from '@/views/Form/constants';

const excludePropertyTypes = propertyTypes.filter((x) => x.unable);
const excludePropertyTypesValues = excludePropertyTypes.map((x) => x.value);

const schema = {
  'basic.youngestOwnerAge': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      lessThanOrEqualTo: 118,
      greaterThanOrEqualTo: minOptionsValues.ageMinMarriedBorrower,
      message: `^No borrower may be younger than ${minOptionsValues.ageMinMarriedBorrower} or older than 118`
    }
  },
  'basic.isBirthDaySoon': {
    presence: { message: '^Please select an option', allowEmpty: false }
  },
  'basic.propertyValue': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'creditAndProperty.creditHistory': {
    presence: { message: '^Please select an option', allowEmpty: false }
  },
  'creditAndProperty.monthlyInstallmentPayment': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'creditAndProperty.minMonthlyCreditCardPayments': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'creditAndProperty.propertyType': {
    presence: { message: '^Please select an option', allowEmpty: false },
    type: 'string',
    exclusion: {
      within: excludePropertyTypesValues,
      message: '^You selected Co-Op as your property type, and Co-Ops are not eligible.'
    }
  },
  'creditAndProperty.yearBuilt': {
    type: 'number',
    numericality: {
      onlyInteger: true,
      lessThanOrEqualTo: new Date().getFullYear(),
      greaterThanOrEqualTo: minOptionsValues.minYearBuilt,
      message: `^All Manufactured Homes must be built after June ${minOptionsValues.minYearBuilt} to qualify.`
    }
  },
  'creditAndProperty.numberPeopleInHome': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 1,
      message: '^Value must be greater than zero'
    }
  },
  'creditAndProperty.isPayingHOADues': {
    presence: { message: '^Please select an option', allowEmpty: false }
  },
  'creditAndProperty.monthlyAmount': {
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'creditAndProperty.estimatedSquareFeet': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: minOptionsValues.minSqFt,
      message:
        '^You entered less than 600 square feet for the size of your home.  The home must be at least 600 sq ft to qualify for a reverse mortgage.'
    }
  },
  'creditAndProperty.isAnyOtherProperties': {
    presence: { message: '^Please select an option', allowEmpty: false }
  },
  'creditAndProperty.state': {
    presence: { message: '^Please select an option', allowEmpty: false }
  },

  // 'creditAndProperty.zipCode': {
  //   // presence: { message: '^Please enter the value', allowEmpty: false },
  //   type: 'number',
  //   numericality: {
  //     onlyInteger: true
  //   }
  // },

  'incomeAndAssets.householdMonthlyIncome': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'incomeAndAssets.checkingSavingsAccounts': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'incomeAndAssets.taxableInvestmentAccount': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'incomeAndAssets.additionalAssets': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'mortgages.firstMortgageBalance': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'mortgages.firstMortgageMonthlyPayment': {
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'mortgages.yearsLeftOnMortgage': {
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be an integer'
    }
  },
  // 'mortgages.areTaxesEscrowed': {
  //   presence: { message: '^Please select an option', allowEmpty: false }
  // },
  'mortgages.annualTaxes': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'mortgages.annualInsurance': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'mortgages.homeEquityLine': {
    presence: { message: '^Please enter the value', allowEmpty: false },
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'mortgages.monthlyHelocPayment': {
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be a dollar amount without cents'
    }
  },
  'mortgages.yearsOnHomeEquityLine': {
    type: 'number',
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 0,
      message: '^Value can not be lower than 0 and must be an integer'
    }
  }
};

export const getSchema = (options = { excludedFields: [] }) => {
  if (options.excludedFields.length > 0) {
    return Object.fromEntries(
      Object.entries(schema).filter(([fieldName]) => !options.excludedFields.includes(fieldName))
    );
  }

  return schema;
};

export default schema;
