<template>
  <div>
    <IncreaseFunds />

    <StaticExample />

    <BuildYourOwnRM />
  </div>
</template>

<script>
import IncreaseFunds from '../IncreaseFunds.vue';
import StaticExample from '../StaticExample.vue';
import BuildYourOwnRM from './SavedBuildYourOwnRM.vue';

export default {
  components: { IncreaseFunds, StaticExample, BuildYourOwnRM }
};
</script>
