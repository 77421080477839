<template>
  <v-sheet
    class="d-flex flex-column flex-sm-row justify-space-between align-center mb-3"
    v-bind:class="{ generalBackground: isBackgroundCustom }"
    width="100%"
  >
    <div class="small-text-font text-center text-sm-start mb-1 mb-sm-0" style="max-width: 250px">
      {{ label }}
    </div>
    <v-sheet width="200">
      <v-text-field
        v-bind:class="{ generalBackground: isBackgroundCustom }"
        outlined
        prefix="$"
        :value="formatMoney(voluntaryPayments)"
        :hide-details="!voluntaryPaymentsError && !inputError"
        :disabled="isUpdating"
        :readonly="isResultSaved"
        @input="(value) => handleScenarioOptionsChange(value, 'monthlyVoluntaryPayments')"
        :error="Boolean(inputError)"
        :error-messages="inputError"
      ></v-text-field>
    </v-sheet>
  </v-sheet>
</template>

<script>
import _ from 'lodash';
import helpers from '@/helpers';

export default {
  props: ['label', 'customBackground'],
  data: () => ({
    voluntaryPaymentsError: false,
    inputError: ''
  }),
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    voluntaryPayments() {
      return this.$store.state.scenarioData.currentScenarioData.selectsOptions.selected
        .monthlyVoluntaryPayments;
    },
    isBackgroundCustom() {
      return this.$props.customBackground || false;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    }
  },
  methods: {
    formatMoney: helpers.formatMoneyForInput,
    unformatMoney: helpers.unformatMoney,
    saveScenarioOption: _.debounce(function (value, fieldName) {
      if (!this.voluntaryPaymentsError) {
        const data = { fieldName, value };
        this.$store.commit('changeOptionValues', data);
        this.$store.dispatch('getNewScenarioData');
      }
    }, 1000),
    handleScenarioOptionsChange(value, fieldName) {
      this.$data.inputError = '';

      const currentValue = value.length === 0 ? 0 : this.unformatMoney(value);

      if (currentValue === null) {
        this.$data.inputError = 'Value must be a number';
        return;
      }

      const validationResponse = this.voluntaryPaymentsRule(currentValue);

      if (typeof validationResponse === 'string') {
        this.$data.inputError = validationResponse;
      }

      this.saveScenarioOption(currentValue, fieldName);
    },
    voluntaryPaymentsRule(value) {
      if (value < 0) {
        this.voluntaryPaymentsError = true;
        return 'Should be a positive number!';
      } else {
        this.voluntaryPaymentsError = false;
        return true;
      }
    }
  }
};
</script>
