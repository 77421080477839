<template>
  <div v-if="isLoanOfficerLoaded">
    <div class="wrapper">
      <div class="text-h5 text-center mb-2">You have been invited by:</div>
      <div class="d-flex align-center justify-center gap-24">
        <div class="text-h5 font-weight-bold">
          {{ loanOfficer.firstName }}
          {{ loanOfficer.lastName }}
        </div>
        <div
          class="rounded-pill px-6 py-1 light-blue darken-2 text-h6 font-weight-medium white--text"
        >
          {{ loanOfficer.email }}
        </div>
      </div>
    </div>
    <ApplicationForm />
  </div>
</template>

<script>
import ApplicationForm from '@/components/ApplicationForm';

export default {
  components: {
    ApplicationForm
  },
  created() {
    this.$store.dispatch('resetCalculationResults');
  },
  computed: {
    loanOfficer() {
      return this.$store.state.loanOfficer.info;
    },
    isLoanOfficerLoaded() {
      return this.$store.getters['loanOfficer/isLoanOfficerLoaded'];
    }
  }
};
</script>

<style scoped>
.wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.gap-24 {
  column-gap: 24px;
}
</style>
