<template>
  <div class="my-8">
    <!-- <FixedRateRMSense /> -->
    <v-card
      class="mx-auto mb-8 py-6 px-4 px-sm-10 d-flex flex-column justify-start align-center"
      elevation="3"
    >
      <v-sheet width="80%" min-width="300" max-width="1000"
        ><CustomScenarioChart :period="otherValues.lifeExpectancy" category="age" /></v-sheet
    ></v-card>
    <EstimatedCashFlowSavings /><EquityChange />
  </div>
</template>

<script>
import CustomScenarioChart from '../Charts/CustomScenarioChart.vue';
// import FixedRateRMSense from './FixedRateRMSense.vue';
import EstimatedCashFlowSavings from './EstimatedCashFlowSavings.vue';
import EquityChange from './EquityChange.vue';

export default {
  components: {
    CustomScenarioChart,
    EstimatedCashFlowSavings,
    EquityChange
  },
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    otherValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues;
    }
  }
};
</script>

<style scoped></style>
