import guestAxios from '@/axios';

export default {
  state: { isEmailConfirmed: false },
  mutations: {
    SET_DATA(state, data) {
      state.isEmailConfirmed = data.isEmailConfirmed;
    }
  },
  actions: {
    async checkConfirm({ commit }, { resultUID, confirmHash, email }) {
      try {
        commit('setLoading');
        const response = await guestAxios.get(
          encodeURI(`/public/calculation/${resultUID}/confirm/${confirmHash}?email=${email}`)
        );

        if (response?.data?.confirmed) {
          commit('SET_DATA', {
            isEmailConfirmed: response.data.confirmed
          });
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        commit('setLoading');
      }
    }
  }
};
