<template>
  <v-container>
    <div class="form mx-auto">
      <v-card elevation="5" class="pa-5">
        <v-form>
          <div v-if="fullStopError.status" class="error-message">{{ fullStopError.message }}</div>
          <div class="d-flex mb-8">
            <div class="form-title mt-3">Get started in 2 easy steps!</div>
            <div class="d-flex justify-center align-center ml-auto" v-if="showScenarioPresets">
              <v-select
                background-color="rgb(240 242 245 / 60%)"
                :items="scenarioData"
                item-text="title"
                item-value="value"
                label="Select scenario inputs"
                v-model="scenarioInput"
                style="max-width: 300px"
                @change="handleFormInputs"
                outlined
              ></v-select>
            </div>
          </div>
          <div v-if="youngestOwnerAgeWarning.status" class="warning-message">
            {{ youngestOwnerAgeWarning.message }}
          </div>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            type="number"
            :min="18"
            :max="118"
            label="Age of Youngest Homeowner"
            outlined
            v-model.number="form.basic.youngestOwnerAge"
            @change="checkYoungestOwnerAge"
            :error="!!errors.basic.youngestOwnerAge"
            :error-messages="errors.basic.youngestOwnerAge"
          ></v-text-field>
          <v-select
            background-color="rgb(240 242 245 / 60%)"
            :items="options.yesNo"
            item-text="title"
            item-value="value"
            label="Is your next birthday within 7 months?"
            outlined
            v-model="form.basic.isBirthDaySoon"
            :error="!!errors.basic.isBirthDaySoon"
            :error-messages="errors.basic.isBirthDaySoon"
          ></v-select>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            label="Estimated Property Value"
            outlined
            prefix="$"
            :value="formatMoney(form.basic.propertyValue)"
            @input="(value) => saveFormInput('basic.propertyValue', value)"
            :error="!!errors.basic.propertyValue"
            :error-messages="errors.basic.propertyValue"
          ></v-text-field>

          <v-select
            id="vue-select"
            background-color="rgb(240 242 245 / 60%)"
            :items="loanOfficer.states"
            item-text="state"
            item-value="state"
            label="State"
            outlined
            :error="!!errors.creditAndProperty.state"
            :error-messages="errors.creditAndProperty.state"
            v-model="form.creditAndProperty.state"
          ></v-select>

          <div class="form-subtitle">Credit & Property</div>
          <v-select
            id="vue-select"
            background-color="rgb(240 242 245 / 60%)"
            :items="options.historyItems"
            item-text="value"
            item-value="value"
            label="Personal Credit History"
            outlined
            :error="!!errors.creditAndProperty.creditHistory"
            :error-messages="errors.creditAndProperty.creditHistory"
            v-model="form.creditAndProperty.creditHistory"
          ></v-select>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            label="Monthly Installment Payments"
            outlined
            prefix="$"
            :error="!!errors.creditAndProperty.monthlyInstallmentPayment"
            :error-messages="errors.creditAndProperty.monthlyInstallmentPayment"
            :value="formatMoney(form.creditAndProperty.monthlyInstallmentPayment)"
            @input="(value) => saveFormInput('creditAndProperty.monthlyInstallmentPayment', value)"
          ></v-text-field>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            label="Minimum Monthly Credit Card Payments"
            outlined
            prefix="$"
            :error="!!errors.creditAndProperty.minMonthlyCreditCardPayments"
            :error-messages="errors.creditAndProperty.minMonthlyCreditCardPayments"
            :value="formatMoney(form.creditAndProperty.minMonthlyCreditCardPayments)"
            @input="
              (value) => saveFormInput('creditAndProperty.minMonthlyCreditCardPayments', value)
            "
          ></v-text-field>
          <v-select
            background-color="rgb(240 242 245 / 60%)"
            :error="!!errors.creditAndProperty.propertyType"
            :error-messages="errors.creditAndProperty.propertyType"
            :items="options.propertyTypes"
            item-text="value"
            item-value="value"
            label="Property Type"
            outlined
            v-model="form.creditAndProperty.propertyType"
            @change="checkPropertyType"
          ></v-select>
          <v-text-field
            v-if="isYearBuiltShown"
            background-color="rgb(240 242 245 / 60%)"
            type="number"
            :min="0"
            label="Year built"
            outlined
            :error="!!errors.creditAndProperty.yearBuilt"
            :error-messages="errors.creditAndProperty.yearBuilt"
            v-model.number="form.creditAndProperty.yearBuilt"
          ></v-text-field>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            type="number"
            :min="1"
            :max="30"
            label="How many people live in your home?"
            outlined
            :error="!!errors.creditAndProperty.numberPeopleInHome"
            :error-messages="errors.creditAndProperty.numberPeopleInHome"
            v-model.number="form.creditAndProperty.numberPeopleInHome"
          ></v-text-field>
          <v-select
            background-color="rgb(240 242 245 / 60%)"
            :items="options.yesNo"
            item-text="title"
            item-value="value"
            label="Do you pay HOA Dues?"
            outlined
            :error="!!errors.creditAndProperty.isPayingHOADues"
            :error-messages="errors.creditAndProperty.isPayingHOADues"
            v-model="form.creditAndProperty.isPayingHOADues"
            @change="checkHoaDues"
          ></v-select>
          <v-text-field
            v-if="isMonthlyAmountShown"
            background-color="rgb(240 242 245 / 60%)"
            type="number"
            :min="0"
            label="Monthly Amount"
            outlined
            prefix="$"
            :error="!!errors.creditAndProperty.monthlyAmount"
            :error-messages="errors.creditAndProperty.monthlyAmount"
            v-model.number="form.creditAndProperty.monthlyAmount"
          ></v-text-field>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            label="Estimated Square Feet of Your Home"
            outlined
            :value="formatMoney(form.creditAndProperty.estimatedSquareFeet)"
            @input="(value) => saveFormInput('creditAndProperty.estimatedSquareFeet', value)"
            :error="!!errors.creditAndProperty.estimatedSquareFeet"
            :error-messages="errors.creditAndProperty.estimatedSquareFeet"
          ></v-text-field>
          <v-select
            background-color="rgb(240 242 245 / 60%)"
            :items="options.yesNo"
            item-text="title"
            item-value="value"
            label="Do you own any other properties?"
            outlined
            :error="!!errors.creditAndProperty.isAnyOtherProperties"
            :error-messages="errors.creditAndProperty.isAnyOtherProperties"
            v-model="form.creditAndProperty.isAnyOtherProperties"
          ></v-select>

          <!-- 
            <v-text-field
              background-color="rgb(240 242 245 / 60%)"
              label="Zip code"
              outlined
              v-model="form.creditAndProperty.zipCode"
            ></v-text-field> -->

          <div class="form-subtitle">Income & Assets</div>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            label="Total Household Monthly Income"
            prefix="$"
            outlined
            :error="!!errors.incomeAndAssets.householdMonthlyIncome"
            :error-messages="errors.incomeAndAssets.householdMonthlyIncome"
            :value="formatMoney(form.incomeAndAssets.householdMonthlyIncome)"
            @input="(value) => saveFormInput('incomeAndAssets.householdMonthlyIncome', value)"
          ></v-text-field>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            prefix="$"
            :min="0"
            label="Checking / Savings Accounts and Roth IRAs"
            outlined
            :error="!!errors.incomeAndAssets.checkingSavingsAccounts"
            :error-messages="errors.incomeAndAssets.checkingSavingsAccounts"
            :value="formatMoney(form.incomeAndAssets.checkingSavingsAccounts)"
            @input="(value) => saveFormInput('incomeAndAssets.checkingSavingsAccounts', value)"
          ></v-text-field>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            prefix="$"
            label="401K, IRA or other taxable investment accounts"
            outlined
            :error="!!errors.incomeAndAssets.taxableInvestmentAccount"
            :error-messages="errors.incomeAndAssets.taxableInvestmentAccount"
            :value="formatMoney(form.incomeAndAssets.taxableInvestmentAccount)"
            @input="(value) => saveFormInput('incomeAndAssets.taxableInvestmentAccount', value)"
          ></v-text-field>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            prefix="$"
            label="Additional assets, such as equity in other real estate"
            outlined
            :error="!!errors.incomeAndAssets.additionalAssets"
            :error-messages="errors.incomeAndAssets.additionalAssets"
            :value="formatMoney(form.incomeAndAssets.additionalAssets)"
            @input="(value) => saveFormInput('incomeAndAssets.additionalAssets', value)"
          ></v-text-field>

          <div class="form-subtitle">Mortgages</div>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            label="First Mortgage Balance"
            outlined
            prefix="$"
            :error="!!errors.mortgages.firstMortgageBalance"
            :error-messages="errors.mortgages.firstMortgageBalance"
            :value="formatMoney(form.mortgages.firstMortgageBalance)"
            @input="
              (value) =>
                checkFirstMortgageBalance(saveFormInput('mortgages.firstMortgageBalance', value))
            "
          ></v-text-field>
          <v-text-field
            v-if="isMortgageOptionsShown"
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            label="First Mortgage Monthly Payment"
            outlined
            prefix="$"
            :error="!!errors.mortgages.firstMortgageMonthlyPayment"
            :error-messages="errors.mortgages.firstMortgageMonthlyPayment"
            :value="formatMoney(form.mortgages.firstMortgageMonthlyPayment)"
            @input="(value) => saveFormInput('mortgages.firstMortgageMonthlyPayment', value)"
          ></v-text-field>
          <v-text-field
            v-if="isMortgageOptionsShown"
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            label="Years Left on First Mortgage"
            outlined
            :error="!!errors.mortgages.yearsLeftOnMortgage"
            :error-messages="errors.mortgages.yearsLeftOnMortgage"
            :value="formatMoney(form.mortgages.yearsLeftOnMortgage)"
            @input="(value) => saveFormInput('mortgages.yearsLeftOnMortgage', value)"
          ></v-text-field>
          <v-select
            v-if="isMortgageOptionsShown"
            background-color="rgb(240 242 245 / 60%)"
            :items="options.yesNo"
            item-text="title"
            item-value="value"
            label="Are Taxes and Insurance Escrowed?"
            outlined
            :error="!!errors.mortgages.areTaxesEscrowed"
            :error-messages="errors.mortgages.areTaxesEscrowed"
            v-model="form.mortgages.areTaxesEscrowed"
          ></v-select>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            prefix="$"
            label="Annual Property Taxes"
            outlined
            :error="!!errors.mortgages.annualTaxes"
            :error-messages="errors.mortgages.annualTaxes"
            :value="formatMoney(form.mortgages.annualTaxes)"
            @input="(value) => saveFormInput('mortgages.annualTaxes', value)"
          ></v-text-field>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            prefix="$"
            label="Annual Homeowner's Insurance"
            outlined
            :error="!!errors.mortgages.annualInsurance"
            :error-messages="errors.mortgages.annualInsurance"
            :value="formatMoney(form.mortgages.annualInsurance)"
            @input="(value) => saveFormInput('mortgages.annualInsurance', value)"
          ></v-text-field>
          <v-text-field
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            label="Home Equity Line of Credit Balance"
            outlined
            prefix="$"
            :error="!!errors.mortgages.homeEquityLine"
            :error-messages="errors.mortgages.homeEquityLine"
            :value="formatMoney(form.mortgages.homeEquityLine)"
            @input="
              (value) => checkHomeEquityLine(saveFormInput('mortgages.homeEquityLine', value))
            "
          ></v-text-field>
          <v-text-field
            v-if="isHomeQuityOptionsShown"
            background-color="rgb(240 242 245 / 60%)"
            :min="0"
            label="Monthly Payment on HELOC"
            outlined
            prefix="$"
            :error="!!errors.mortgages.monthlyHelocPayment"
            :error-messages="errors.mortgages.monthlyHelocPayment"
            :value="formatMoney(form.mortgages.monthlyHelocPayment)"
            @input="(value) => saveFormInput('mortgages.monthlyHelocPayment', value)"
          ></v-text-field>
          <v-text-field
            v-if="isHomeQuityOptionsShown"
            background-color="rgb(240 242 245 / 60%)"
            type="number"
            :min="0"
            label="Years Left on Home Equity Line of Credit"
            outlined
            :error="!!errors.mortgages.yearsOnHomeEquityLine"
            :error-messages="errors.mortgages.yearsOnHomeEquityLine"
            v-model.number="form.mortgages.yearsOnHomeEquityLine"
          ></v-text-field>

          <div class="d-flex justify-center">
            <v-btn :disabled="fullStopError.status" color="success" x-large @click="submit"
              ><div class="px-4">Get results</div></v-btn
            >
          </div>
        </v-form>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
import validate from 'validate.js';

import helpers from '@/helpers';

import { getSchema } from '@/views/Form/validation';
import {
  minOptionsValues,
  propertyTypes,
  historyItems,
  yesNo,
  statesAndZipCodes,
  scenarioData
} from '@/views/Form/constants';

const getDefaultErrors = () => ({
  basic: {},
  creditAndProperty: {},
  incomeAndAssets: {},
  mortgages: {}
});

const getDefaultForm = () => {
  const stored = sessionStorage.getItem('form');
  try {
    const form = JSON.parse(stored);
    if (_.isEmpty(form)) {
      throw 'No saved form data.';
    }

    return form;
  } catch (e) {
    return {
      basic: {
        youngestOwnerAge: undefined,
        isBirthDaySoon: undefined,
        propertyValue: undefined
      },
      creditAndProperty: {
        creditHistory: undefined,
        monthlyInstallmentPayment: undefined,
        minMonthlyCreditCardPayments: undefined,
        propertyType: undefined,
        yearBuilt: 1977,
        numberPeopleInHome: undefined,
        isPayingHOADues: undefined,
        monthlyAmount: undefined,
        estimatedSquareFeet: undefined,
        isAnyOtherProperties: undefined,
        state: undefined
      },
      incomeAndAssets: {
        householdMonthlyIncome: undefined,
        checkingSavingsAccounts: undefined,
        taxableInvestmentAccount: undefined,
        additionalAssets: undefined
      },
      mortgages: {
        firstMortgageBalance: undefined,
        firstMortgageMonthlyPayment: undefined,
        yearsLeftOnMortgage: undefined,
        areTaxesEscrowed: undefined,
        annualTaxes: undefined,
        annualInsurance: undefined,
        homeEquityLine: undefined,
        monthlyHelocPayment: undefined,
        yearsOnHomeEquityLine: undefined
      }
    };
  }
};

export default {
  data: () => ({
    minOptionsValues,
    scenarioInput: [],
    options: {
      propertyTypes,
      historyItems,
      yesNo,
      statesAndZipCodes
    },
    form: getDefaultForm(),
    errors: getDefaultErrors(),
    fullStopError: {
      status: false,
      message:
        "Based on the information you provided, we are not able to issue a pre-qualfied letter, but this does not necessarily mean we can't help you.  We encourage you to schedule a meeting with us in our office or on the phone to discuss this further.  The issues we have encountered in the pre-qualification process are listed below:"
    },
    youngestOwnerAgeWarning: {
      status: false,
      message:
        'At least one borrower must be 62 or older to qualify for a reverse mortgage. One borrower may be younger than 62, if he/she is the spouse of the borrower who is 62 or older. We assume there is a married spouse who is at least 62.'
    },
    isYearBuiltShown: false,
    isMonthlyAmountShown: false,
    isMortgageOptionsShown: false,
    isHomeQuityOptionsShown: false,
    excludableFields: {
      isYearBuiltShown: ['creditAndProperty.yearBuilt'],
      isMonthlyAmountShown: ['creditAndProperty.monthlyAmount'],
      isMortgageOptionsShown: [
        'mortgages.areTaxesEscrowed',
        'mortgages.yearsLeftOnMortgage',
        'mortgages.firstMortgageMonthlyPayment'
      ],
      isHomeQuityOptionsShown: ['mortgages.yearsOnHomeEquityLine', 'mortgages.monthlyHelocPayment']
    }
  }),
  computed: {
    loanOfficer() {
      return this.$store.state.loanOfficer.info;
    },
    scenarioData() {
      return scenarioData;
    },
    showScenarioPresets() {
      let params = new URL(document.location).searchParams;
      let presets = params.get('presets');
      console.log({ presets });
      return presets === '1';
    },
    excludedFields() {
      return Object.keys(this.excludableFields)
        .filter((condition) => !this.$data[condition])
        .reduce((prev, curr) => {
          return prev.concat(this.excludableFields[curr]);
        }, []);
    }
  },

  methods: {
    formatMoney: helpers.formatMoneyForInput,
    unformatMoney: helpers.unformatMoney,
    handleFormInputs(value) {
      this.$data.form = value;
      this.formDataCheck();
    },
    saveFormInput(path, value) {
      const currentValue = this.unformatMoney(value);

      if (currentValue === null) {
        _.set(this.$data.form, path, value);

        return value;
      }

      _.set(this.$data.form, path, currentValue);
      return currentValue;
    },
    checkYoungestOwnerAge(value) {
      this.$data.youngestOwnerAgeWarning.status =
        value < minOptionsValues.ageMinSingleBorrower &&
        value >= minOptionsValues.ageMinMarriedBorrower
          ? true
          : false;
    },
    checkPropertyType(value) {
      const option = propertyTypes.find((i) => i.value === value);
      const { year } = option;

      this.$data.isYearBuiltShown = year;
    },
    checkHoaDues(value) {
      this.$data.isMonthlyAmountShown = value ? true : false;
      if (!value) this.$data.form.creditAndProperty.monthlyAmount = 0;
    },
    checkFirstMortgageBalance(value) {
      if (value > 0) {
        this.$data.isMortgageOptionsShown = true;
      } else {
        this.$data.isMortgageOptionsShown = false;
      }
    },
    checkHomeEquityLine(value) {
      if (value > 0) {
        this.$data.isHomeQuityOptionsShown = true;
      } else {
        this.$data.isHomeQuityOptionsShown = false;
      }
    },
    formDataCheck() {
      this.checkYoungestOwnerAge(this.$data.form.basic.youngestOwnerAge);
      this.checkPropertyType(this.$data.form.creditAndProperty.propertyType);
      this.checkHoaDues(this.$data.form.creditAndProperty.isPayingHOADues);
      this.checkFirstMortgageBalance(this.$data.form.mortgages.firstMortgageBalance);
      this.checkHomeEquityLine(this.$data.form.mortgages.homeEquityLine);
    },
    checkBeforeSending(data) {
      let tempData = data;
      if (!this.$data.isMonthlyAmountShown) {
        tempData.creditAndProperty.monthlyAmount = 0;
      }
      if (!this.$data.isMortgageOptionsShown) {
        tempData.mortgages.firstMortgageMonthlyPayment = 0;
        tempData.mortgages.yearsLeftOnMortgage = 0;
        tempData.mortgages.areTaxesEscrowed = false;
      }
      if (!this.$data.isHomeQuityOptionsShown) {
        tempData.mortgages.monthlyHelocPayment = 0;
        tempData.mortgages.yearsOnHomeEquityLine = 0;
      }
      return tempData;
    },
    clearOldScenarioData() {
      this.$store.commit('setScenario', '');
      this.$store.commit('setScenarioData', {
        finalValues: {},
        selectsOptions: {},
        chartsData: {}
      });
      this.$store.commit('setAmortCompAndTALCData', {});
      this.$store.commit('setEmailToLOData', {});
    },
    async submit() {
      const schema = getSchema({ excludedFields: this.excludedFields });
      const validationErrors = validate(this.$data.form, schema);

      if (validationErrors) {
        this.$data.fullStopError.status = true;
        const errors = getDefaultErrors();

        _.each(validationErrors, (value, key) => {
          _.set(errors, key, value[0]);
        });

        this.$data.errors = errors;

        this.$nextTick(() => {
          console.error(errors);
          const el = this.$el
            .querySelector('.v-messages.error--text:first-of-type')
            .closest('.v-input');

          this.$vuetify.goTo(el, { offset: 100 });
        });
      } else {
        this.clearOldScenarioData();
        const submitData = this.$data.form;
        const checkedData = this.checkBeforeSending(submitData);
        console.log('sent', checkedData);
        this.$store.dispatch('getAllData', { submitData, isAudit: false });
        this.$router.push({ name: 'Result' });
      }
    }
  },
  created() {
    if (this.showScenarioPresets) {
      this.$data.form = _.first(scenarioData).value;
      this.$data.scenarioInput = _.first(scenarioData);
    }

    this.formDataCheck();
  },

  watch: {
    form: {
      handler(newFormValue) {
        sessionStorage.setItem('form', JSON.stringify(newFormValue));

        if (this.$data.fullStopError.status) {
          this.$data.fullStopError.status = false;
        }
      },
      deep: true
    }
  }

  // watch: {
  //   form: {
  //     handler: _.debounce(function (form) {
  //       const validationErrors = validate(form, schema);
  //       this.$data.fullStopError.status = validationErrors ? true : false;
  //       const errors = getDefaultErrors();

  //       _.each(validationErrors, (value, key) => {
  //         _.set(errors, key, value[0]);
  //       });
  //       this.$data.errors = errors;
  //     }, 1500),
  //     deep: true
  //   }
  // }
};
</script>

<style scoped>
.form {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

#vue-select div {
  line-height: 26px;
}
.form-title {
  font-weight: 500;
  font-size: 22px;
}
.form-subtitle {
  padding: 8px 0;
  font-weight: 500;
}
.error-message {
  font-weight: 500;
}
.warning-message {
  font-size: 14px;
  padding: 12px 0;
}
</style>
